<div class="content">
  <h1 mat-dialog-title *ngIf="update === undefined || !update">
    Δημιουργία ερώτησης
  </h1>
  <h1 mat-dialog-title *ngIf="update === undefined || update">
    Επεξεργασία ερώτησης
  </h1>
  <form [formGroup]="form">
    <mat-grid-list class="form" cols="12" rowHeight="70px">
      <mat-grid-tile colspan="12">
        <div class="align-left full-width">
          <mat-form-field class="full-width" appearance="standard">
            <mat-label>Ερώτηση</mat-label>
            <input
              matInput
              placeholder="Ερώτηση"
              formControlName="question"
              required
            />
            <mat-error *ngIf="form.get('question')?.invalid"
              >Παρακαλώ συμπληρώστε ερώτηση.</mat-error
            >
          </mat-form-field>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list cols="12" rowHeight="70px">
      <mat-grid-tile colspan="12">
        <div class="align-left full-width">
          <mat-form-field class="full-width" appearance="standard">
            <mat-label>Απάντηση 1</mat-label>
            <input
              matInput
              placeholder="Απάντηση 1"
              formControlName="answer_one"
              required
            />
            <mat-error *ngIf="form.get('answer_one')?.invalid"
              >Παρακαλώ συμπληρώστε απάντηση.</mat-error
            >
          </mat-form-field>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list cols="12" rowHeight="70px">
      <mat-grid-tile colspan="12">
        <div class="align-left full-width">
          <mat-form-field class="full-width" appearance="standard">
            <mat-label>Απάντηση 2</mat-label>
            <input
              matInput
              placeholder="Απάντηση 2"
              formControlName="answer_two"
              required
            />
            <mat-error *ngIf="form.get('answer_two')?.invalid"
              >Παρακαλώ συμπληρώστε απάντηση.</mat-error
            >
          </mat-form-field>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list cols="12" rowHeight="70px">
      <mat-grid-tile colspan="12">
        <div class="align-left full-width">
          <mat-form-field class="full-width" appearance="standard">
            <mat-label>Απάντηση 3</mat-label>
            <input
              matInput
              placeholder="Απάντηση 3"
              formControlName="answer_three"
              required
            />
            <mat-error *ngIf="form.get('answer_three')?.invalid"
              >Παρακαλώ συμπληρώστε απάντηση.</mat-error
            >
          </mat-form-field>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list cols="12" rowHeight="70px">
      <mat-grid-tile colspan="12">
        <div class="align-left full-width">
          <mat-form-field class="full-width" appearance="standard">
            <mat-label>Σωστή απάντηση</mat-label>
            <mat-select formControlName="correct" required>
              <mat-option *ngFor="let index of [1, 2, 3]" [value]="index">
                {{ index }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('correct')?.invalid"
              >Παρακαλώ συμπληρώστε τη σωστή απάντηση.</mat-error
            >
          </mat-form-field>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <div mat-dialog-actions align="end">
      <mat-dialog-actions>
        <button class="close-btn" mat-raised-button [mat-dialog-close]="false">Ακύρωση</button>
        <button
          [disabled]="submited"
          (click)="submit()"
          class="add-btn custom-btn"
          mat-raised-button
        >
          Επιβεβαίωση
        </button>
      </mat-dialog-actions>
    </div>
  </form>
</div>
