<div class="mat-elevation-z8 main">
  <mat-toolbar>
    <div fxLayout="row" class="nav-div" fxFlex="100"  *ngIf="report !== undefined" fxLayoutAlign="start center center">
      <div fxLayout="column" fxFlex="90">
        <div fxLayout="row wrap">
          <label style="white-space: break-spaces;">{{ report.report.name }}</label>
        </div>
      </div>
      <div fxLayout="column" fxFlex="10">
        <div fxLayout="row">
          <button
            mat-icon-button
            (click)="approve()"
            *ngIf="!report.report.approved"
            matTooltip="Πατήστε για έγκριση"
          >
            <mat-icon>verified</mat-icon>
          </button>
          <button
            mat-icon-button
            *ngIf="report.report.approved"
            (click)="disapprove()"
            matTooltip="Πατήστε για μη έγκριση"
          >
            <mat-icon>block</mat-icon>
          </button>
          <button (click)="openDeleteDialog()" mat-icon-button>
            <mat-icon matTooltip="Διαγραφή" class="close-color">delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
<!--    <mat-grid-list cols="12" class="nav-div" *ngIf="report !== undefined">-->
<!--      <mat-grid-tile colspan="9">-->
<!--        <div class="align-left">-->
<!--          {{ report.report.name }}-->
<!--        </div>-->
<!--      </mat-grid-tile>-->

<!--      <mat-grid-tile colspan="3">-->
<!--        <div class="align-right">-->
<!--          <button-->
<!--            mat-icon-button-->
<!--            (click)="approve()"-->
<!--            *ngIf="!report.report.approved"-->
<!--            matTooltip="Πατήστε για έγκριση"-->
<!--          >-->
<!--            <mat-icon>verified</mat-icon>-->
<!--          </button>-->
<!--          <button-->
<!--            mat-icon-button-->
<!--            *ngIf="report.report.approved"-->
<!--            (click)="disapprove()"-->
<!--            matTooltip="Πατήστε για μη έγκριση"-->
<!--          >-->
<!--            <mat-icon>block</mat-icon>-->
<!--          </button>-->
<!--          <button (click)="openDeleteDialog()" mat-icon-button>-->
<!--            <mat-icon matTooltip="Διαγραφή" class="close-color">delete</mat-icon>-->
<!--          </button>-->
<!--        </div>-->
<!--      </mat-grid-tile>-->
<!--    </mat-grid-list>-->
  </mat-toolbar>
  <div class="context" *ngIf="report === undefined">
    <div class="spinner-div">
      <mat-spinner [diameter]="100"></mat-spinner>
    </div>
  </div>
  <div class="context" *ngIf="report !== undefined">
    <table class="details">
      <tr>
        <td class="primary-color"><strong>Κατηγορία:</strong></td>
        <td>{{ report.category.name }}</td>
      </tr>
      <!-- <tr>
        <td>Υποατηγορία:</td>
        <td>{{ report.sub_category.name }}</td>
      </tr> -->
      <tr>
        <td class="primary-color"><strong>Προστέθηκε:</strong></td>
        <td>{{ getDate(report) }}</td>
      </tr>
      <tr>
        <td class="primary-color"><strong>Ένταση Ήχου:</strong></td>
        <td>{{  report.report.sound > 0 ? report.report.sound.toFixed(2) + " Db" : '-' }}</td>
      </tr>
      <tr>
        <td class="primary-color" style="padding-right: 10px"><strong>Κατάσταση Αναφοράς:</strong></td>
        <td> {{ getApprovedName() }}</td>
      </tr>
    </table>

    <div style="padding-top: 20px;">
      <h4 class="primary-color"><strong>Περιεχόμενο Αναφοράς Προβλήματος</strong></h4>
      <p>
        {{ report.report.context }}
      </p>
    </div>


    <div class="like">
      <div class="number primary-color">
        <div>
          {{ report.like_number }}
        </div>
        <div>
          <mat-icon>thumb_up</mat-icon>
        </div>
      </div>
      <div class="number close-color" style="padding-left: 10px;">
        <div>
          {{ report.dislike_number }}
        </div>
        <div>
          <mat-icon>thumb_down</mat-icon>
        </div>
      </div>
    </div>

    <div *ngIf="report.images.length > 0" style="padding-top: 20px;">
      <h4 class="primary-color"><strong>Φωτογραφίες</strong></h4>
      <div>

        <carousel style="height: 300px;" arrowsTheme="dark"
                  objectFit="contain" [cellsToShow]="2">
          <div *ngFor="let image of images;" class="carousel-cell">
            <img [src]="image.path" (click)="displayImage(image)">
          </div>
        </carousel>
      </div>
    </div>

    <agm-map
      class="map"
      #map
      [latitude]="report.report.lat"
      [longitude]="report.report.lon"
      [zoom]="14"
      (mapReady)="onMapReady($event)"
    >
    </agm-map>
  </div>
</div>
