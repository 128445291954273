<div class="content">

  <div mat-dialog-title>
    <h1 *ngIf="update === undefined || !update" class="modal-title">
      <span>Δημιουργία Χρήστη</span>
      <mat-icon style="cursor: pointer;" (click)="close()">close</mat-icon>
    </h1>
    <h1  *ngIf="update === undefined || update" class="modal-title">
      <span>Επεξεργασία Χρήστη</span>
      <mat-icon style="cursor: pointer;" (click)="close()">close</mat-icon>
    </h1>
  </div>

  <div mat-dialog-content>
    <form  [formGroup]="form">
      <mat-grid-list class="form" cols="3" rowHeight="90px">
          <mat-grid-tile colspan="1">
              <mat-form-field style="width: 80%" appearance="standard" class="title">
                  <mat-label>Όνομα</mat-label>
                  <input
                    matInput
                    placeholder="Όνομα"
                    formControlName="name"
                  />
              </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
              <mat-form-field style="width: 80%" appearance="standard" class="title">
                  <mat-label>Επίθετο</mat-label>
                  <input
                    matInput
                    placeholder="Επίθετο"
                    formControlName="surname"
                  />
              </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
              <mat-form-field style="width: 80%" appearance="standard" class="title">
                  <mat-label>Email</mat-label>
                  <input
                    matInput
                    placeholder="Email"
                    formControlName="email"
                    required
                  />

                  <mat-error *ngIf="form.get('email')?.errors?.required"
                      >Παρακαλώ συμπληρώστε email.</mat-error
                  >
                  <mat-error *ngIf="form.get('email')?.errors?.email"
                      >Παρακαλώ συμπληρώστε ένα έγκυρο email.</mat-error
                  >
              </mat-form-field>
          </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list class="form" cols="3" rowHeight="90px">
          <mat-grid-tile colspan="1">
              <mat-form-field style="width: 80%" appearance="standard" class="title">
                  <mat-label>Πόλη</mat-label>
                  <input
                    matInput
                    placeholder="Πόλη"
                    formControlName="city"
                  />
              </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
              <mat-form-field style="width: 80%" appearance="standard" class="title">
                  <mat-label>Διεύθυνση</mat-label>
                  <input
                    matInput
                    placeholder="Διεύθυνση"
                    formControlName="address"
                  />
              </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
              <mat-form-field style="width: 80%" appearance="standard" class="title">
                  <mat-label>Τ.Κ</mat-label>
                  <input
                    matInput
                    placeholder="Τ.Κ"
                    formControlName="zip_code"
                  />
              </mat-form-field>
          </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list class="form" cols="3" rowHeight="120px">
          <mat-grid-tile colspan="1">
              <mat-form-field style="width: 80%" appearance="standard" class="title">
                  <mat-label>Τηλέφωνο</mat-label>
                  <input
                    matInput
                    placeholder="Τηλέφωνο"
                    formControlName="phone_number"
                  />
              </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
              <mat-form-field style="width: 80%" appearance="standard" class="title">
                  <mat-label>Κινητό Τηλέφωνο</mat-label>
                  <input
                    matInput
                    placeholder="Κινητό Τηλέφωνο"
                    formControlName="mobile_number"
                  />
              </mat-form-field>
          </mat-grid-tile>
          <mat-grid-tile colspan="1">
              <mat-form-field style="width: 80%" appearance="standard" class="title">
                  <mat-label>Κωδικός Πρόσβασης</mat-label>
                  <input
                    matInput
                    [type]="hide ? 'password' : 'text'"

                    placeholder="Κωδικός Πρόσβασης"
                    formControlName="password"
                    [required]="!update"
                  />
                  <mat-icon style="cursor: pointer;" (click)="hide = !hide" matSuffix>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>

                  <mat-error *ngIf="form.get('password')?.errors?.required"
                      >Παρακαλώ συμπληρώστε κωδικό πρόσβασης.</mat-error
                  >
                  <mat-error *ngIf="form.get('password')?.errors?.pattern"
                      >Συμπληρώστε έναν έγκυρο Κωδικό πρόσβασης. Από 8 χαρακτήρες και άνω,
                      με τουλάχιστον εναν αριθμό και ένα κεφαλαίο γράμμα.</mat-error
                  >
              </mat-form-field>
          </mat-grid-tile>
      </mat-grid-list>

      <mat-grid-list class="form" cols="3" rowHeight="90px">
        <mat-grid-tile colspan="1">
         
            <mat-form-field style="width: 80%" appearance="standard" class="title">
              <mat-label>Ρόλος</mat-label>
              <mat-select formControlName="role" required>
                <mat-option
                  *ngFor="let role of available_roles"
                  [value]="role.id"
                >
                  {{ role.name }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="form.get('role')?.invalid"
                >Παρακαλώ συμπληρώστε ρόλο.</mat-error
              >
            </mat-form-field>
         
        </mat-grid-tile>
      </mat-grid-list>
     

  </form>
  </div>

  <div mat-dialog-actions class="actions" align="end">
    <mat-dialog-actions>
      <button class="close-btn" mat-raised-button [mat-dialog-close]="false">Ακύρωση</button>

      <button
        class="custom-btn"
        [disabled]="submited || form.invalid"
        (click)="submit()"
        mat-raised-button
      >
        Επιβεβαίωση
      </button>
    </mat-dialog-actions>
  </div>
</div>
