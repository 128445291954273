<div class="mat-elevation-z8 main">
  <mat-tab-group mat-align-tabs="start" dynamicHeight mat-stretch-tabs>
    <mat-tab label="Προτάσεις Βελτίωσης">

      <ng-container *ngIf="!dataloaded">
        <div class="spinner-div">
          <mat-spinner [diameter]="100"></mat-spinner>
        </div>
      </ng-container>
      
      <div [ngClass]="{'hide': !dataloaded}">
        <div style="padding: 20px;">
          <mat-form-field style="width:20%;">
            <mat-label>Αναζήτηση</mat-label>
            <input matInput (keyup)="onSearch()"  [(ngModel)]="search">
          </mat-form-field>
        </div>

        <table
          mat-table
          matSort
          [dataSource]="dataSource"
          class="table"
        >
          <ng-container matColumnDef="order">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let element; let i = index">
              {{ i + 1 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <th style="border: none; width: 30%;" mat-header-cell mat-sort-header *matHeaderCellDef>Όνομα</th>
            <td style="border: none; width: 30%;" mat-cell *matCellDef="let element">{{ element.name }}</td>
          </ng-container>

          <ng-container matColumnDef="like_number">
            <th style="border: none; width: 10%; text-align: center;"  mat-header-cell mat-sort-header *matHeaderCellDef
              >Likes</th
            >
            <td style="border: none; width: 10%; text-align: start; padding-left: 5px;" mat-cell *matCellDef="let element">{{
              element.like_number
            }}</td>
          </ng-container>

          <ng-container matColumnDef="dislike_number">
            <th style="border: none; width: 10%; text-align: center;"  mat-header-cell mat-sort-header *matHeaderCellDef
              >Dislikes</th
            >
            <td style="border: none; width: 10%; text-align: start; padding-left: 5px;"  mat-cell *matCellDef="let element">{{
              element.dislike_number
            }}</td>
          </ng-container>

          <ng-container matColumnDef="approved">
            <th style="border: none; width: 10%; text-align: center;" mat-header-cell mat-sort-header *matHeaderCellDef
              >Κατάσταση</th
            >
            <td style="border: none; width: 10%; text-align: start;" mat-cell *matCellDef="let element" align="left">
              <mat-icon style="cursor: pointer; padding-left: 5px;" matTooltip="Εγκεκριμένη" *ngIf="element.approved">done</mat-icon>
              <mat-icon style="cursor: pointer; padding-left: 5px;" matTooltip="Μη Εγκεκριμένη" *ngIf="!element.approved">clear</mat-icon>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> Ενέργειες </mat-header-cell>
            <mat-cell *matCellDef="let element" align="right">
              <mat-icon class="pointer primary-color" matTooltip="Δείτε την πρόταση" [routerLink]="getSuggestionLink(element)"
                >visibility</mat-icon
              >
              <mat-icon class="pointer close-color" matTooltip="Διαγραφή" (click)="openDeleteDialog(element)"
                >delete</mat-icon
              >
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </table>

        <mat-paginator
          [length]="dataSource.data.length"
          [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
      </div>

    </mat-tab>
    <mat-tab label="Κατηγορίες">
      <app-suggestion-categories></app-suggestion-categories>
    </mat-tab>
  </mat-tab-group>
</div>
