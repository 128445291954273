import { CommonService } from 'src/app/services/common.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Quiz } from 'src/app/interfaces/quiz.interface';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { GdText } from 'src/app/interfaces/good-deed-text.interface';
import { QuizFormComponent } from '../quiz-form/quiz-form.component';

@Component({
  selector: 'app-quiz',
  templateUrl: './quiz.component.html',
  styleUrls: ['./quiz.component.scss'],
})
export class QuizComponent implements OnInit {
  @Input() quiz: Quiz;
  @Input() text: GdText;
  @Output('removed') removed: EventEmitter<any> = new EventEmitter();
  @Output('updated') updated: EventEmitter<any> = new EventEmitter();

  constructor(
    private commonService: CommonService,
    private snackBarService: SnackBarService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {}

  async removeDialog() {
    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.remove();
      }
    });
  }

  async remove() {
    try {
      const response = await this.commonService.deleteRequest(
        `quiz/${this.quiz.id}`
      );

      this.removed.emit();
    } catch (error) {
      console.log(error);
    }
  }

  async editQuiz(): Promise<void> {
    const dialogRef = this.dialog.open(QuizFormComponent, {
      height: '470px',
      width: '300px',
      data: {
        quiz: this.quiz,
        good_deed_text_id: this.text?.id,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.updated.emit(result);
      }
    });
  }
}
