import { Router } from '@angular/router';
import { Report } from 'src/app/interfaces/report.interface';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Suggestion } from 'src/app/interfaces/suggestion.interface';
import { CommonService } from 'src/app/services/common.service';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  average_pos = { lat: 40.653549, lng: 22.898692 };
  available_suggestions: Array<Suggestion> = [];
  available_reports: Array<Report> = [];

  non_approve_suggestions: Array<Suggestion> = [];
  non_approve_reports: Array<Report> = [];
 
  dataloaded = false;

  constructor(private commonService: CommonService) { }

  ngOnInit(): void {
    this.initData();
  }
 
  async initData(): Promise<void> {
    try {
      const response = await this.commonService.getRequest('suggestions/view');
      this.available_suggestions = response.results;

      this.available_suggestions.forEach(el => {
        if (!el.approved) {
          this.non_approve_suggestions.push(el)
        }
      })

      const reportResponse = await this.commonService.getRequest('reports/view');
      this.available_reports = reportResponse.results;

      this.available_reports.forEach(el => {
        if (!el.report.approved) {
          this.non_approve_reports.push(el)
        }
      })
 
      this.dataloaded = true
    } catch (error) {
      console.log(error);
    }
  }
 
}
