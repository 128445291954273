<mat-stepper [linear]="true" #stepper class="stepper">
  <mat-step [stepControl]="firstFormGroup">
    <form [formGroup]="firstFormGroup" class="form">
      <ng-template matStepLabel>Προσθέστε τίτλο</ng-template>

      <div class="stepper-div">
        <mat-form-field style="width: 100%;" appearance="standard">
          <mat-label>Τίτλος</mat-label>

          <input matInput formControlName="title" required />
        </mat-form-field>
      </div>
    </form>
    <div mat-dialog-actions align="end">
      <mat-dialog-actions >
        <button [disabled]="firstFormGroup.invalid" class="add-btn custom-btn" mat-raised-button matStepperNext>Επόμενο</button>
      </mat-dialog-actions>
    </div>
  </mat-step>

  <mat-step [stepControl]="secondFormGroup" label="Προσθέστε Ερωτήσεις">
    <form [formGroup]="secondFormGroup" class="form">
      <div>
        <mat-tab-group
          [selectedIndex]="selected.value"
          (selectedIndexChange)="selected.setValue($event)"
          #tabGroup
        >
          <mat-tab *ngFor="let tab of tabs; let index = index">
            <ng-template mat-tab-label>
              <div class="center">
                <span>Ερώτηση {{ index + 1 }}</span>
                <button
                  *ngIf="index !== 0"
                  (click)="removeQuestion(index)"
                  mat-icon-button
                  class="close-color"
                  style="padding-left: 10px;"
                  matTooltip="Διαγραφή"
                >
                  <mat-icon>clear</mat-icon>
                </button>
              </div>
            </ng-template>

            <app-question-stepper-form
              *ngIf="questions !== undefined"
              [question]="questions[index]"
              #questionForm
            ></app-question-stepper-form>
          </mat-tab>
          <mat-tab disabled>
            <ng-template mat-tab-label>
              <button class="primary-color" matTooltip="Προσθήκη ερώτησης" mat-icon-button (click)="addQuestion(true)">
                <mat-icon>add_circle</mat-icon>
              </button>
            </ng-template>
          </mat-tab>
        </mat-tab-group>
      </div>
    </form>

    <div mat-dialog-actions align="end">
      <mat-dialog-actions>
        <button class="close-btn" mat-raised-button matStepperPrevious>Πίσω</button>
        
        <button class="custom-btn" [disabled]="!validQuestions()" mat-raised-button (click)="checkQuestions()">
          Επόμενο
        </button>
      </mat-dialog-actions>
    </div>

    <!-- <div class="actions-div">
      <button class="mr-2" mat-raised-button matStepperPrevious>Πίσω</button>

      <button class="add-btn" mat-raised-button (click)="checkQuestions()">
        Επόμενο
      </button>
    </div> -->
  </mat-step>

  <mat-step label="Επιβεβαίωση">
    <form class="form">
      <div class="questions-div">
        <div
          *ngFor="let question of questions; let index = index"
          class="question"
        >
          <div><strong>Ερώτηση {{ index + 1 }}</strong></div>
          <div>
            <h3><strong class="primary-color">{{ question.question }}</strong></h3>
          </div>
          <div>1) {{ question.answer_one }}</div>
          <div>2) {{ question.answer_two }}</div>
          <div>3) {{ question.answer_three }}</div>
          <div>4) {{ question.answer_four }}</div>
          <div style="margin-top: 10px;">Σωστή απάντηση: <strong>{{ question.correct }}</strong></div>
        </div>
      </div>
    </form>

    <div mat-dialog-actions align="end">
      <mat-dialog-actions>
        <button class="close-btn" mat-raised-button matStepperPrevious>Πίσω</button>
        <button class="add-btn custom-btn" mat-raised-button (click)="submit()">
          Επιβεβαίωση
        </button>
      </mat-dialog-actions>
    </div>

  </mat-step>
</mat-stepper>
