import {Injectable} from '@angular/core';
import {environment} from '../../environments/environment';

import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class InterceptInterceptor implements HttpInterceptor {

  constructor() {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = localStorage.getItem('token');
    request = request.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        Authorization: `jwt ${token}`,
      },
      url: `${environment.apiUrl}/${request.url}`
    });

    return next.handle(request);
  }
}
