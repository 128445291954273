import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { Report } from 'src/app/interfaces/report.interface';
import { Suggestion } from 'src/app/interfaces/suggestion.interface';
import { CommonService } from 'src/app/services/common.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';

@Component({
  selector: 'app-non-approve-reports',
  templateUrl: './non-approve-reports.component.html',
  styleUrls: ['./non-approve-reports.component.scss']
})
export class NonApproveReportsComponent implements OnInit {

  @Input() data: Array<Report>;  

  displayedColumns: string[] = [
    'name',
    'approved',
  ];
 
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  length: any;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 100];

  pageEvent: PageEvent;
  dataSource: any;
  
  constructor(private commonService: CommonService, private snackBarService: SnackBarService) {
    this.dataSource = new MatTableDataSource<Report>(this.data);
   }

  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    // this.paginator.length = this.data.length;
  }

  async approveReport(index: number) {
    let report = this.data[index].report
    try {
      const respone = await this.commonService.getRequest(
        `reports/approve/${report.id}`
      );
      this.data.splice(index, 1)
      this.snackBarService.displaySuccess('Η πρόταση εγκρίθηκε επιτυχώς.');
      this.table.renderRows();
    } catch (error: any) {
      this.snackBarService.displaySuccess(error?.message);
    }
  }

}
