import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { CommonService } from 'src/app/services/common.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Question } from 'src/app/interfaces/question.interface';

@Component({
  selector: 'app-question-stepper-form',
  templateUrl: './question-stepper-form.component.html',
  styleUrls: ['./question-stepper-form.component.scss'],
})
export class QuestionStepperFormComponent implements OnInit {
  @Input() question: Question;

  submited: boolean = false;
  update: boolean = false;

  form = this.formBuilder.group({
    question: ['', [Validators.required]],
    answer_one: ['', [Validators.required]],
    answer_two: ['', [Validators.required]],
    answer_three: ['', [Validators.required]],
    answer_four: ['', [Validators.required]],
    correct: ['', [Validators.required]],
  });

  constructor(public formBuilder: FormBuilder) {}

  ngOnInit(): void {
    // if (this.data.update !== undefined) {
    //   this.update = this.data.update;
    // }
    if (this.question) {
      this.form = this.formBuilder.group({
        question: [this.question.question, [Validators.required]],
        answer_one: [this.question.answer_one, [Validators.required]],
        answer_two: [this.question.answer_two, [Validators.required]],
        answer_three: [this.question.answer_three, [Validators.required]],
        answer_four: [this.question.answer_four, [Validators.required]],
        correct: [this.question.correct, [Validators.required]],
      });
    }
  }

  clearForm() {
    this.form.reset();
    this.form.controls['question'].setErrors(null);
    this.form.controls['answer_one'].setErrors(null);
    this.form.controls['answer_two'].setErrors(null);
    this.form.controls['answer_three'].setErrors(null);
    this.form.controls['answer_four'].setErrors(null);
    this.form.controls['correct'].setErrors(null);
  }

  submit() {
    this.submited = true;
 
  }

  checkValid() {
    return this.form.valid;
  }

  getData() {
    return {
      // id: this.data.question.id,
      question: this.form.get('question')?.value,
      answer_one: this.form.get('answer_one')?.value,
      answer_two: this.form.get('answer_two')?.value,
      answer_three: this.form.get('answer_three')?.value,
      answer_four: this.form.get('answer_four')?.value,
      correct: this.form.get('correct')?.value,
    };
  }

  // formatData() {
  //   var question;

  //   if (this.data.question) {
  //     question = {
  //       id: this.data.question.id,
  //       question: this.form.get('question')?.value,
  //       answer_one: this.form.get('answer_one')?.value,
  //       answer_two: this.form.get('answer_two')?.value,
  //       answer_three: this.form.get('answer_three')?.value,
  //       correct: this.form.get('correct')?.value,
  //     };
  //   } else {
  //     question = {
  //       question: this.form.get('question')?.value,
  //       answer_one: this.form.get('answer_one')?.value,
  //       answer_two: this.form.get('answer_two')?.value,
  //       answer_three: this.form.get('answer_three')?.value,
  //       correct: this.form.get('correct')?.value,
  //     };
  //   }

  //   return question;
  // }
}
