<div class="mat-elevation-z8 main">
  <mat-toolbar>
    <div fxLayout="row" class="nav-div" fxFlex="100"  *ngIf="suggestion !== undefined" fxLayoutAlign="start center center">
      <div fxLayout="column" fxFlex="90">
        <div fxLayout="row wrap">
          <label style="white-space: break-spaces;">{{ suggestion.name }}</label>
        </div>
      </div>
      <div fxLayout="column" fxFlex="10">
        <div fxLayout="row">
          <button
            mat-icon-button
            (click)="approve()"
            *ngIf="!suggestion.approved"
            matTooltip="Πατήστε για έγκριση"
          >
            <mat-icon>verified</mat-icon>
          </button>
          <button
            mat-icon-button
            *ngIf="suggestion.approved"
            (click)="disapprove()"
            matTooltip="Πατήστε για μη έγκριση"
          >
            <mat-icon>block</mat-icon>
          </button>
          <button (click)="openDeleteDialog()" mat-icon-button>
            <mat-icon class="close-color" matTooltip="Διαγραφή">delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
<!--    <mat-grid-list cols="12" class="nav-div" *ngIf="suggestion !== undefined">-->
<!--      <mat-grid-tile colspan="9">-->
<!--        <div class="align-left">-->
<!--          {{ suggestion.name }}-->
<!--        </div>-->
<!--      </mat-grid-tile>-->

<!--      <mat-grid-tile colspan="3">-->
<!--        <div class="align-right">-->
<!--          <button-->
<!--            mat-icon-button-->
<!--            (click)="approve()"-->
<!--            *ngIf="!suggestion.approved"-->
<!--            matTooltip="Πατήστε για έγκριση"-->
<!--          >-->
<!--            <mat-icon>verified</mat-icon>-->
<!--          </button>-->
<!--          <button-->
<!--            mat-icon-button-->
<!--            *ngIf="suggestion.approved"-->
<!--            (click)="disapprove()"-->
<!--            matTooltip="Πατήστε για μη έγκριση"-->
<!--          >-->
<!--            <mat-icon>block</mat-icon>-->
<!--          </button>-->
<!--          <button (click)="openDeleteDialog()" mat-icon-button>-->
<!--            <mat-icon class="close-color" matTooltip="Διαγραφή">delete</mat-icon>-->
<!--          </button>-->
<!--        </div>-->
<!--      </mat-grid-tile>-->
<!--    </mat-grid-list>-->
  </mat-toolbar>
  <div class="context" *ngIf="suggestion === undefined">
    <div class="spinner-div">
      <mat-spinner [diameter]="100"></mat-spinner>
    </div>
  </div>
  <div class="context" *ngIf="suggestion !== undefined">
    <table class="details">
      <tr>
        <td class="primary-color"><strong>Κατηγορία:</strong></td>
        <td>{{ suggestion.category_name }}</td>
      </tr>
      <tr>
        <td class="primary-color"><strong>Προστέθηκε:</strong></td>
        <td>{{ getDate(suggestion) }}</td>
      </tr>
      <tr>
        <td class="primary-color" style="padding-right: 10px"><strong>Κατάσταση Πρότασης:</strong></td>
        <td> {{ getApprovedName() }}</td>
      </tr>
    </table>


    <div style="padding-top: 20px;">
      <h4 class="primary-color"><strong>Περιεχόμενο Πρότασης</strong></h4>
      <p>
        {{ suggestion.context }}
      </p>
    </div>


    <div class="like">
      <div class="number">
        <div class="primary-color">
          {{ suggestion.like_number }}
        </div>
        <div>
          <mat-icon class="primary-color">thumb_up</mat-icon>
        </div>
      </div>
      <div class="number" style="padding-left: 10px;">
        <div class="close-color">
          {{ suggestion.dislike_number }}
        </div>
        <div>
          <mat-icon class="close-color">thumb_down</mat-icon>
        </div>
      </div>
    </div>

    <agm-map
      class="map"
      #map
      [latitude]="suggestion.lat"
      [longitude]="suggestion.lon"
      [zoom]="14"
      (mapReady)="onMapReady($event)"
    >
    </agm-map>
</div>

</div>
