import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { CommonService } from 'src/app/services/common.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit {
  @Output('created') reload: EventEmitter<any> = new EventEmitter();

  // form = this.formBuilder.group({
  //   name: ['', [Validators.required]],
  //   surname: ['', [Validators.required]],
  //   email: ['', [Validators.compose([Validators.required, Validators.email])]],
  //   phone_number: ['', [Validators.required]],
  //   mobile_number: ['', [Validators.required]],
  //   address: ['', [Validators.required]],
  //   city: ['', [Validators.required]],
  //   zip_code: ['', [Validators.compose([Validators.required,Validators.pattern("^[0-9]*$")])]],
  //   role: ['', [Validators.required]],
  //   password: [
  //     '',
  //     Validators.compose([
  //       // Validators.minLength(8),
  //       Validators.required,
  //       Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+.{7,}$'),
  //     ]),
  //   ],
  // });

  form: any;

  submited: boolean = false;
  update: boolean = false;

  available_roles = [
    { name: 'Χρήστης', id: 0 },
    { name: 'Διαχειριστής', id: 1 },
  ];

  hide = true;

  constructor(
    public formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    private commonService: CommonService,
    private dialogRef: MatDialogRef<UserFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    if (this.data.update !== undefined) {
      this.update = this.data.update;
    }

    if (this.data.user) {
 
      this.form = this.formBuilder.group({
        name: [this.data.user.name],
        surname: [this.data.user.surname],
        email: [
          this.data.user.email,
          [Validators.compose([Validators.required, Validators.email])],
        ],
        phone_number: [this.data.user.phone_number],
        mobile_number: [this.data.user.mobile_number],
        address: [this.data.user.address],
        city: [this.data.user.city],
        zip_code: [this.data.user.zip_code, [Validators.compose([Validators.pattern("^[0-9]*$")])]],
        role: [this.data.user.role, [Validators.required]],
        password: [
          '',
          Validators.compose([
            // Validators.minLength(8),
            Validators.pattern(
              '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+$'
            ),
          ]),
        ],
      });
    } else {
      this.form = this.formBuilder.group({
        name: [''],
        surname: [''],
        email: [
          '',
          [Validators.compose([Validators.required, Validators.email])],
        ],
        phone_number: [''],
        mobile_number: [''],
        address: [''],
        city: [''],
        zip_code: ['', [Validators.compose([Validators.pattern("^[0-9]*$")])]],
        role: ['', [Validators.required]],
        password: [
          '',
          Validators.compose([
            Validators.required,
            // Validators.minLength(8),
            Validators.pattern(
              '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+.{7,}$'
            ),
          ]),
        ],
      });
    }
  }

  async submit(): Promise<void> {
    this.submited = true;
    if (!this.form.valid) {
      this.snackBarService.displayError('Συμπληρώστε όλα τα πεδία.');
    } else {
      await this.postForm();
    }
    this.submited = false;
  }

  formatData() {
    const user = {
      name: this.form.get('name')?.value,
      surname: this.form.get('surname')?.value,
      email: this.form.get('email')?.value,
      phone_number: this.form.get('phone_number')?.value,
      mobile_number: this.form.get('mobile_number')?.value,
      password: this.form.get('password')?.value,
      city: this.form.get('city')?.value,
      address: this.form.get('address')?.value,
      zip_code: this.form.get('zip_code')?.value,
    };

    return user;
  }

  async postForm(): Promise<void> {
    const formData = this.formatData();

    const body = { user: formData };

    const role = this.form.get('role')?.value;

    let response;

    if (this.update) {
      response = await this.commonService.putRequest(
        `users/update/${this.data.user.id}`,
        body
      );

      if (role) {
        this.snackBarService.displaySuccess(
          'Ο διαχειριστής αποθηκεύτηκε επιτυχώς.'
        );
      } else {
        this.snackBarService.displaySuccess('Ο χρήστης αποθηκεύτηκε επιτυχώς.');

      }
    } else {
      if (role) {
        response = await this.commonService.postRequest(
          'users/create-admin',
          body
        );

        this.snackBarService.displaySuccess(
          'Ο διαχειριστής αποθηκεύτηκε επιτυχώς.'
        );
      } else {
        response = await this.commonService.postRequest('users/register', body);

        this.snackBarService.displaySuccess('Ο χρήστης αποθηκεύτηκε επιτυχώς.');

      }
    }


    this.dialogRef.close({ quiz: body });

    this.clearForm();
  }

  clearForm() {
    this.form.reset();
    this.form.controls['name'].setErrors(null);
    this.form.controls['surname'].setErrors(null);
    this.form.controls['email'].setErrors(null);
    this.form.controls['phone_number'].setErrors(null);
    this.form.controls['mobile_number'].setErrors(null);
    this.form.controls['password'].setErrors(null);
    this.form.controls['city'].setErrors(null);
    this.form.controls['address'].setErrors(null);
    this.form.controls['zip_code'].setErrors(null);
    this.form.controls['role'].setErrors(null);
  }

  close() {
    this.dialogRef.close();
  }
}
