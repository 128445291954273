import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss'],
})
export class DrawerComponent implements OnInit {
  routes = [
    { path: '/home', name: 'Αρχική Σελίδα' },
    { path: '/suggestions', name: 'Προτάσεις Βελτίωσης' },
    { path: '/reports', name: 'Αναφορές Προβλημάτων' },
    { path: '/good-deeds', name: 'Καλές Πράξεις' },
    { path: '/guides', name: 'Ενημερωτικοί Οδηγοί' },
    { path: '/all-quiz', name: 'Quiz' },
    // { path: '/questions', name: 'Ερωτήσεις' },
    { path: '/users', name: 'Χρήστες' },
    { path: '/settings', name: 'Ρυθμίσεις' },
  ];

  constructor(public router: Router) {}

  ngOnInit(): void {}
}
