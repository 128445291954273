<div mat-dialog-title>
    <h1>Δημιουργία Νέου Διαχειριστή</h1>
</div>
<div mat-dialog-content>
    <form  [formGroup]="form">
        <mat-grid-list class="form" cols="3" rowHeight="90px">
            <mat-grid-tile colspan="1">
                <mat-form-field style="width: 80%" appearance="standard" class="title">
                    <mat-label>Όνομα</mat-label>
                    <input
                      matInput
                      placeholder="Όνομα"
                      formControlName="name"
                      required
                    />
                    <mat-error *ngIf="form.get('name')?.errors?.required"
                        >Παρακαλώ συμπληρώστε όνομα.</mat-error
                    >
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="1">
                <mat-form-field style="width: 80%" appearance="standard" class="title">
                    <mat-label>Επίθετο</mat-label>
                    <input
                      matInput
                      placeholder="Επίθετο"
                      formControlName="surname"
                      required
                    />
                    <mat-error *ngIf="form.get('surname')?.errors?.required"
                        >Παρακαλώ συμπληρώστε επίθετο.</mat-error
                    >
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="1">
                <mat-form-field style="width: 80%" appearance="standard" class="title">
                    <mat-label>Email</mat-label>
                    <input
                      matInput
                      placeholder="Email"
                      formControlName="email"
                      required
                    />

                    <mat-error *ngIf="form.get('email')?.errors?.required"
                        >Παρακαλώ συμπληρώστε email.</mat-error
                    >
                    <mat-error *ngIf="form.get('email')?.errors?.email"
                        >Παρακαλώ συμπληρώστε ένα έγκυρο email.</mat-error
                    >
                </mat-form-field>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list class="form" cols="3" rowHeight="90px">
            <mat-grid-tile colspan="1">
                <mat-form-field style="width: 80%" appearance="standard" class="title">
                    <mat-label>Πόλη</mat-label>
                    <input
                      matInput
                      placeholder="Πόλη"
                      formControlName="city"
                      required
                    />
                    <mat-error *ngIf="form.get('city')?.errors?.required"
                        >Παρακαλώ συμπληρώστε πόλη.</mat-error
                    >
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="1">
                <mat-form-field style="width: 80%" appearance="standard" class="title">
                    <mat-label>Διεύθυνση</mat-label>
                    <input
                      matInput
                      placeholder="Διεύθυνση"
                      formControlName="address"
                      required
                    />
                    <mat-error *ngIf="form.get('address')?.errors?.required"
                        >Παρακαλώ συμπληρώστε διεύθυνση.</mat-error
                    >
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="1">
                <mat-form-field style="width: 80%" appearance="standard" class="title">
                    <mat-label>Τ.Κ</mat-label>
                    <input
                      matInput
                      placeholder="Τ.Κ"
                      formControlName="zip_code"
                      required
                    />

                    <mat-error *ngIf="form.get('zip_code')?.errors?.required"
                        >Παρακαλώ συμπληρώστε Τ.Κ.</mat-error
                    >
                    <mat-error *ngIf="form.get('zip_code')?.errors?.required"
                        >Παρακαλώ συμπληρώστε T.K.</mat-error
                    >
                </mat-form-field>
            </mat-grid-tile>
        </mat-grid-list>
        <mat-grid-list class="form" cols="3" rowHeight="120px">
            <mat-grid-tile colspan="1">
                <mat-form-field style="width: 80%" appearance="standard" class="title">
                    <mat-label>Τηλέφωνο</mat-label>
                    <input
                      matInput
                      placeholder="Τηλέφωνο"
                      formControlName="phone_number"
                      required
                    />
                    <mat-error *ngIf="form.get('phone_number')?.errors?.required">
                        Παρακαλώ συμπληρώστε τηλέφωνο.
                    </mat-error>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="1">
                <mat-form-field style="width: 80%" appearance="standard" class="title">
                    <mat-label>Κινητό Τηλέφωνο</mat-label>
                    <input
                      matInput
                      placeholder="Κινητό Τηλέφωνο"
                      formControlName="mobile_number"
                      required
                    />
                    <mat-error *ngIf="form.get('phone_number')?.errors?.required">
                        Παρακαλώ συμπληρώστε κινητό τηλέφωνο.
                    </mat-error>
                </mat-form-field>
            </mat-grid-tile>
            <mat-grid-tile colspan="1">
                <mat-form-field style="width: 80%" appearance="standard" class="title">
                    <mat-label>Κωδικός Πρόσβασης</mat-label>
                    <input
                      matInput
                      [type]="hide ? 'password' : 'text'"

                      placeholder="Κωδικός Πρόσβασης"
                      formControlName="password"
                      required
                    />
                    <mat-icon style="cursor: pointer;" (click)="hide = !hide" matSuffix>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>

                    <mat-error *ngIf="form.get('password')?.errors?.required"
                        >Παρακαλώ συμπληρώστε κωδικό πρόσβασης.</mat-error
                    >
                    <mat-error *ngIf="form.get('password')?.errors?.pattern"
                        >Συμπληρώστε έναν έγκυρο Κωδικό πρόσβασης. Από 8 χαρακτήρες και άνω,
                        με τουλάχιστον εναν αριθμό και ένα κεφαλαίο γράμμα.</mat-error
                    >
                </mat-form-field>
            </mat-grid-tile>
        </mat-grid-list>
    </form>
</div>
<div mat-dialog-actions align="end">
    <button class="custom-btn" [disabled]="form.invalid" mat-raised-button (click)="submit()">
        Επιβεβαίωση
    </button>
</div>
