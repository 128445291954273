<div class="content">
     
    <div class="stats-div">
        <h3><strong>Παρακάτω βλέπετε την κατάσταση των καταγραφών</strong> </h3>
        <mat-card>
            <mat-tab-group  mat-align-tabs="start" dynamicHeight mat-stretch-tabs>
                <mat-tab *ngIf="!dataloaded" [ngClass]="{'hide': dataloaded}">
                    <mat-card-content style="height: 30vh; overflow-y: auto;">
                        <div class="spinner-div">
                            <mat-spinner [diameter]="100"></mat-spinner>
                        </div>
                    </mat-card-content>
                </mat-tab>
                <mat-tab [ngClass]="{'hide': !dataloaded}" *ngIf="non_approve_suggestions.length > 0">
                    <ng-template mat-tab-label>
                        <div>
                            <span>
                                Μη Εγκεκριμένες Προτάσεις
                            </span>
                            <span class="badge">
                                {{non_approve_suggestions.length}}
                            </span>
                        </div>
                    </ng-template>
                    <mat-card-content style="height: 30vh; overflow-y: auto;">
                        <app-non-approve-suggestions [data]="non_approve_suggestions">
                        </app-non-approve-suggestions>
                    </mat-card-content>
                </mat-tab>
                <mat-tab [ngClass]="{'hide': !dataloaded}" *ngIf="non_approve_reports.length > 0">
                    <ng-template mat-tab-label>
                        <div>
                            <span>
                                Μη Εγκεκριμένες Αναφορές
                            </span>
                            <span class="badge">
                                {{non_approve_reports.length}}
                            </span>
                        </div>
                    </ng-template>
                    <mat-card-content style="height: 30vh;  overflow-y: auto;">
                        <app-non-approve-reports [data]="non_approve_reports">
                        </app-non-approve-reports>
                    </mat-card-content>
                </mat-tab>
                <mat-tab [ngClass]="{'hide': !dataloaded}" label="Δημοφιλέστερες Προτάσεις" *ngIf="available_suggestions.length > 0">
                    <mat-card-content style="height: 30vh;  overflow-y: auto;">
                        <app-best-suggestions [data]="available_suggestions">
                        </app-best-suggestions>
                    </mat-card-content>
                </mat-tab>
                <mat-tab [ngClass]="{'hide': !dataloaded}" label="Δημοφιλέστερες Αναφορές" *ngIf="available_reports.length > 0">
                    <mat-card-content style="height: 30vh; overflow-y: auto;">
                        <app-best-reports [data]="available_reports">
                        </app-best-reports>
                    </mat-card-content>
                </mat-tab>
            </mat-tab-group>
        </mat-card>
        <div class="logos">
            <div style="text-align: center;">
                <img style="width: 100%; height: auto" src="../../../assets/main_logo.png">
            </div>
            <div class="logos-div">
                <img style="max-width: 10%; height: auto; padding-left: 2%;"  [ngStyle.lt-lg]="{'width':'10%'}" src="../../../assets/logo_1.png">
                <img style="max-width: 50%; height: auto; padding-right: 2%;"  [ngStyle.lt-lg]="{'width':'30%'}" src="../../../assets/logo_2.jpg">
            </div>
        </div>
    </div>

    <div class="map-div">
        <h3><strong>Ο παρακάτω χάρτης παρουσιάζει τις καταγραφές που έχουν γίνει στο Δήμο</strong> </h3>
        <app-home-map></app-home-map>
    </div>
</div>
