<mat-toolbar class="toolbar">
  <mat-grid-list cols="12" class="nav-div">
    <mat-grid-tile colspan="2">
      <div class="align-left">
        <button mat-icon-button (click)="toggleDrawer()">
          <mat-icon>menu</mat-icon>
        </button>
      </div>
    </mat-grid-tile>
    <mat-grid-tile colspan="10">
      <div class="align-right center">
        <span>{{ getUserName() }}</span>
        <button matTooltip="Αποσύνδεση" mat-icon-button aria-label="Αποσύνδεση" (click)="confirmLogout()">
          <mat-icon>exit_to_app</mat-icon>
        </button>
      </div>
    </mat-grid-tile>
  </mat-grid-list>
</mat-toolbar>
