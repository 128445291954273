import { QuestionFormComponent } from './../question-form/question-form.component';
import { Question } from './../../interfaces/question.interface';
import { CommonService } from './../../services/common.service';
import { Component, OnInit, ViewChild } from '@angular/core';

import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { OrderPipe } from 'ngx-order-pipe';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.scss'],
})
export class QuestionsComponent implements OnInit {
  displayedColumns: string[] = ['order', 'question', 'actions'];
  questions: Array<Question> = [];
  available_questions: Array<Question> = [];

  dataSource = new MatTableDataSource<Question>(this.questions);

  length: any;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 100];

  pageEvent: PageEvent;

  latest_sort: any;

  tabIndex: number = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private commonService: CommonService,
    private orderPipe: OrderPipe,
    private snackBarService: SnackBarService,
    public dialog: MatDialog
  ) {}

  rowClick(row: any) {
    console.log('Row clicked: ', row);
  }

  ngOnInit(): void {
    this.initQuestions();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  reload(): void {
    this.initQuestions();
    this.tabIndex = 0;
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(',')
        .map((str) => +str);
    }
  }

  async initQuestions(): Promise<void> {
    try {
      const response = await this.commonService.getRequest('questions/');

      this.questions = response.results;

      this.available_questions = this.questions.slice(0, this.pageSize);

      this.paginator.length = this.questions.length;
    } catch (error) {
      console.log(error);
    }
  }

  pagerChange(e: any): void {
    if (e.pageIndex === 0) {
      this.changePage(0, e.pageSize);
    } else {
      this.changePage(e.pageSize * e.pageIndex, e.pageSize);
    }
  }

  changePage(index: number, size: number) {
    this.available_questions = this.questions.slice(index, index + size);
  }

  sortData(e: any) {
    this.latest_sort = e;
    if (!e.direction) {
      this.available_questions = this.orderPipe.transform(
        this.available_questions,
        'id',
        true
      );
    } else {
      this.available_questions = this.orderPipe.transform(
        this.available_questions,
        e.active,
        e.direction === 'asc' ? false : true
      );
    }
  }

  async deleteQuestions(e: any) {
    try {
      const results = await this.commonService.deleteRequest(
        `questions/${e.id}`
      );

      let guides_index = this.questions.map((item) => item.id).indexOf(e.id);

      this.questions.splice(guides_index, 1);

      this.available_questions = this.questions;

      if (this.latest_sort !== undefined) {
        this.sortData(this.latest_sort);
      } else {
        this.sortData({
          direction: 'asc',
        });
      }

      this.snackBarService.displaySuccess('Η ερώτηση διεγράφη επιτυχώς.');
    } catch (error: any) {
      this.snackBarService.displayError(error?.error?.message);
    }
  }

  getLink(question: Question) {
    return `/question/${question.id}`;
  }

  openDeleteDialog(question: Question): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteQuestions(question);
      }
    });
  }

  edit(question: Question) {
    const dialogRef = this.dialog.open(QuestionFormComponent, {
      data: {
        question: question,
        update: true,
      },
      width: '500px',
      height: '510px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.reload();
      }
    });
  }

  create() {
    const dialogRef = this.dialog.open(QuestionFormComponent, {
      data: {},
      width: '500px',
      height: '510px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.reload();
      }
    });
  }
}
