import { CommonService } from './../../services/common.service';
import { Component, OnInit, ViewChild } from '@angular/core';

import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { OrderPipe } from 'ngx-order-pipe';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { CreateDialogComponentComponent } from '../create-dialog-component/create-dialog-component.component';
import { UpdateDialogComponent } from '../update-dialog/update-dialog.component';
import { ReportSubCategory } from 'src/app/interfaces/report-sub-category.interface';

import { SubcategoryDialogComponent } from '../subcategory-dialog/subcategory-dialog.component';

@Component({
  selector: 'app-report-sub-categories',
  templateUrl: './report-sub-categories.component.html',
  styleUrls: ['./report-sub-categories.component.scss'],
})
export class ReportSubCategoriesComponent implements OnInit {
  displayedColumns: string[] = ['order', 'name', 'category', 'actions'];
  categories: Array<ReportSubCategory> = [];
  available_categories: Array<ReportSubCategory> = [];

  dataSource = new MatTableDataSource<ReportSubCategory>(this.categories);

  length: any;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 100];

  pageEvent: PageEvent;

  latest_sort: any;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private commonService: CommonService,
    private orderPipe: OrderPipe,
    private snackBarService: SnackBarService,
    public dialog: MatDialog
  ) {}

  rowClick(row: any) {
    console.log('Row clicked: ', row);
  }

  ngOnInit(): void {
    this.initCategories();
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(',')
        .map((str) => +str);
    }
  }

  async initCategories(): Promise<void> {
    try {
      const response = await this.commonService.getRequest(
        'report-subcategories/view'
      );

      this.categories = response.results;

      this.available_categories = this.categories.slice(0, this.pageSize);
      this.paginator.length = this.categories.length;
    } catch (error) {
      console.log(error);
    }
  }

  pagerChange(e: any): void {
    if (e.pageIndex === 0) {
      this.changePage(0, e.pageSize);
    } else {
      this.changePage(e.pageSize * e.pageIndex, e.pageSize);
    }
  }

  changePage(index: number, size: number) {
    this.available_categories = this.categories.slice(index, index + size);
  }

  sortData(e: any) {
    this.latest_sort = e;
    if (!e.direction) {
      this.available_categories = this.orderPipe.transform(
        this.available_categories,
        'id',
        true
      );
    } else {
      if (e.active === 'category') {
        this.available_categories = this.orderPipe.transform(
          this.available_categories,
          e.active,
          e.direction === 'asc' ? false : true
        );
      } else {
        this.available_categories = this.orderPipe.transform(
          this.available_categories,
          e.active,
          e.direction === 'asc' ? false : true
        );
      }
    }
  }

  async delete(e: any) {
    try {
      const results = await this.commonService.deleteRequest(
        `report-subcategories/${e.id}`
      );

      let categories_index = this.categories
        .map((item) => item.id)
        .indexOf(e.id);

      this.categories.splice(categories_index, 1);

      this.available_categories = this.categories;

      if (this.latest_sort !== undefined) {
        this.sortData(this.latest_sort);
      } else {
        this.sortData({
          direction: 'asc',
        });
      }

      this.snackBarService.displaySuccess('Η υποκατηγορία διεγράφη επιτυχώς.');
    } catch (error: any) {
      this.snackBarService.displayError(error?.message);
    }
  }

  openDeleteDialog(category: ReportSubCategory): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.delete(category);
      }
    });
  }

  // edit(category: ReportSubCategory) {
  //   const dialogRef = this.dialog.open(SubcategoryDialogComponent, {
  //     height: '300px',
  //   });

  //   dialogRef.afterClosed().subscribe((result) => {
  //     if (result !== undefined && result) {
  //       this.postUpdate(category, result);
  //     }
  //   });
  // }

  create() {
    const dialogRef = this.dialog.open(SubcategoryDialogComponent, {
      height: '290px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined && result) {
        this.postCreate(result);
      }
    });
  }

  // async postUpdate(category: ReportSubCategory, name: string): Promise<void> {
  //   try {
  //     const body = {
  //       category: {
  //         name: name,
  //       },
  //     };
  //     const repsonse = await this.commonService.putRequest(
  //       `report-subcategories/update/${category.id}`,
  //       body
  //     );

  //     this.categories.map((element) => {
  //       if (element.id === category.id) {
  //         element.name = name;
  //       }
  //     });

  //     this.available_categories.map((element) => {
  //       if (element.id === category.id) {
  //         element.name = name;
  //       }
  //     });

  //     this.snackBarService.displaySuccess(
  //       'Η υποκατηγορία αποθηκεύτηκε με επιτυχία.'
  //     );
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  async postCreate(data: any): Promise<void> {
    try {
      const body = {
        sub_category: {
          name: data.input,
          category_id: data.category,
        },
      };
      const repsonse = await this.commonService.postRequest(
        `report-subcategories/create`,
        body
      );

      this.categories.push(repsonse);
      this.available_categories.push(repsonse);

      if (this.latest_sort !== undefined) {
        this.sortData(this.latest_sort);
      } else {
        this.sortData({
          direction: 'asc',
        });
      }

      this.snackBarService.displaySuccess(
        'Η υποκατηγορία δημιουργήθηκε επιτυχώς.'
      );
    } catch (error) {
      console.log(error);
    }
  }
}
