<mat-dialog-content class="mat-typography dialog">
  <ng-container mat-dialog-title>
    <h1 class="modal-title">
      <span>Προσθήκη Νέας Κατηγορίας</span>
      <mat-icon style="cursor: pointer;" (click)="close()">close</mat-icon>
    </h1>
  </ng-container>

  <mat-form-field class="field">
    <mat-label>Όνομα</mat-label>
    <input matInput [(ngModel)]="input" />
  </mat-form-field>
</mat-dialog-content>

<div mat-dialog-actions align="end">
  <mat-dialog-actions >
    <button class="close-btn" mat-raised-button [mat-dialog-close]="false">Ακύρωση</button>
    <button class="custom-btn" [disabled]="!input" mat-raised-button [mat-dialog-close]="input">
      Προσθήκη
    </button>
  </mat-dialog-actions>
</div>
