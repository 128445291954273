import { UserService } from './../../services/user.service';
import { UserFormComponent } from './../user-form/user-form.component';
import { CommonService } from './../../services/common.service';
import { Component, OnInit, ViewChild } from '@angular/core';

import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { OrderPipe } from 'ngx-order-pipe';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

import { User } from 'src/app/interfaces/user.interface';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  displayedColumns: string[] = [
    'order',
    'name',
    'surname',
    'email',
    'role',
    'points',
    'actions',
  ];
  users: Array<User> = [];
  available_users: Array<User> = [];

  dataSource = new MatTableDataSource<User>();

  length: any;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 100];

  pageEvent: PageEvent;

  latest_sort: any;
  tabIndex: number = 0;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  current_user: User;
  dataloaded = false;

  search = ""
  constructor(
    private commonService: CommonService,
    private orderPipe: OrderPipe,
    private snackBarService: SnackBarService,
    public dialog: MatDialog,
    public userService: UserService
  ) {}

  rowClick(row: any) {
    console.log('Row clicked: ', row);
  }

  ngOnInit(): void {
    this.current_user = this.userService.getUser();
    this.initData();
  }

  reload(): void {
    this.initData();
    this.tabIndex = 0;
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(',')
        .map((str) => +str);
    }
  }

  async initData(): Promise<void> {
    try {
      const response = await this.commonService.getRequest('users/view');

      this.users = response.results;

      this.available_users = this.users 

      this.dataSource.data = this.available_users;

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataloaded = true

    } catch (error) {
      console.log(error);
    }
  }

  pagerChange(e: any): void {
    if (e.pageIndex === 0) {
      this.changePage(0, e.pageSize);
    } else {
      this.changePage(e.pageSize * e.pageIndex, e.pageSize);
    }
  }

  changePage(index: number, size: number) {
    this.available_users = this.users.slice(index, index + size);
  }

  sortData(e: any) {
    this.latest_sort = e;
    if (!e.direction) {
      this.available_users = this.orderPipe.transform(
        this.available_users,
        'id',
        true
      );
    } else {
      this.available_users = this.orderPipe.transform(
        this.available_users,
        e.active,
        e.direction === 'asc' ? false : true
      );
    }
  }

  async deleteuser(e: any) {
    try {
      const results = await this.commonService.deleteRequest(`users/${e.id}`);

      let users_index = this.users.map((item) => item.id).indexOf(e.id);

      this.users.splice(users_index, 1);

      this.available_users = this.users;

      this.dataSource.data = this.available_users;

      if (this.latest_sort !== undefined) {
        this.sortData(this.latest_sort);
      } else {
        this.sortData({
          direction: 'asc',
        });
      }

      this.snackBarService.displaySuccess('Ο χρήστης διεγράφη επιτυχώς.');
    } catch (error: any) {
      this.snackBarService.displayError(error?.message);
    }
  }

  getLink(user: User) {
    return `/user/${user.id}`;
  }

  openDeleteDialog(user: User): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteuser(user);
      }
    });
  }

  edit(user: User) {
    const dialogRef = this.dialog.open(UserFormComponent, {
      width: '70vw',
      data: {
        update: true,
        user: user,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.reload();
      }
    });
  }

  addUser() {
    const dialogRef = this.dialog.open(UserFormComponent, {
      width: '70vw',
      data: {
        update: false,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.reload();
      }
    });
  }

  getRoleName(user: User) {
    return user.role ? 'Διαχειριστής' : 'Χρήστης';
  }

  onSearch() {
    if (this.search) {
 
      this.available_users = this.users.filter((element) => {
        return element.name.toLowerCase().includes(this.search.toLowerCase()) ||  element.surname.toLowerCase().includes(this.search.toLowerCase());
      });

      this.dataSource.data = this.available_users
    } else {
      this.dataSource.data = this.users
    }
  }
}
