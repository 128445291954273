<div class="mat-elevation-z8 main">
  <mat-tab-group
    mat-align-tabs="start"
    [(selectedIndex)]="tabIndex"
    dynamicHeight
    mat-stretch-tabs
    [disablePagination]="true"
  >
    <mat-tab label="Ενημερωτικοί Οδηγοί" disabled>
      <ng-container *ngIf="!dataloaded">
        <div class="spinner-div">
          <mat-spinner [diameter]="100"></mat-spinner>
        </div>
      </ng-container>

      <div [ngClass]="{'hide': !dataloaded}">

        <div>
          <button mat-raised-button class="custom-btn add-btn" (click)="addGuide()">
            Προσθήκη Νέου Οδηγού
          </button>
        </div>
        <mat-form-field style="width:20%; margin-left: 20px;">
          <mat-label>Αναζήτηση</mat-label>
          <input matInput (keyup)="onSearch()"  [(ngModel)]="search">
        </mat-form-field>
        <table
          mat-table
          matSort
          [dataSource]="dataSource"
          class="table"
        >
          <ng-container matColumnDef="order">
            <th mat-header-cell *matHeaderCellDef style="width: 2%; border: none;">#</th>
            <td mat-cell *matCellDef="let element; let i = index" style="width: 2%; border: none;">
              {{ i + 1 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="title">
            <th mat-header-cell mat-sort-header *matHeaderCellDef style="width: 45%; border: none;"
              >Τίτλος</th
            >
            <td mat-cell *matCellDef="let element" style="width: 45%; border: none;">{{ element.title }}</td>
          </ng-container>

          <ng-container matColumnDef="quiz">
            <th mat-header-cell *matHeaderCellDef style="width: 35%; border: none;"
              >Quiz</th
            >
            <td mat-cell *matCellDef="let element" style="width: 35%; border: none;">
              <mat-select
                class="select"
                [disabled]="!quizLoaded"
                (valueChange)="updateQuiz($event, element)"
                *ngIf="element.quiz !== undefined"
                [(ngModel)]="element.quiz.id"
              >
                <mat-option
                  *ngIf="available_quiz.length <= 0"
                  [value]="0"
                  [disabled]="true"
                >
                  Δεν βρέθηκε κάποιο quiz
                </mat-option>

                <mat-option
                  *ngFor="let quiz of available_quiz"
                  [value]="quiz.id"
                  [disabled]="!quiz.available"
                >
                  {{ quiz.title }}
                </mat-option>
              </mat-select>

              <mat-select
                class="select"
                [disabled]="!quizLoaded"
                (valueChange)="updateQuiz($event, element)"
                *ngIf="element.quiz === undefined"
              >
                <mat-option
                  *ngIf="available_quiz.length <= 0"
                  [value]="0"
                  [disabled]="true"
                >
                  Δεν βρέθηκε κάποιο quiz
                </mat-option>

                <mat-option
                  *ngFor="let quiz of available_quiz"
                  [value]="quiz.id"
                  [disabled]="!quiz.available"
                >
                  {{ quiz.title }}
                </mat-option>
              </mat-select>
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> Ενέργειες </mat-header-cell>
            <mat-cell *matCellDef="let element">
              <mat-icon matTooltip="Δείτε τον οδηγό" class="pointer primary-color" [routerLink]="getLink(element)"
                >visibility</mat-icon
              >

              <mat-icon matTooltip="Επεξεργασία" class="pointer primary-color" (click)="edit(element)">edit</mat-icon>
              <mat-icon *ngIf="element.quiz" matTooltip="Αφαίρεση Quiz" class="pointer close-color" (click)="removeQuiz(element)">close</mat-icon>
              <mat-icon matTooltip="Διαγραφή" class="pointer close-color" (click)="openDeleteDialog(element)"
                >delete</mat-icon
              >
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </table>
        <mat-paginator
          [length]="length"
          [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
      </div>

    </mat-tab>
  </mat-tab-group>
</div>
