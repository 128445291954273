import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { CommonService } from 'src/app/services/common.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Quiz } from 'src/app/interfaces/quiz.interface';
import { Question } from 'src/app/interfaces/question.interface';
@Component({
  selector: 'app-quiz-form',
  templateUrl: './quiz-form.component.html',
  styleUrls: ['./quiz-form.component.scss'],
})
export class QuizFormComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    private commonService: CommonService,
    private dialogRef: MatDialogRef<QuizFormComponent>
  ) {}

  @Output('created') reload: EventEmitter<any> = new EventEmitter();

  available_questions: Array<Question> = [];

  form = this.formBuilder.group({
    title: ['', [Validators.required]],
    questions: ['', [Validators.required]],
  });

  submited: boolean = false;
  update: boolean = false;

  async ngOnInit(): Promise<void> {
    await this.initQuestions();

    if (this.data.update !== undefined) {
      this.update = this.data.update;
    }
    if (this.data.quiz) {
      let question_ids: Array<number> = [];

      if (
        this.data.quiz.questions !== undefined &&
        this.data.quiz.questions.length > 0
      ) {
        this.data.quiz.questions.forEach((question: Question) => {
          question_ids.push(question.id);
        });
      }

      this.form = this.formBuilder.group({
        title: [this.data.quiz.title, [Validators.required]],
        questions: [question_ids, [Validators.required]],
      });
    }
  }

  async initQuestions(): Promise<void> {
    try {
      const response = await this.commonService.getRequest('questions/');

      this.available_questions = response.results;
    } catch (error) {
      console.log(error);
    }
  }

  clearForm() {
    this.form.reset();
    this.form.controls['title'].setErrors(null);
    this.form.controls['questions'].setErrors(null);
  }

  submit() {
    this.submited = true;
    if (!this.form.valid) {
      this.snackBarService.displayError('Συμπληρώστε όλα τα πεδία.');
    } else {
      if (this.update) {
        this.postUpdateForm();
      } else {
        this.postForm();
      }
    }
    this.submited = false;
  }

  formatData() {
    var quiz;

    if (this.data.quiz) {
      quiz = {
        id: this.data.quiz.id,
        title: this.form.get('title')?.value,
        questions: this.form.get('questions')?.value,
      };
    } else {
      quiz = {
        title: this.form.get('title')?.value,
        questions: this.form.get('questions')?.value,
      };
    }

    return quiz;
  }

  async postForm(): Promise<void> {
    try {
      const formData = this.formatData();

      const body = { quiz: formData };

      let response;

      if (this.data.quiz) {
        response = await this.commonService.putRequest(
          `quiz/update/${this.data.quiz.id}`,
          body
        );
      } else {
        response = await this.commonService.postRequest('quiz/create', body);
      }

      this.snackBarService.displaySuccess('Το quiz αποθηκεύτηκε επιτυχώς.');

      this.dialogRef.close({ quiz: body });
    } catch (error: any) {
      this.snackBarService.displayError(error?.error?.message);
    }
  }

  async postUpdateForm(): Promise<void> {
    try {
      const formData = this.formatData();

      const body = { guide: formData };

      const response = await this.commonService.putRequest(
        `quiz/update/${this.data.quiz.id}`,
        body
      );

      this.snackBarService.displaySuccess('Το quiz αποθηκεύτηκε επιτυχώς.');

      this.clearForm();
      this.dialogRef.close({ result: true });
    } catch (error: any) {
      this.snackBarService.displayError(error?.error?.message);
    }
  }

  successCreate(event: any) {
    this.dialogRef.close({ quiz: event });
  }

  close() {
    this.dialogRef.close();
  }
}
