<button mat-raised-button class="add-btn" (click)="create()">
  Προσθήκη νέας υποκατηγορίας
</button>
<table
  mat-table
  matSort
  (matSortChange)="sortData($event)"
  [dataSource]="available_categories"
  class="table"
>
  <ng-container matColumnDef="order">
    <th mat-header-cell *matHeaderCellDef>#</th>
    <td mat-cell *matCellDef="let element; let i = index">
      {{ i + 1 }}
    </td>
  </ng-container>

  <ng-container matColumnDef="name">
    <mat-header-cell mat-sort-header *matHeaderCellDef>Όνομα</mat-header-cell>
    <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="category">
    <mat-header-cell mat-sort-header *matHeaderCellDef
      >Κατηγορία</mat-header-cell
    >
    <mat-cell *matCellDef="let element">{{ element.category.name }}</mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef> Ενέργειες </mat-header-cell>
    <mat-cell *matCellDef="let element" align="right">
      <!-- <mat-icon class="pointer" (click)="edit(element)">edit</mat-icon> -->
      <mat-icon class="pointer" (click)="openDeleteDialog(element)"
        >delete</mat-icon
      >
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</table>

<mat-paginator
  [length]="length"
  [pageSize]="pageSize"
  [pageSizeOptions]="pageSizeOptions"
  (page)="pagerChange($event)"
  aria-label="Select page"
>
</mat-paginator>
