<div class="content">
  <ng-container mat-dialog-title>
    <h1 *ngIf="update === undefined || !update" class="modal-title">
      <span>Δημιουργία Ενημερωτικού Οδηγού</span>
      <mat-icon style="cursor: pointer;" (click)="close()">close</mat-icon>
    </h1>
    <h1 *ngIf="update !== undefined && update" class="modal-title">
      <span>Επεξεργασία Ενημερωτικού Οδηγού</span>
      <mat-icon style="cursor: pointer;" (click)="close()">close</mat-icon>
    </h1>
  </ng-container>

  <form [formGroup]="form">
    <mat-grid-list cols="12" rowHeight="50px">
      <mat-grid-tile colspan="12">
        <div style="width: 100%" class="align-left">
          <mat-form-field style="width: 100%" appearance="standard">
            <mat-label>Τιτλος</mat-label>
            <input
              matInput
              placeholder="Τίτλος"
              formControlName="title"
              required
            />
          </mat-form-field>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
    
        <div style="width: 100%; margin-top:50px"  class="textarea-div">
          <quill-editor  [styles]="{height: '250px'}" placeholder="Περιεχόμενο" formControlName="context"></quill-editor>
        </div>
      
    <div mat-dialog-actions class="actions" align="end">
      <mat-dialog-actions>
        <button class="close-btn" mat-raised-button [mat-dialog-close]="false">Ακύρωση</button>
        <button
          class="custom-btn"
          [disabled]="submited || form.invalid"
          (click)="submit()"
          mat-raised-button
        >
          Επιβεβαίωση
        </button>
      </mat-dialog-actions>
    </div>
  </form>
</div>

<!-- <div *ngIf="update">
  <h1 mat-dialog-title *ngIf="update">Επεξεργασία οδηγού</h1>

  <div>
    <form [formGroup]="form">
      <div class="title-div">
        <mat-form-field appearance="standard" class="title">
          <mat-label>Τιτλος</mat-label>
          <input
            matInput
            placeholder="Τίτλος"
            formControlName="title"
            required
          />
          <mat-error *ngIf="form.get('title')?.invalid"
            >Παρακαλώ συμπληρώστε τίτλο.</mat-error
          >
        </mat-form-field>
      </div>

      <div class="textarea-div">
        <div class="textarea-div">
          <quill-editor matInput formControlName="context"></quill-editor>
        </div>
      </div>

      <div mat-dialog-actions class="actions">
        <mat-dialog-actions align="end">
          <button mat-raised-button [mat-dialog-close]="false">Ακύρωση</button>
          <button
            class="btn"
            [disabled]="form.invalid"
            (click)="submit()"
            mat-raised-button
          >
            Επιβεβαίωση
          </button>
        </mat-dialog-actions>
      </div>
    </form>
  </div>
</div> -->
