<div class="content">
  <ng-container mat-dialog-title>
    <h1 *ngIf="update === undefined || !update" class="modal-title">
      <span>Δημιουργία Quiz</span>
      <mat-icon style="cursor: pointer;" (click)="close()">close</mat-icon>
    </h1>
    <h1 *ngIf="update === undefined || update" class="modal-title">
      <span>Επεξεργασία Quiz</span>
      <mat-icon style="cursor: pointer;" (click)="close()">close</mat-icon>
    </h1>
  </ng-container>

  <div class="stepper" mat-dialog-content>
    <app-quiz-stepper
      [quiz]="data.quiz"
      (created)="successCreate($event)"
    ></app-quiz-stepper>
  </div>

  <!-- <form [formGroup]="form">
    <mat-grid-list class="form" cols="12" rowHeight="70px">
      <mat-grid-tile colspan="12">
        <div class="align-left full-width">
          <mat-form-field class="full-width" appearance="standard">
            <mat-label>Τίτλος</mat-label>
            <input
              matInput
              placeholder="Τίτλος"
              formControlName="title"
              required
            />
            <mat-error *ngIf="form.get('title')?.invalid"
              >Παρακαλώ συμπληρώστε τίτλο.</mat-error
            >
          </mat-form-field>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list class="form" cols="12" rowHeight="70px">
      <mat-grid-tile colspan="12">
        <div class="align-left full-width">
          <mat-form-field class="full-width" appearance="standard">
            <mat-label>Ερωτήσεις</mat-label>
            <mat-select formControlName="questions" multiple required>
              <mat-option
                *ngFor="let question of available_questions"
                [value]="question.id"
                >{{ question.question }}</mat-option
              >
            </mat-select>
            <mat-error *ngIf="form.get('title')?.invalid"
              >Παρακαλώ επιλέξτε τουλάχτιστον μία ερώτηση.</mat-error
            >
          </mat-form-field>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <div mat-dialog-actions class="actions">
      <mat-dialog-actions align="end">
        <button mat-raised-button [mat-dialog-close]="false">Ακύρωση</button>

        <button
          [disabled]="submited"
          class="add-btn"
          (click)="submit()"
          mat-raised-button
        >
          Επιβεβαίωση
        </button>
      </mat-dialog-actions>
    </div>
  </form> -->
</div>
