<div class="mat-elevation-z8 main">
  
  <mat-tab-group
    mat-align-tabs="start"
    [(selectedIndex)]="tabIndex"
    dynamicHeight
    mat-stretch-tabs
    [disablePagination]="true"
  >
    <mat-tab label="Quiz" disabled>


      <ng-container *ngIf="!dataloaded">
        <div class="spinner-div">
          <mat-spinner [diameter]="100"></mat-spinner>
        </div>
      </ng-container>

      <div [ngClass]="{'hide': !dataloaded}">

        <div>
          <button mat-raised-button class="custom-btn add-btn" (click)="addQuiz()">
            Προσθήκη Νέου Quiz
          </button>
        </div>
        <mat-form-field style="width:20%; margin-left: 20px;">
          <mat-label>Αναζήτηση</mat-label>
          <input matInput (keyup)="onSearch()"  [(ngModel)]="search">
        </mat-form-field>
        <table
          mat-table
          matSort
          [dataSource]="dataSource"
          class="table"
        >
          <ng-container matColumnDef="order">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let element; let i = index">
              {{ i + 1 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="title">
            <mat-header-cell mat-sort-header *matHeaderCellDef
              >Τίτλος</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{ element.title }}</mat-cell>
          </ng-container>
  <!-- 
          <ng-container matColumnDef="questions">
            <mat-header-cell mat-sort-header *matHeaderCellDef
              >Ερωτήσεις</mat-header-cell
            >
            <mat-cell *matCellDef="let element">
              <span [innerHtml]="getQuestions(element)"></span>
            </mat-cell>
          </ng-container> -->

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> Ενέργειες </mat-header-cell>
            <mat-cell *matCellDef="let element" align="right">
              <mat-icon matTooltip="Επεξεργασία" class="pointer primary-color" (click)="edit(element)">edit</mat-icon>

              <mat-icon matTooltip="Διαγραφή" class="pointer close-color" (click)="openDeleteDialog(element)"
                >delete</mat-icon
              >
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </table>
        <mat-paginator
          [length]="length"
          [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
      </div>


    </mat-tab>
  </mat-tab-group>
</div>
