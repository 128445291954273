import { SnackBarService } from 'src/app/services/snack-bar.service';
import { CommonService } from './../../services/common.service';
import { Suggestion } from 'src/app/interfaces/suggestion.interface';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';

import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

declare var google: any;
@Component({
  selector: 'app-suggestion',
  templateUrl: './suggestion.component.html',
  styleUrls: ['./suggestion.component.scss'],
})
export class SuggestionComponent implements OnInit {
  suggestion: Suggestion;
  map: any;

  constructor(
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private snackBarService: SnackBarService,
    private location: Location,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    let id = this.activatedRoute.snapshot.paramMap.get('id');

    this.initSuggestion(id);
  }

  async initSuggestion(id: any): Promise<void> {
    try {
      const response = await this.commonService.getRequest(`suggestions/${id}`);

      this.suggestion = response.suggestion;
    } catch (error) {
      console.log(error);
    }
  }

  getDate(suggestion: Suggestion): string {
    let date = new Date(suggestion.added_at);

    var month = date.getUTCMonth() + 1;
    var day = date.getUTCDate();
    var year = date.getUTCFullYear();

    return year + '-' + month + '-' + day;
  }

  async openDeleteDialog() {
    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteSuggestion();
      }
    });
  }

  async deleteSuggestion() {
    try {
      const results = await this.commonService.deleteRequest(
        `suggestions/${this.suggestion.id}`
      );

      this.snackBarService.displaySuccess('Η πρόταση διεγράφη επιτυχώς.');

      this.location.back();
    } catch (error: any) {
      this.snackBarService.displayError(error?.message);
    }
  }

  async approve() {
    try {
      const respone = await this.commonService.getRequest(
        `suggestions/approve/${this.suggestion.id}`
      );
      this.suggestion = respone.suggestion[0];
      this.snackBarService.displaySuccess('Η πρόταση εγκρίθηκε επιτυχώς.');
    } catch (error: any) {
      this.snackBarService.displaySuccess(error?.message);
    }
  }

  async disapprove() {
    try {
      const respone = await this.commonService.getRequest(
        `suggestions/disapprove/${this.suggestion.id}`
      );
      this.suggestion = respone.suggestion[0];
      this.snackBarService.displaySuccess('Η πρόταση ορίστηκε ως μη εγκεκριμένη.');
    } catch (error: any) {
      this.snackBarService.displaySuccess(error?.message);
    }
  }

  getApprovedName() {
    return this.suggestion.approved ? 'Εγκεκριμένη' : 'Μη Εγκεκριμένη';
  }

  onMapReady(map: any) {
    this.map = map;

    this.addMarker()
  }

  addMarker() {

    const location = new google.maps.LatLng(
      this.suggestion.lat,
      this.suggestion.lon,
    );

    let marker = new google.maps.Marker({
      position: location,
      title: this.suggestion.title,
      latitude: this.suggestion.lat,
      longitude: this.suggestion.lon,
      map: this.map,
    });

    this.addInfoToMarket(marker);

  }

  addInfoToMarket(marker: any) {
    let infoContent = '';
 
    infoContent = `<div id="info-div">
      <strong>${this.suggestion.name}</strong><br>
    </div>`;
 
    marker.setClickable(true);

    let infoWindow = new google.maps.InfoWindow({
      content: infoContent,
      minWidth: 180,
    });

    marker.addListener('click', (e: any) => {
      infoWindow.open(this.map, marker);
    });

    marker.setClickable(true);
  }

}
