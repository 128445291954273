import { Quiz } from './../../interfaces/quiz.interface';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { CommonService } from './../../services/common.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { GoodDeed } from 'src/app/interfaces/good-deed.interface';
import { GoodDeedFormComponent } from '../good-deed-form/good-deed-form.component';
import { UpdateDialogComponent } from '../update-dialog/update-dialog.component';
import { QuizFormComponent } from '../quiz-form/quiz-form.component';
import { Guide } from 'src/app/interfaces/guide.interface';

@Component({
  selector: 'app-good-deed',
  templateUrl: './good-deed.component.html',
  styleUrls: ['./good-deed.component.scss'],
})
export class GoodDeedComponent implements OnInit {
  good_deed: GoodDeed;
  available_guides: Array<Guide> = [];
  available_quiz: Array<Quiz> = [];
  good_deeds: Array<GoodDeed> = [];

  constructor(
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private snackBarService: SnackBarService,
    private location: Location,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    let id = this.activatedRoute.snapshot.paramMap.get('id');

    await this.initGoodDeeds();
    await this.initData(id);
    await this.initQuiz();
    await this.initGuides();
  }

  async reload(): Promise<void> {
    await this.initData(this.good_deed.id);

    await this.initQuiz();
    await this.initGuides();
  }

  async initGoodDeeds(): Promise<void> {
    try {
      const response = await this.commonService.getRequest('good-deeds/view');

      this.good_deeds = response.results;
    } catch (error) {
      console.log(error);
    }
  }

  async initData(id: any): Promise<void> {
    try {
      const response = await this.commonService.getRequest(`good-deeds/${id}`);
      this.good_deed = response.result.good_deed;
    } catch (error) {
      console.log(error);
    }
  }

  async initQuiz(): Promise<void> {
    try {
      const response = await this.commonService.getRequest('quiz');

      this.available_quiz = response.results;
    } catch (error) {
      console.log(error);
    }
  }

  async initGuides(): Promise<void> {
    try {
      const response = await this.commonService.getRequest('guides/view');

      this.available_guides = response.results;

      let ids: Array<number> = [];

      this.good_deeds.forEach((good_deed) => {
        if (good_deed.guide) {
          ids.push(good_deed.guide.id);
        }
      });

      this.available_guides.forEach((guide) => {
        if (ids.includes(guide.id)) {
          guide.available = false;
        } else {
          guide.available = true;
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  async openDeleteDialog() {
    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.delete();
      }
    });
  }

  async delete() {
    try {
      const results = await this.commonService.deleteRequest(
        `good-deeds/${this.good_deed.id}`
      );

      this.snackBarService.displaySuccess('Η καλή πράξη διεγράφη επιτυχώς.');

      this.location.back();
    } catch (error: any) {
      this.snackBarService.displayError(error?.message);
    }
  }

  async edit() {
    const dialogRef = this.dialog.open(UpdateDialogComponent, {
      height: '280px',
      width: '500px',
      data: {
        current: {
          name: this.good_deed.title,
          placeholer: 'Τίτλος',
          title: 'Επεξεργασία Τίτλου',
          type: 'textarea',
          rows: 3
        },
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.postUpdate(result);
      }
    });
  }

  async postUpdate(inputTitle: string): Promise<void> {
    try {
      const body = {
        good_deed: {
          title: inputTitle,
        },
      };
      const response = await this.commonService.putRequest(
        `good-deeds/update/${this.good_deed.id}`,
        body
      );

      this.good_deed.title = inputTitle;
    } catch (error) {
      console.log(error);
    }
  }

  async updateGuide(e: number): Promise<void> {
    try {
      const body = {
        guide_id: e,
      };

      const response = await this.commonService.putRequest(
        `good-deeds/add-guide/${this.good_deed.id}`,
        body
      );

      await this.reload();

      await this.initGuides();
    } catch (error) {}
  }

  getGuideLink(guide: Guide) {
    return `/guide/${guide.id}`;
  }

  async updateQuiz(e: number): Promise<void> {
    try {
      const body = {
        quiz_id: e,
      };
 
      const response = await this.commonService.putRequest(
        `guides/add-quiz/${this.good_deed.guide?.id}`,
        body
      );
      this.initQuiz();
    } catch (error) {}
  }
}
