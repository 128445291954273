import { CommonService } from './../../services/common.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-new-admin-form',
  templateUrl: './new-admin-form.component.html',
  styleUrls: ['./new-admin-form.component.scss']
})
export class NewAdminFormComponent implements OnInit {


  form = this.formBuilder.group({
    name: ['', [Validators.required]],
    surname: ['', [Validators.required]],
    email: ['', [Validators.compose([Validators.required, Validators.email])]],
    phone_number: ['', [Validators.required]],
    mobile_number: ['', [Validators.required]],
    password: [
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9]+.{7,}$'),
      ]),
    ],
    address: ['', [Validators.required]],
    city: ['', [Validators.required]],
    zip_code: ['', [Validators.compose([Validators.required,Validators.pattern("^[0-9]*$")])]],
  });

  hide = true

  constructor(private commonService: CommonService, 
            public formBuilder: FormBuilder, 
            private snackBarService: SnackBarService,
            public dialogRef: MatDialogRef<NewAdminFormComponent>) { }

  ngOnInit(): void {
    
  }

  async submit() {

    try {
      const formData = this.formatData();

      const body = { user: formData };

      let response = await this.commonService.postRequest(
        'users/create-admin',
        body
      );
  
      this.snackBarService.displaySuccess(
        'Ο διαχειριστής αποθηκεύτηκε επιτυχώς.'
      );

      this.dialogRef.close();

    } catch (error: any) {
      this.snackBarService.displayError(
        error.error.message
      );
    }
  }

  formatData() {
    const user = {
      name: this.form.get('name')?.value,
      surname: this.form.get('surname')?.value,
      email: this.form.get('email')?.value,
      phone_number: this.form.get('phone_number')?.value,
      mobile_number: this.form.get('mobile_number')?.value,
      password: this.form.get('password')?.value,
      city: this.form.get('city')?.value,
      address: this.form.get('address')?.value,
      zip_code: this.form.get('zip_code')?.value,
    };

    return user;
  }
}
