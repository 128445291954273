<div class="mat-elevation-z8 main">
  <mat-toolbar>
    <mat-grid-list cols="12" class="nav-div" *ngIf="user !== undefined">
      <mat-grid-tile colspan="9">
        <div class="align-left">{{ user.name }} {{ user.surname }}</div>
      </mat-grid-tile>

      <mat-grid-tile colspan="3">
        <div class="align-right">
          <button (click)="edit()" mat-icon-button>
            <mat-icon matTooltip="Επεξεργασία">edit</mat-icon>
          </button>
          <button (click)="openDeleteDialog()" mat-icon-button>
            <mat-icon matTooltip="Διαγραφή" class="close-color">delete</mat-icon>
          </button>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </mat-toolbar>
  <div class="context" *ngIf="user === undefined">
    <div class="spinner-div">
      <mat-spinner [diameter]="100"></mat-spinner>
    </div>
  </div>
  <div class="context" *ngIf="user !== undefined">
    <table style="width: 100%">
      <tr style="height: 30px;">
        <th style="text-align: left;">
          Όνομα:
        </th>
        <td>
          {{ user.name }}
        </td>

        <th style="text-align: left;">
          Διεύθυνση:
        </th>
        <td>
          {{ user.address }}
        </td>

        <th style="text-align: left;">
          Τηλέφωνο:
        </th>
        <td>
          {{ user.phone_number }}
        </td>
      </tr>
      <tr style="height: 30px;">
        <th style="text-align: left;">
          Επώνυμο:
        </th>
        <td>
          {{ user.surname }}
        </td>

        <th style="text-align: left;">
          Πόλη:
        </th>
        <td>
          {{ user.city }}
        </td>

        <th style="text-align: left;">
          Κινητό Τηλέφωνο:
        </th>
        <td>
          {{ user.mobile_number }}
        </td>
      </tr>
      <tr style="height: 30px;">
        <th style="text-align: left;">
          Email:
        </th>
        <td>
          {{ user.email }}
        </td>

        <th style="text-align: left;">
          Τ.Κ:
        </th>
        <td>
          {{ user.zip_code }}
        </td>

        <th style="text-align: left;">
          Πόντοι:
        </th>
        <td>
          {{ user.points }}
        </td>
      </tr>
      <tr style="height: 30px;">
        <th style="text-align: left;">
          Ρόλος:
        </th>
        <td>
          {{ getRoleName() }}
        </td>
      </tr>
    </table>
  </div>
</div>
