<mat-dialog-content class="mat-typography dialog">
  <h1 mat-dialog-title>Προσθήκη Νέας Κατηγορίας</h1>

  <mat-form-field class="field">
    <mat-label>Ονομα</mat-label>
    <input matInput [(ngModel)]="input" />
  </mat-form-field>

  <mat-form-field class="field">
    <mat-label>Κατηγορία</mat-label>
    <mat-select [(ngModel)]="category">
      <mat-option *ngFor="let category of categories" [value]="category.id">
        {{ category.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-dialog-content>

<div mat-dialog-actions>
  <mat-dialog-actions align="end">
    <button mat-raised-button [mat-dialog-close]="false">Ακύρωση</button>
    <button
      class="add-btn"
      mat-raised-button
      [mat-dialog-close]="{ input: input, category: category }"
    >
      Προσθήκη
    </button>
  </mat-dialog-actions>
</div>
