<div class="mat-elevation-z8 main">
  <mat-toolbar>
    <div fxLayout="row" class="nav-div" *ngIf="guide !== undefined" fxFlex="100" fxLayoutAlign="start center center">
      <div fxLayout="column" fxFlex="90">
        <div fxLayout="row wrap">
          <label style="white-space: break-spaces;">{{ guide.title }}</label>
        </div>
      </div>
      <div fxLayout="column" fxFlex="10">
        <div fxLayout="row">
          <button (click)="edit()" mat-icon-button>
            <mat-icon matTooltip="Επεξεργασία">edit</mat-icon>
          </button>
          <button (click)="openDeleteDialog()" mat-icon-button>
            <mat-icon matTooltip="Διαγραφή" class="close-color">delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
<!--    <mat-grid-list cols="12" class="nav-div" *ngIf="guide !== undefined">-->
<!--      <mat-grid-tile colspan="9">-->
<!--        <div class="align-left">-->
<!--          {{ guide.title }}-->
<!--        </div>-->
<!--      </mat-grid-tile>-->

<!--      <mat-grid-tile colspan="3">-->
<!--        <div class="align-right">-->
<!--          <button (click)="edit()" mat-icon-button>-->
<!--            <mat-icon matTooltip="Επεξεργασία">edit</mat-icon>-->
<!--          </button>-->
<!--          <button (click)="openDeleteDialog()" mat-icon-button>-->
<!--            <mat-icon matTooltip="Διαγραφή" class="close-color">delete</mat-icon>-->
<!--          </button>-->
<!--        </div>-->
<!--      </mat-grid-tile>-->
<!--    </mat-grid-list>-->
  </mat-toolbar>
  <div class="context" *ngIf="guide === undefined">
    <div class="spinner-div">
      <mat-spinner [diameter]="100"></mat-spinner>
    </div>
  </div>
  <div class="context" *ngIf="guide !== undefined">
    <quill-view [content]="guide.context"></quill-view>
  </div>
</div>
