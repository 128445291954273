<mat-drawer-container [hasBackdrop]="true" autosize>
  <mat-drawer #drawer mode='over'>

    <div class="menu-container">
      <div style="margin-bottom: 20px;">
        <img style="width: 100%; height: 64px;" src="../../../assets/main_logo.png">
      </div>
      <div
        class="route-item pointer"
        *ngFor="let route of routes"
        [routerLink]="route.path"
        routerLinkActive="active-route"
      >
        <span>{{ route.name }}</span>
      </div>
      <div class="logos-div">
        <div style="display: flex; justify-content: center; width: 100%">
          <img style="max-width: 40%; height: auto; padding-bottom: 10px;" src="../../../assets/logo_1.png">
        </div>
        <img style="max-width: 100%; height: auto; padding-bottom: 10px;" src="../../../assets/logo_2.jpg">
      </div>
    </div>
  </mat-drawer>

  <app-navbar (toggleDrawer)="drawer.toggle()"></app-navbar>

  <div class="main-content">
    <router-outlet></router-outlet>
  </div>
</mat-drawer-container>
