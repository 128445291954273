import { Quiz } from './../../interfaces/quiz.interface';
import { QuestionStepperFormComponent } from './../question-stepper-form/question-stepper-form.component';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {
  FormBuilder,
  Validators,
  FormGroup,
  FormControl,
} from '@angular/forms';
import { MatTabGroup } from '@angular/material/tabs';
import { MatStepper } from '@angular/material/stepper';
import { CommonService } from 'src/app/services/common.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { DataLayerManager } from '@agm/core';

@Component({
  selector: 'app-quiz-stepper',
  templateUrl: './quiz-stepper.component.html',
  styleUrls: ['./quiz-stepper.component.scss'],
})
export class QuizStepperComponent implements OnInit {
  @Input() quiz: Quiz;
  @Output('created') created: EventEmitter<any> = new EventEmitter();

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  submitFormGroup: FormGroup;

  tabs: Array<string> = [];
  selected = new FormControl(0);
  @ViewChildren('questionForm')
  questionForms: QueryList<QuestionStepperFormComponent>;

  questions: Array<any> = [];

  @ViewChild('stepper') private stepper: MatStepper;

  constructor(
    private formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    if (this.quiz !== undefined) {
      this.firstFormGroup = this.formBuilder.group({
        title: [this.quiz.title, Validators.required],
      });

      this.secondFormGroup = this.formBuilder.group({
        secondCtrl: ['', Validators.required],
      });

      this.quiz.questions.forEach((question, index) => {
        this.questions.push(question);
        this.tabs.push('Ερώτηση ' + (index + 1));
      });
    } else {
      this.tabs.push('Ερώτηση 1');
      this.firstFormGroup = this.formBuilder.group({
        title: ['', Validators.required],
      });

      this.secondFormGroup = this.formBuilder.group({
        secondCtrl: ['', Validators.required],
      });

      this.questions[0] = {
        question: '',
        answer_one: '',
        answer_two: '',
        answer_three: '',
        answer_four: '',
        correct: '',
      };
    }
  }

  addQuestion(selectAfterAdding: boolean) {
    this.tabs.push('Ερώτηση ' + (this.tabs.length + 1));
    this.questions.push({
      question: '',
      answer_one: '',
      answer_two: '',
      answer_three: '',
      answer_four: '',
      correct: '',
    });

    if (selectAfterAdding) {
      this.selected.setValue(this.tabs.length - 1);
    }
  }

  removeQuestion(index: number) {
    this.tabs.splice(index, 1);
    this.questions.splice(index, 1);

    this.selected.setValue(index - 1);
  }

  checkQuestions() {
    let check = true;

    this.questionForms.forEach((form, index) => {
      if (!form.checkValid()) {
        this.snackBarService.displayError('Παρακαλώ συμπληρώστε όλα τα πεδία.');
        check = false;
      } else {
        this.questions[index] = form.getData();
      }
    });
    if (check) {
      this.secondFormGroup.get('secondCtrl')?.setValue('true');
      this.stepper.next();
    }
  }

  async submit() {
    try {
      let body = {
        quiz: {
          title: this.firstFormGroup.get('title')?.value,
          questions: this.questions,
        },
      };

      let response;

      if (this.quiz !== undefined) {
        response = await this.commonService.putRequest(
          `quiz/update/${this.quiz.id}`,
          body
        );
      } else {
        response = await this.commonService.postRequest('quiz/create', body);
      }

      this.snackBarService.displaySuccess("Το quiz αποθηκεύτηκε επιτυχώς.")

      this.created.emit(response);
    } catch (error: any) {
      this.snackBarService.displaySuccess(error.error?.mmessage);
    }
  }

  validQuestions() {
    let check = true
    if (this.questionForms !== undefined) {
      this.questionForms.forEach((question: any) => {
        if (!question.checkValid()) {
          check = false
        }
      })
    } else {
      check = false
    }
    return check
  }

}
