import { MatDialog } from '@angular/material/dialog';
import { UserService } from './../../services/user.service';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

import { CommonService } from 'src/app/services/common.service';
import { SnackBarService } from './../../services/snack-bar.service';
import { NewAdminFormComponent } from '../new-admin-form/new-admin-form.component';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginForm = this.formBuilder.group({
    email: ['', [Validators.required]],
    password: ['', [Validators.required]],
  });

  loading: boolean = false;

  hide = true;
  
  @HostListener('document:keypress', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) { 
    if (event.key === "Enter") {
      if (this.loginForm.valid) {
        this.postLogin();
      } else {
        this.snackBarService.displayError(
          'Παρακαλώ συμπληρώστε τα παραπάνω πεδία.'
        );
      }
    }
  }

  constructor(
    public formBuilder: FormBuilder,
    private commonService: CommonService,
    private snackBarService: SnackBarService,
    private userService: UserService,
    private dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {

    let response = await this.commonService.getRequest('users/check-admin')
 
    if (!response.result) {
      const dialogRef = this.dialog.open(NewAdminFormComponent, {
        width: '50%',
        disableClose: true
      });
  
      dialogRef.afterClosed().subscribe((result) => {
        if (result !== undefined && result) {
        console.log(result)
        }
      });
    } else {
      let user = this.userService.getUser();

      if (user) {
        await this.userService.checkLoggedUser(true);
      }
    }

  }

  submitForm(): void {
    if (!this.loginForm.valid) {
      this.snackBarService.displayError(
        'Παρακαλώ συμπληρώστε τα παραπάνω πεδία.'
      );
    } else {
      this.postLogin();
    }
  }

  async postLogin(): Promise<void> {
    try {
      const body = { user: this.formatData() };

      this.loading = true;

      const data = await this.commonService.postRequest(
        `users/login-admin`,
        body
      );

      this.loading = false;

      if (data.token && data.user) {
        this.loginForm.reset();
        this.userService.login(data);
      } else if (data.message) {
        this.snackBarService.displayError(data.message);
      }
    } catch (e: any) {
      this.loading = false;

      this.snackBarService.displayError(e.error.message);
    }
  }

  formatData(): Object {
    return {
      email: this.loginForm.value.email,
      password: this.loginForm.value.password,
    };
  }
}
