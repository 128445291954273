import { CommonService } from './../../services/common.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Report } from 'src/app/interfaces/report.interface';

import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { OrderPipe } from 'ngx-order-pipe';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss'],
})
export class ReportsComponent implements OnInit {
  displayedColumns: string[] = ['order', 'name', 'sound', 'like', 'dislike','approved', 'actions'];

  reports: Array<Report> = [];
  available_reports: Array<Report> = [];

  dataSource = new MatTableDataSource<any>();

  length: any;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 100];

  pageEvent: PageEvent;

  latest_sort: any;
  dataloaded = false;

  data: any[] = []

  search = ""
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private commonService: CommonService,
    private orderPipe: OrderPipe,
    private snackBarService: SnackBarService,
    public dialog: MatDialog
  ) {}

  rowClick(row: any) {
    console.log('Row clicked: ', row);
  }

  ngOnInit(): void {
    this.initReports();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(',')
        .map((str) => +str);
    }
  }

  async initReports(): Promise<void> {
    try {
      const response = await this.commonService.getRequest('reports/view');

      this.reports = response.results;
 

      this.reports.forEach((element: any) => {
 
        this.data.push({
          id: element.report.id,
          name: element.report.name,
          sound: element.report.sound,
          like: element.like_number,
          dislike: element.dislike_number,
          approved: element.report.approved,
        })
      });
      this.dataSource.data = this.data

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataloaded = true

    } catch (error) {
      console.log(error);
    }
  }

  pagerChange(e: any): void {
    if (e.pageIndex === 0) {
      this.changePage(0, e.pageSize);
    } else {
      this.changePage(e.pageSize * e.pageIndex, e.pageSize);
    }
  }

  changePage(index: number, size: number) {
    this.available_reports = this.reports.slice(index, index + size);
  }

  sortData(e: any) {
    this.latest_sort = e;
    if (!e.direction) {
      this.available_reports = this.orderPipe.transform(
        this.available_reports,
        'id',
        true
      );
    } else {
      this.available_reports = this.orderPipe.transform(
        this.available_reports,
        e.active,
        e.direction === 'asc' ? false : true
      );
    }
  }

  async deleteReport(e: any) {

    try {
      const results = await this.commonService.deleteRequest(
        `reports/${e.id}`
      );
      let index = this.available_reports
        .map((item) => item.report.id)
        .indexOf(e.id);

      let reports_index = this.reports
        .map((item) => item.report.id)
        .indexOf(e.id);

      this.reports.splice(reports_index, 1);

      this.available_reports = this.reports;

      this.dataSource.data = this.reports

      if (this.latest_sort !== undefined) {
        this.sortData(this.latest_sort);
      } else {
        this.sortData({
          direction: 'asc',
        });
      }

      this.data = []
      this.available_reports.forEach((element: any) => {
        this.data.push({
          id: element.report.id,
          name: element.report.name,
          sound: element.report.sound,
          like: element.like_number,
          dislike: element.dislike_number,
          approved: element.report.approved,
        })
      });
 
      this.dataSource.data = this.data

      this.snackBarService.displaySuccess('Η αναφορά διεγράφη επιτυχώς.');
    } catch (error: any) {
      this.snackBarService.displayError(error?.message);
    }
  }

  getReportLink(report: any) {
    return `/report/${report.id}`;
  }

  openDeleteDialog(report: Report): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteReport(report);
      }
    });
  }
  
  onSearch() {
    if (this.search) {
      this.available_reports = this.reports.filter((element) => {
        return element.report.name.toLowerCase().includes(this.search.toLowerCase());
      });
 
      this.data = []
      this.available_reports.forEach((element: any) => {
        this.data.push({
          id: element.report.id,
          name: element.report.name,
          sound: element.report.sound,
          like: element.like_number,
          dislike: element.dislike_number,
          approved: element.report.approved,
        })
      });
 
      this.dataSource.data = this.data

    } else {
      this.data = []

      this.reports.forEach((element: any) => {
 
        this.data.push({
          id: element.report.id,
          name: element.report.name,
          sound: element.report.sound,
          like: element.like_number,
          dislike: element.dislike_number,
          approved: element.report.approved,
        })
      });

      this.dataSource.data = this.data
    }
  }
}
