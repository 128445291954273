import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Report } from 'src/app/interfaces/report.interface';
import { Suggestion } from 'src/app/interfaces/suggestion.interface';
import { CommonService } from 'src/app/services/common.service';

import * as Sound from 'src/app/constants/sound.constants';

declare var google: any;

@Component({
  selector: 'app-home-map',
  templateUrl: './home-map.component.html',
  styleUrls: ['./home-map.component.scss']
})

export class HomeMapComponent implements OnInit {

  map: any;
  average_pos = { lat: 40.653549, lng: 22.898692 };
  available_suggestions: Array<Suggestion> = [];
  available_reports: Array<Report> = [];
 
  infoWindows: Array<any> = [];
  markers: Array<any> = [];

  selected_map_option: number = 1;

  dataloaded = false;
  available_map_options = [
    {value: 1, name: "Δείτε όλες τις Καταγραφές"},
    {value: 2, name: "Δείτε τις Προτάσεις Βελτίωσης"},
    {value: 3, name: "Δείτε τις Αναφορές Προβλημάτων"}
  ]

  @ViewChild('map', { read: ElementRef, static: false }) mapRef: ElementRef;

  sound_circles:any = [];

  constructor(private commonService: CommonService, private router: Router) { }

  ngOnInit(): void {
    this.initData();
  }


  onMapReady(map: any) {
    this.map = map;
  }

  async initData(): Promise<void> {
    try {
      const response = await this.commonService.getRequest('suggestions/view');
      this.available_suggestions = response.results;
      const reportResponse = await this.commonService.getRequest('reports/view');
      this.available_reports = reportResponse.results;
      this.addMarkers()
    } catch (error) {
      console.log(error);
    }
  }

  async initSuggestionsData(): Promise<void> {
    try {
      const response = await this.commonService.getRequest('suggestions/view');
      this.available_suggestions = response.results;
      this.addMarkers('suggestions')
    } catch (error) {
      console.log(error);
    }
  }

  async initReportsData(): Promise<void> {
    try {
      const reportResponse = await this.commonService.getRequest('reports/view');
      this.available_reports = reportResponse.results;
      this.addMarkers('reports')
    } catch (error) {
      console.log(error);
    }
  }

  addSuggestionMarkers() {
    this.available_suggestions.map((el) => {
      const location = new google.maps.LatLng(el.lat, el.lon);
      var icon = { url: "../../assets/suggestion_flag.png", scaledSize: new google.maps.Size(40, 40) }
      let marker = new google.maps.Marker({
        position: location,
        title: el.name,
        id: "suggestion_"+el.id,
        latitude: el.lat,
        longitude: el.lon,
        icon: icon,
        map: this.map,
      });

      this.markers.push(marker);

      this.addInfoToMarket(marker, el);

    });
  }

  addReportMarkers() {
    this.available_reports.map((el) => {
      const location = new google.maps.LatLng(el.report.lat, el.report.lon);

      var icon = { url: "../../assets/report_flag.png", scaledSize: new google.maps.Size(30, 30) }
      let marker = new google.maps.Marker({
        position: location,
        title: el.report.name,
        id: "report"+el.report.id,
        latitude: el.report.lat,
        longitude: el.report.lon,
        icon: icon,
        map: this.map,
      });

     this.markers.push(marker);

     this.addReportInfoToMarket(marker, el);

    });
  }

  addMarkers(type: string | null = null) {
    this.markers.forEach(marker => marker.setMap(null))
    if (type === null) {
      if (this.available_suggestions !== undefined && this.available_suggestions.length > 0) {
        this.addSuggestionMarkers();
      }
  
      if (this.available_reports !== undefined && this.available_reports.length > 0) {
        this.addReportMarkers();
      }
    } else if (type === 'suggestions') {
 
      if (this.available_suggestions !== undefined && this.available_suggestions.length > 0) {
        this.addSuggestionMarkers();
      }
    } else if (type === 'reports') {
      if (this.available_reports !== undefined && this.available_reports.length > 0) {
        this.addReportMarkers();
      }
    }
    this.dataloaded = true
  }

  addReportInfoToMarket(marker: any, report: Report) {

    let infoContent = '';
 
    infoContent = `<div id="info-div">
      <strong>${report.report.name}</strong><br>
      <div class="info-text">
        Δείτε περισσότερα <span id="report_${report.report.id}" class="info-link"> εδώ </span>
      </div>
    </div>`;
  
    if (report.report.sound > 0) {

      let sound = this.getSoundText(report.report.sound)

      infoContent = `<div id="info-div">
        <strong>${report.report.name}</strong><br><span>${sound}</span>
        <div class="info-text">
        Δείτε περισσότερα <span id="report_${report.report.id}" class="info-link"> εδώ </span>
      </div>
      </div>`;
    } else {
      infoContent = `<div id="info-div">
        <strong>${report.report.name}</strong><br>
        <div class="info-text">
        Δείτε περισσότερα <span id="report_${report.report.id}" class="info-link"> εδώ </span>
      </div>
      </div>`;
    }
   

    marker.setClickable(true);

    let infoWindow = new google.maps.InfoWindow({
      content: infoContent,
      minWidth: 180,
    });

    marker.addListener('click', () => {
      this.closeAllInfo();
      infoWindow.open(this.map, marker);

      infoWindow.addListener('domready', () => {
        if (document.getElementById(`report_${report.report.id}`) !== null) {

          document.getElementById(`report_${report.report.id}`)?.addEventListener('click', () => {
              this.onReportMarkerClick(report);
            });
        }
      });
    });

    marker.setClickable(true);

    this.infoWindows.push({ id: marker.id, window: infoWindow });

    if (report.report.sound > 0) {
      this.drawCircle(
        this.getSoundColor(report.report.sound),
        this.getSoundColor(report.report.sound),
        report
      )
    }
  }

  addInfoToMarket(marker: any, suggestion: Suggestion, link = true) {
    let infoContent = '';
    if (link) {
      infoContent = `<div id="info-div">
        <strong>${suggestion.name}</strong><br>
        <div class="info-text">
          Δείτε περισσότερα <span id="suggestion_${suggestion.id}" class="info-link"> εδώ </span>
        </div>
      </div>`;
    } else {
      infoContent = `<div id="info-div">
        <strong>${suggestion.name}</strong><br>
      </div>`;
    }

    marker.setClickable(true);

    let infoWindow = new google.maps.InfoWindow({
      content: infoContent,
      minWidth: 180,
    });

    marker.addListener('click', (e: any) => {
      this.closeAllInfo();
      infoWindow.open(this.map, marker);
    });

    marker.setClickable(true);

    infoWindow.addListener('domready', () => {
      if (
        document.getElementById(`suggestion_${suggestion.id}`) !== null
      ) {
        document.getElementById(`suggestion_${suggestion.id}`)?.addEventListener('click', () => {
            this.onMarkerClick(suggestion);
          });
      }
    });
    
    this.infoWindows.push({ id: marker.id, window: infoWindow });
  }

  closeAllInfo() {
    this.infoWindows.forEach((window: any) => {
      window.window.close();
    });
  }

  onMarkerClick(suggestion: Suggestion) {
    this.closeAllInfo();
    this.router.navigateByUrl(`/suggestion/${suggestion.id}`);
  }

  onReportMarkerClick(report: Report) {
    this.router.navigateByUrl(`/report/${report.report.id}`);
  }

  getSoundText(sound: number) {
    if (sound >= Sound.HIGH) {
      return 'Δυνατή ένταση ήχου';
    } else if (sound >= Sound.MEDIUM && sound < Sound.HIGH) {
      return 'Μεσαία ένταση ήχου';
    } else if (sound <= Sound.MEDIUM) {
      return 'Χαμηλή ένταση ήχου';
    }
  }

  getSoundColor(sound: number): string {
    if (sound >= Sound.HIGH) {
      return 'red';
    } else if (sound > Sound.MEDIUM && sound < Sound.HIGH) {
      return 'yellow';
    } else if (sound < Sound.MEDIUM) {
      return 'green';
    }

    return ''
  }

  changeMapOption() {
    this.dataloaded = false
    switch (this.selected_map_option) {
      case 1:
        this.initData();
        break;
      
      case 2:
        this.clearSound();
        this.initSuggestionsData();
        break;

      case 3:
        this.initReportsData();
        break;
    
      default:
        break;
    }
  }

  drawCircle(strokeColor: string, fillColor: string, report: Report) {
    if (report) {
      const circle = new google.maps.Circle({
        strokeColor: strokeColor,
        strokeOpacity: 0.3,
        strokeWeight: 2,
        fillColor: fillColor,
        fillOpacity: 0.1,
        map: this.map,
        center: {
          lat: report.report.lat,
          lng: report.report.lon,
        },
        radius: report.report.sound / 0.6,
      });

      this.sound_circles.push(circle)
    }
  }

  clearSound() {
    this.sound_circles.forEach((el: any) => {
      el.setMap(null)
    });
  }
}
