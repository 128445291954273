import { CommonService } from 'src/app/services/common.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
  styleUrls: ['./privacy.component.scss']
})
export class PrivacyComponent implements OnInit {

  public privacy = "";

  constructor(private commonService: CommonService) { }

  async ngOnInit() {
    const respone = await this.commonService.getRequest('settings/terms')
    this.privacy = respone.terms.value;
    console.log(this.privacy)
  }

}
