<div style="width: 100%;" class="content">
    <mat-table
    [dataSource]="best_data"
    #table
  >
    <ng-container matColumnDef="name">
      <th style="border: none; width: 80%" mat-header-cell *matHeaderCellDef>Όνομα</th>
      <td mat-cell *matCellDef="let element" style="border: none; width: 80%"> <a style="text-decoration: none;" class="info-link" [routerLink]="'/suggestion/'+element.id">{{ element.name }}</a></td>
    </ng-container>

    <ng-container matColumnDef="like">
        <th style="border: none; width: 10%; text-align: center;" mat-header-cell *matHeaderCellDef>Likes</th>
        <td style="border: none; width: 10%; text-align: center;" mat-cell *matCellDef="let element"> {{ element.like_number }} </td>
    </ng-container>

    <ng-container matColumnDef="dislike">
        <th style="border: none; width: 10%; text-align: center;" mat-header-cell *matHeaderCellDef>Dislikes</th>
        <td mat-cell *matCellDef="let element" style="border: none; width: 10%; text-align: center;"> {{ element.dislike_number }} </td>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </mat-table>
</div>