<div class="content" style="overflow-y: auto;">
  <form [formGroup]="form">
    <mat-grid-list class="form" cols="12" rowHeight="70px">
      <mat-grid-tile colspan="12">
        <div style="width: 100%" class="align-left">
          <mat-form-field style="width: 100%" appearance="standard">
            <mat-label>Ερώτηση</mat-label>
            <input
              matInput
              placeholder="Ερώτηση"
              formControlName="question"
              required
            />
            <mat-error *ngIf="form.get('question')?.invalid"
              >Παρακαλώ συμπληρώστε ερώτηση.</mat-error
            >
          </mat-form-field>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list cols="12" rowHeight="70px">
      <mat-grid-tile colspan="12">
        <div style="width: 100%" class="align-left">
          <mat-form-field style="width: 100%" appearance="standard">
            <mat-label>Απάντηση 1</mat-label>
            <input
              matInput
              placeholder="Απάντηση 1"
              formControlName="answer_one"
              required
            />
            <mat-error *ngIf="form.get('answer_one')?.invalid"
              >Παρακαλώ συμπληρώστε απάντηση.</mat-error
            >
          </mat-form-field>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list cols="12" rowHeight="70px">
      <mat-grid-tile colspan="12">
        <div class="align-left" style="width: 100%">
          <mat-form-field style="width: 100%" appearance="standard">
            <mat-label>Απάντηση 2</mat-label>
            <input
              matInput
              placeholder="Απάντηση 2"
              formControlName="answer_two"
              required
            />
            <mat-error *ngIf="form.get('answer_two')?.invalid"
              >Παρακαλώ συμπληρώστε απάντηση.</mat-error
            >
          </mat-form-field>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list cols="12" rowHeight="70px">
      <mat-grid-tile colspan="12">
        <div class="align-left" style="width: 100%">
          <mat-form-field style="width: 100%" appearance="standard">
            <mat-label>Απάντηση 3</mat-label>
            <input
              matInput
              placeholder="Απάντηση 3"
              formControlName="answer_three"
              required
            />
            <mat-error *ngIf="form.get('answer_three')?.invalid"
              >Παρακαλώ συμπληρώστε απάντηση.</mat-error
            >
          </mat-form-field>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list cols="12" rowHeight="70px">
      <mat-grid-tile colspan="12">
        <div class="align-left" style="width: 100%">
          <mat-form-field style="width: 100%" appearance="standard">
            <mat-label>Απάντηση 4</mat-label>
            <input
              matInput
              placeholder="Απάντηση 4"
              formControlName="answer_four"
              required
            />
            <mat-error *ngIf="form.get('answer_four')?.invalid"
              >Παρακαλώ συμπληρώστε απάντηση.</mat-error
            >
          </mat-form-field>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list cols="12" rowHeight="70px">
      <mat-grid-tile colspan="12">
        <div class="align-left" style="width: 100%">
          <mat-form-field style="width: 100%" appearance="standard">
            <mat-label>Σωστή απάντηση</mat-label>
            <mat-select formControlName="correct" required>
              <mat-option *ngFor="let index of [1, 2, 3, 4]" [value]="index">
                {{ index }}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="form.get('correct')?.invalid"
              >Παρακαλώ συμπληρώστε τη σωστή απάντηση.</mat-error
            >
          </mat-form-field>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </form>
</div>
