import { Question } from './../../interfaces/question.interface';
import { QuizFormComponent } from './../quiz-form/quiz-form.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent, MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { OrderPipe } from 'ngx-order-pipe';
import { Guide } from 'src/app/interfaces/guide.interface';
import { Quiz } from 'src/app/interfaces/quiz.interface';
import { CommonService } from 'src/app/services/common.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-all-quiz',
  templateUrl: './all-quiz.component.html',
  styleUrls: ['./all-quiz.component.scss'],
})
export class AllQuizComponent implements OnInit {
  displayedColumns: string[] = ['order', 'title', 'actions'];
  quiz: Array<Quiz> = [];
  available_quiz: Array<Quiz> = [];
  available_questions: Array<Question> = [];

  dataSource = new MatTableDataSource<Quiz>(this.quiz);

  length: any;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 100];

  pageEvent: PageEvent;

  latest_sort: any;

  tabIndex: number = 0;
  dataloaded = false;
  search = ""
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private commonService: CommonService,
    private orderPipe: OrderPipe,
    private snackBarService: SnackBarService,
    public dialog: MatDialog
  ) {}

  async ngOnInit(): Promise<void> {
    await this.initQuiz();
    await this.initQuestions();
  }

  async reload(): Promise<void> {
    await this.initQuiz();
    await this.initQuestions();

    this.tabIndex = 0;
  }

  async initQuestions(): Promise<void> {
    try {
      const response = await this.commonService.getRequest('questions/');
      this.available_questions = response.results;
    } catch (error) {
      console.log(error);
    }
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(',')
        .map((str) => +str);
    }
  }

  async initQuiz(): Promise<void> {
    try {
      const response = await this.commonService.getRequest('quiz/');

      this.quiz = response.results;

      this.quiz.forEach((quiz: Quiz) => {
        quiz.questions.forEach((question: Question) => {
          question.value = question.id;
        });
      });

      this.available_quiz = this.quiz 

      this.dataSource.data = this.available_quiz;

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataloaded = true

    } catch (error) {
      console.log(error);
    }
  }

  pagerChange(e: any): void {
    if (e.pageIndex === 0) {
      this.changePage(0, e.pageSize);
    } else {
      this.changePage(e.pageSize * e.pageIndex, e.pageSize);
    }
  }

  changePage(index: number, size: number) {
    this.available_quiz = this.quiz.slice(index, index + size);
  }

  sortData(e: any) {
    this.latest_sort = e;
    if (!e.direction) {
      this.available_quiz = this.orderPipe.transform(
        this.available_quiz,
        'id',
        true
      );
    } else {
      this.available_quiz = this.orderPipe.transform(
        this.available_quiz,
        e.active,
        e.direction === 'asc' ? false : true
      );
    }
  }

  async deleteQuiz(e: any) {
    try {
      const results = await this.commonService.deleteRequest(`quiz/${e.id}`);

      let guides_index = this.quiz.map((item) => item.id).indexOf(e.id);

      this.quiz.splice(guides_index, 1);

      this.available_quiz = this.quiz;

      this.dataSource.data = this.available_quiz;

      if (this.latest_sort !== undefined) {
        this.sortData(this.latest_sort);
      } else {
        this.sortData({
          direction: 'asc',
        });
      }

      this.snackBarService.displaySuccess('Το quiz διεγράφη επιτυχώς.');
    } catch (error: any) {
      this.snackBarService.displayError(error?.message);
    }
  }

  getLink(quiz: Quiz) {
    return `/quiz/${quiz.id}`;
  }

  openDeleteDialog(guide: Guide): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteQuiz(guide);
      }
    });
  }

  edit(quiz: Quiz) {
    const dialogRef = this.dialog.open(QuizFormComponent, {
      data: {
        quiz: quiz,
        update: true,
      },
      width: '80vw',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.initQuiz();
      }
    });
  }

  addQuiz() {
    const dialogRef = this.dialog.open(QuizFormComponent, {
      width: '80vw',
      data: {},
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.initQuiz();
      }
    });
  }

  compareQuestions(q1: Question, q2: Question) {
    return q1.id === q2.id;
  }

  async updateQuestions(panelOpen: any, element: Quiz) {
    if (!panelOpen) {
      try {
        const body = { quiz: element };
        const response = await this.commonService.putRequest(
          `quiz/update/${element.id}`,
          body
        );

        this.snackBarService.displaySuccess(
          'Οι ερωτήσεις αποθηκεύτηκαν επιτυχώς'
        );

        this.reload();
      } catch (error: any) {
        this.snackBarService.displayError(error.error?.message);
      }
    }
  }

  getQuestions(quiz: Quiz) {
    let text = '';

    text += quiz.questions.map((question, index) => {
      return question.question + '<br>';
    });

    return text;
  }

  onSearch() {
    if (this.search) {
      this.available_quiz = this.quiz.filter((element) => {
        return element.title.toLowerCase().includes(this.search.toLowerCase());
      });
      this.dataSource.data = this.available_quiz
    } else {
      this.dataSource.data = this.quiz
    }
  }
}
