import { SnackBarService } from 'src/app/services/snack-bar.service';
import { CommonService } from './../../services/common.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

import { User } from 'src/app/interfaces/user.interface';
import { UserFormComponent } from '../user-form/user-form.component';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  user: User;
  
  constructor(
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private snackBarService: SnackBarService,
    private location: Location,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    let id = this.activatedRoute.snapshot.paramMap.get('id');

    this.initData(id);
  }

  async initData(id: any): Promise<void> {
    try {
      const response = await this.commonService.getRequest(`users/${id}`);

      this.user = response.user;
    } catch (error) {
      console.log(error);
    }
  }

  async openDeleteDialog() {
    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.delete();
      }
    });
  }

  async delete() {
    try {
      const results = await this.commonService.deleteRequest(
        `users/${this.user.id}`
      );

      this.snackBarService.displaySuccess('Ο χρήστης διεγράφη επιτυχώς.');

      this.location.back();
    } catch (error: any) {
      this.snackBarService.displayError(error?.message);
    }
  }

  getRoleName() {
    if (this.user.role !== undefined && this.user.role) {
      return 'Διαχηρηστής';
    }

    return 'Χρήστης';
  }

  async edit() {
    const dialogRef = this.dialog.open(UserFormComponent, {
      width: '70vw',
      data: {
        update: true,
        user: this.user,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.initData(this.user.id);
      }
    });
  }
}
