import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Guide } from 'src/app/interfaces/guide.interface';
import { CommonService } from 'src/app/services/common.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';

@Component({
  selector: 'app-guide-form',
  templateUrl: './guide-form.component.html',
  styleUrls: ['./guide-form.component.scss'],
})
export class GuideFormComponent implements OnInit {
  @Output('created') reload: EventEmitter<any> = new EventEmitter();

  editorStyle = {};
  form = this.formBuilder.group({
    title: ['', [Validators.required]],
    context: ['', [Validators.required]],
  });

  submited: boolean = false;
  guide: Guide;
  update: boolean = false;

  @Input() isUpdate: boolean;

  constructor(
    public formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<GuideFormComponent>
  ) {}

  ngOnInit(): void {
    this.guide = this.data.guide;

    if (this.data.update !== undefined) {
      this.update = this.data.update;
    } else {
      this.update = false;
    }

    if (this.guide) {
      this.form = this.formBuilder.group({
        title: [this.guide.title, [Validators.required]],
        context: [this.guide.context, [Validators.required]],
      });
    }
  }

  submit(): void {
    this.submited = true;
    if (!this.form.valid) {
      this.snackBarService.displayError('Συμπληρώστε όλα τα πεδία.');
    } else {
      if (this.update) {
        this.postUpdateForm();
      } else {
        this.postForm();
      }
    }
    this.submited = false;
  }

  formatData() {
    const guide = {
      title: this.form.get('title')?.value,
      context: this.form.get('context')?.value,
    };

    return guide;
  }

  async postForm(): Promise<void> {
    try {
      const formData = this.formatData();

      const body = { guide: formData };

      const response = await this.commonService.postRequest(
        'guides/create',
        body
      );

      this.snackBarService.displaySuccess('Ο οδηγός αποθηκεύτηκε επιτυχώς.');
      this.dialogRef.close({ result: true });
      this.clearForm();
    } catch (error: any) {
      this.snackBarService.displayError(error?.error?.message);
    }
  }

  async postUpdateForm(): Promise<void> {
    try {
      const formData = this.formatData();

      const body = { guide: formData };

      const response = await this.commonService.putRequest(
        `guides/update/${this.guide.id}`,
        body
      );

      this.snackBarService.displaySuccess('Ο οδηγός αποθηκεύτηκε επιτυχώς.');

      this.clearForm();
      this.dialogRef.close({ result: true });
    } catch (error: any) {
      this.snackBarService.displayError(error?.error?.message);
    }
  }

  clearForm() {
    this.form.reset();
    this.form.controls['context'].setErrors(null);
    this.form.controls['title'].setErrors(null);
  }

  close() {
    this.dialogRef.close();
  }
}
