import { Quiz } from './../../interfaces/quiz.interface';
import { CommonService } from './../../services/common.service';
import { Component, OnInit, ViewChild } from '@angular/core';

import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { OrderPipe } from 'ngx-order-pipe';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

import { GoodDeed } from 'src/app/interfaces/good-deed.interface';
import { MatTabGroup } from '@angular/material/tabs';
import { GoodDeedFormComponent } from '../good-deed-form/good-deed-form.component';
import { Guide } from 'src/app/interfaces/guide.interface';

@Component({
  selector: 'app-good-deeds',
  templateUrl: './good-deeds.component.html',
  styleUrls: ['./good-deeds.component.scss'],
})
export class GoodDeedsComponent implements OnInit {
  displayedColumns: string[] = ['order', 'title', 'guide', 'quiz', 'actions'];
  good_deeds: Array<GoodDeed> = [];
  available_good_deeds: Array<GoodDeed> = [];
  available_guides: Array<Guide> = [];
  available_quiz: Array<Quiz> = [];

  dataSource = new MatTableDataSource<GoodDeed>();

  length: any;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 100];

  pageEvent: PageEvent;

  latest_sort: any;
  tabIndex: number = 0;
  dataloaded = false;
  search = ""
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private commonService: CommonService,
    private orderPipe: OrderPipe,
    private snackBarService: SnackBarService,
    public dialog: MatDialog
  ) {}

  rowClick(row: any) {
    console.log('Row clicked: ', row);
  }

  async ngOnInit(): Promise<void> {
    this.initGoodDeeds();
    this.initGuides();
    this.initQuiz();
  }

  async reload(): Promise<void> {
    this.initGoodDeeds();
    this.tabIndex = 0;
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(',')
        .map((str) => +str);
    }
  }

  async initQuiz(): Promise<void> {
    try {
      const response = await this.commonService.getRequest('quiz/');

      this.available_quiz = response.results;

      let ids: Array<number> = [];

      this.good_deeds.forEach((good_deed) => {
        if (good_deed.guide?.quiz) {
          ids.push(good_deed.guide?.quiz.id);
        }
      });
      this.available_quiz.forEach((quiz) => {
        if (ids.includes(quiz.id)) {
          quiz.available = false;
        } else {
          quiz.available = true;
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  async initGuides(): Promise<void> {
    try {
      const response = await this.commonService.getRequest('guides/view');

      this.available_guides = response.results;
      let ids: Array<number> = [];

      this.good_deeds.forEach((good_deed) => {
        if (good_deed.guide !== undefined) {
          ids.push(good_deed.guide.id);
        }
      });

      this.available_guides.forEach((guide) => {
        if (ids.includes(guide.id)) {
          guide.available = false;
        } else {
          guide.available = true;
        }
      });
    } catch (error) {
      console.log(error);
    }
  }

  async updateGuide(guide_id: number, element: GoodDeed): Promise<void> {
    try {
      const body = {
        guide_id: guide_id,
      };

      const response = await this.commonService.putRequest(
        `good-deeds/add-guide/${element.id}`,
        body
      );

      await this.reload();

      await this.initGuides();
    } catch (error) {}
  }

  async updateQuiz(e: number, guide: Guide): Promise<void> {
    try {
      const body = {
        quiz_id: e,
      };

      const response = await this.commonService.putRequest(
        `guides/add-quiz/${guide.id}`,
        body
      );

      this.initQuiz();
    } catch (error) {}
  }

  async initGoodDeeds(): Promise<void> {
    try {
      const response = await this.commonService.getRequest('good-deeds/view');

      this.good_deeds = response.results;

      this.dataSource.data = this.good_deeds;

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataloaded = true

    } catch (error) {
      console.log(error);
    }
  }

  pagerChange(e: any): void {
    if (e.pageIndex === 0) {
      this.changePage(0, e.pageSize);
    } else {
      this.changePage(e.pageSize * e.pageIndex, e.pageSize);
    }
  }

  changePage(index: number, size: number) {
    this.available_good_deeds = this.good_deeds.slice(index, index + size);
  }

  sortData(e: any) {
    this.latest_sort = e;
    if (!e.direction) {
      this.available_good_deeds = this.orderPipe.transform(
        this.available_good_deeds,
        'id',
        true
      );
    } else {
      this.available_good_deeds = this.orderPipe.transform(
        this.available_good_deeds,
        e.active,
        e.direction === 'asc' ? false : true
      );
    }
  }

  async deleteGoodDeed(e: GoodDeed) {
    try {
      const results = await this.commonService.deleteRequest(
        `good-deeds/${e.id}`
      );

      if (e.guide !== undefined) {
        this.available_guides.forEach((guide) => {
          if (guide.id === e.guide?.id) {
            guide.available = true;
          }
        });
      }

      if (e.guide !== undefined && e.guide.quiz !== undefined) {
        this.available_quiz.forEach((quiz) => {
          if (quiz.id === e.guide?.quiz.id) {
            quiz.available = true;
          }
        });
      }

      let good_deeds_index = this.good_deeds
        .map((item) => item.id)
        .indexOf(e.id);

      this.good_deeds.splice(good_deeds_index, 1);

      this.available_good_deeds = this.good_deeds;

      this.dataSource.data = this.good_deeds;

      if (this.latest_sort !== undefined) {
        this.sortData(this.latest_sort);
      } else {
        this.sortData({
          direction: 'asc',
        });
      }

      this.snackBarService.displaySuccess('Η καλή πράξη διεγράφη επιτυχώς.');
    } catch (error: any) {
      this.snackBarService.displayError(error?.message);
    }
  }

  getGoodDeedLink(good_deed: GoodDeed) {
    return `/good-deed/${good_deed.id}`;
  }

  openDeleteDialog(good_deed: GoodDeed): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteGoodDeed(good_deed);
      }
    });
  }

  edit(goodDeed: GoodDeed) {
    const dialogRef = this.dialog.open(GoodDeedFormComponent, {
      data: {
        good_deed: goodDeed,
        update: true,
        guides: this.available_guides
      },
      width: '60vw',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.result) {
        this.reload();
      }
    });
  }

  create() {
    const dialogRef = this.dialog.open(GoodDeedFormComponent, {
      data: {
        update: false,
        available_guides: this.available_guides,
        available_quiz: this.available_quiz,
        guides: this.available_guides
      },
      width: '60vw',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.result) {
        this.reload();
      }
    });
  }

  getGuideId(element: GoodDeed): number {
    if (element.guide !== undefined) {
      return element.guide.id;
    }

    return 0;
  }

  getQuiz(quiz: Quiz) {
    return `/quiz/${quiz.id}`;
  }

  onSearch() {
    if (this.search) {
      this.available_good_deeds = this.good_deeds.filter((element) => {
        return element.title.toLowerCase().includes(this.search.toLowerCase());
      });
      this.dataSource.data = this.available_good_deeds
    } else {
      this.dataSource.data = this.good_deeds
    }
  }
}
