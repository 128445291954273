
<ng-container *ngIf="!dataloaded">
  <div class="spinner-div">
    <mat-spinner [diameter]="100"></mat-spinner>
  </div>
</ng-container>
<div [ngClass]="{'hide': !dataloaded}">

  <div>
    <button mat-raised-button class="custom-btn add-btn" (click)="create()">
      Προσθήκη Νέας Κατηγορίας
    </button>
  </div>
  <mat-form-field style="width:20%; padding: 20px;">
    <mat-label>Αναζήτηση</mat-label>
    <input matInput (keyup)="onSearch()"  [(ngModel)]="search">
  </mat-form-field>

  <table
    mat-table
    matSort
    [dataSource]="dataSource"
    class="table"
  >
    <ng-container matColumnDef="order">
      <th mat-header-cell *matHeaderCellDef>#</th>
      <td mat-cell *matCellDef="let element; let i = index">
        {{ i + 1 }}
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <mat-header-cell mat-sort-header *matHeaderCellDef>Όνομα</mat-header-cell>
      <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="actions">
      <mat-header-cell *matHeaderCellDef> Ενέργειες </mat-header-cell>
      <mat-cell *matCellDef="let element" align="right">
        <mat-icon matTooltip="Επεξεργασία" class="pointer primary-color" (click)="edit(element)">edit</mat-icon>
        <mat-icon *ngIf="element.can_delete" matTooltip="Διαγραφή" class="pointer close-color" (click)="openDeleteDialog(element)"
          >delete</mat-icon>
        <mat-icon *ngIf="element.id === 1 || element.id === 2" matTooltip="Η Κύρια κατηγορία δε μπορεί να διαγραφεί" class="pointer disable-color">delete</mat-icon>

        <mat-icon *ngIf="(element.id !== 1 && element.id !== 2) && !element.can_delete" matTooltip="Η Κατηγορία υπάρχει σε κάποια αναφορά προβλήματος και δε μπορεί να διαγραφεί" class="pointer disable-color">delete</mat-icon>

      </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
  </table>

  <mat-paginator
    [length]="length"
    [pageSizeOptions]="pageSizeOptions"
  >
  </mat-paginator>
</div>

