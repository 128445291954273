import { SettingsComponent } from './components/settings/settings.component';
import { QuestionsComponent } from './components/questions/questions.component';
import { QuestionComponent } from './components/question/question.component';
import { AllQuizComponent } from './components/all-quiz/all-quiz.component';
import { AccessGuard } from './access.guard';
import { UserComponent } from './components/user/user.component';
import { UsersComponent } from './components/users/users.component';
import { GuideComponent } from './components/guide/guide.component';
import { GoodDeedsComponent } from './components/good-deeds/good-deeds.component';
import { ReportComponent } from './components/report/report.component';
import { NgModule } from '@angular/core';

import { RouterModule, Routes } from '@angular/router';

import { SuggestionsComponent } from './components/suggestions/suggestions.component';
import { ReportsComponent } from './components/reports/reports.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { HomeLayoutComponent } from './components/home-layout/home-layout.component';
import { LoginLayoutComponent } from './components/login-layout/login-layout.component';
import { ProfileComponent } from './components/profile/profile.component';
import { SuggestionComponent } from './components/suggestion/suggestion.component';
import { GoodDeedComponent } from './components/good-deed/good-deed.component';
import { GuidesComponent } from './components/guides/guides.component';
import { PrivacyComponent } from './components/privacy/privacy.component';

const routes: Routes = [
  { path: '', component: LoginComponent },
  { path: 'privacy-policy', component: PrivacyComponent },
  {
    path: 'login',
    component: LoginLayoutComponent,
    children: [{ path: '', component: LoginComponent }],
  },
  {
    path: 'home',
    component: HomeLayoutComponent,
    children: [{ path: '', component: HomeComponent }],
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'profile',
    component: HomeLayoutComponent,
    children: [{ path: '', component: ProfileComponent }],
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'suggestions',
    component: HomeLayoutComponent,
    children: [{ path: '', component: SuggestionsComponent }],
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'reports',
    component: HomeLayoutComponent,
    children: [{ path: '', component: ReportsComponent }],
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'suggestion/:id',
    component: HomeLayoutComponent,
    children: [{ path: '', component: SuggestionComponent }],
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'report/:id',
    component: HomeLayoutComponent,
    children: [{ path: '', component: ReportComponent }],
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'good-deeds',
    component: HomeLayoutComponent,
    children: [{ path: '', component: GoodDeedsComponent }],
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'good-deed/:id',
    component: HomeLayoutComponent,
    children: [{ path: '', component: GoodDeedComponent }],
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'guides',
    component: HomeLayoutComponent,
    children: [{ path: '', component: GuidesComponent }],
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'guide/:id',
    component: HomeLayoutComponent,
    children: [{ path: '', component: GuideComponent }],
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'users',
    component: HomeLayoutComponent,
    children: [{ path: '', component: UsersComponent }],
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'user/:id',
    component: HomeLayoutComponent,
    children: [{ path: '', component: UserComponent }],
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'all-quiz',
    component: HomeLayoutComponent,
    children: [{ path: '', component: AllQuizComponent }],
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'questions',
    component: HomeLayoutComponent,
    children: [{ path: '', component: QuestionsComponent }],
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'question/:id',
    component: HomeLayoutComponent,
    children: [{ path: '', component: QuestionComponent }],
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
  {
    path: 'settings',
    component: HomeLayoutComponent,
    children: [{ path: '', component: SettingsComponent }],
    data: { requiresLogin: true },
    canActivate: [AccessGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
