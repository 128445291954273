<div class="mat-elevation-z8 main">
  <mat-tab-group
    mat-align-tabs="start"
    [(selectedIndex)]="tabIndex"
    dynamicHeight
    mat-stretch-tabs
    [disablePagination]="true"
  >
    <mat-tab label="Ερωτήσεις" disabled>
      <button mat-raised-button class="add-btn" (click)="create()">
        Προσθήκη νέας ερώτησης
      </button>
      <table
        mat-table
        matSort
        (matSortChange)="sortData($event)"
        [dataSource]="available_questions"
        class="table"
      >
        <ng-container matColumnDef="order">
          <th mat-header-cell *matHeaderCellDef>#</th>
          <td mat-cell *matCellDef="let element; let i = index">
            {{ i + 1 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="question">
          <mat-header-cell mat-sort-header *matHeaderCellDef
            >Ερώτηση</mat-header-cell
          >
          <mat-cell *matCellDef="let element">{{ element.question }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="actions">
          <mat-header-cell class="actions" *matHeaderCellDef>
            Ενέργειες
          </mat-header-cell>
          <mat-cell class="actions" *matCellDef="let element" align="right">
            <mat-icon class="pointer" (click)="edit(element)">edit</mat-icon>

            <mat-icon class="pointer" (click)="openDeleteDialog(element)"
              >delete</mat-icon
            >
          </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </table>
      <mat-paginator
        [length]="length"
        [pageSize]="pageSize"
        [pageSizeOptions]="pageSizeOptions"
        (page)="pagerChange($event)"
        aria-label="Επιλέξτε Σελίδα"
      >
      </mat-paginator>
    </mat-tab>
  </mat-tab-group>
</div>
