<div class="main">
  <div class="content" [ngStyle.lt-lg]="{'width':'100%'}" >
    <div style=" text-align: center;" [ngStyle.lt-lg]="{'margin-top':'0px'}">
      <img style="width: 100%; height: auto" [ngStyle.lt-lg]="{'width':'50%'}" src="../../../assets/main_logo.png">
    </div>
    <div class="container">
      <mat-grid-list cols="1" rows="1" rowHeight="60vh">
        <mat-grid-tile>
          <mat-card class="card mat-elevation-z0">
            <h2><strong>Σύνδεση</strong></h2>  
            <mat-card-content style="width: 100%;">
              <form [formGroup]="loginForm" class="form-div">
                <mat-grid-list cols="1" rows="3" rowHeight="3:1" style="width: 60%;">
                  <div>
                    <mat-form-field style="width: 100%">
                      <input
                        class="input"
                        formControlName="email"
                        matInput
                        placeholder="Email"
                      />
                    </mat-form-field>
                  </div>
                  <div>
                    <mat-form-field style="width: 100%">
                      <input
                        [type]="hide ? 'password' : 'text'"
                        formControlName="password"
                        matInput
                        placeholder="Κωδικός πρόσβασης"
                      />
                      <mat-icon style="cursor: pointer;" (click)="hide = !hide" matSuffix>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
                    </mat-form-field>
                  </div>
                </mat-grid-list>
              </form>
            </mat-card-content>
            <mat-card-actions>
              <div class="button-div">
                <button
                  (click)="submitForm()"
                  mat-raised-button
                  class="custom-btn"
                  *ngIf="!loading"
                  style="font-size: larger;"
                >
                  Σύνδεση
                </button>
                <mat-spinner [diameter]="30" *ngIf="loading"></mat-spinner>
              </div>
            </mat-card-actions>
          </mat-card>
        </mat-grid-tile>
      </mat-grid-list>
    </div>
    <div class="logo-div" [ngStyle.lt-lg]="{'width':'100%'}">
      <img style="max-width: 10%; height: auto" [ngStyle.lt-lg]="{'width':'5%'}" src="../../../assets/logo_1.png">
      <img style="max-width: 60%; height: auto" [ngStyle.lt-lg]="{'width':'20%'}" src="../../../assets/logo_2.jpg">
    </div>
  </div>
 
  <div fxHide.lt-lg style="width: 100%; height: 100%">
    <img style="width: 60vw; height: 100%" src="../../../assets/login_bg.jpg">
  </div>
</div>

<!-- <div class="main-div">
  <div class="text-div">
    <mat-grid-list cols="12" rowHeight="150px">
      <mat-grid-tile colspan="12">
        <div class="align-left mat-display-1">For My City</div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>

  <div class="container">
    <mat-grid-list cols="1" rows="1" rowHeight="80vh">
      <mat-grid-tile>
        <mat-card class="card">
          <mat-card-title> Σύνδεση </mat-card-title>
          <mat-card-content>
            <form [formGroup]="loginForm" class="form-div">
              <mat-grid-list cols="1" rows="3" rowHeight="3:1">
                <div>
                  <mat-form-field>
                    <input
                      class="input"
                      formControlName="email"
                      matInput
                      placeholder="Email"
                    />
                  </mat-form-field>
                </div>
                <div>
                  <mat-form-field>
                    <input
                      type="password"
                      formControlName="password"
                      matInput
                      placeholder="Κωδικός πρόσβασης"
                    />
                  </mat-form-field>
                </div>
              </mat-grid-list>
            </form>
          </mat-card-content>
          <mat-card-actions>
            <div class="button-div">
              <button
                (click)="submitForm()"
                mat-raised-button
                color="primary"
                *ngIf="!loading"
              >
                Σύνδεση
              </button>
              <mat-spinner [diameter]="30" *ngIf="loading"></mat-spinner>
            </div>
          </mat-card-actions>
        </mat-card>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div> -->
