import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  url = environment.apiUrl;

  constructor(private http: HttpClient) {}

  getImage(filename: string) {
    return `${this.url}/images/${filename}`;
  }

  async getRequest(endpoint: string): Promise<any> {

    return new Promise((resolve, reject) => {
      this.http.get(endpoint).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async postRequest(endpoint: string, body: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http.post(endpoint, body).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async putRequest(endpoint: string, body: any): Promise<any> {

    return new Promise((resolve, reject) => {
      this.http.put(endpoint, body).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  async deleteRequest(endpoint: string): Promise<any> {

    return new Promise((resolve, reject) => {
      this.http.delete(endpoint).subscribe(
        (response: any) => {
          resolve(response);
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
}
