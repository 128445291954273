import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { CommonService } from 'src/app/services/common.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-question-form',
  templateUrl: './question-form.component.html',
  styleUrls: ['./question-form.component.scss'],
})
export class QuestionFormComponent implements OnInit {
  @Output('created') reload: EventEmitter<any> = new EventEmitter();
  submited: boolean = false;
  update: boolean = false;

  form = this.formBuilder.group({
    question: ['', [Validators.required]],
    answer_one: ['', [Validators.required]],
    answer_two: ['', [Validators.required]],
    answer_three: ['', [Validators.required]],
    correct: ['', [Validators.required]],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    private commonService: CommonService,
    private dialogRef: MatDialogRef<QuestionFormComponent>
  ) {}

  ngOnInit(): void {
    if (this.data.update !== undefined) {
      this.update = this.data.update;
    }
    if (this.data.question) {
      this.form = this.formBuilder.group({
        question: [this.data.question.question, [Validators.required]],
        answer_one: [this.data.question.answer_one, [Validators.required]],
        answer_two: [this.data.question.answer_two, [Validators.required]],
        answer_three: [this.data.question.answer_three, [Validators.required]],
        correct: [this.data.question.correct, [Validators.required]],
      });
    }
  }

  clearForm() {
    this.form.reset();
    this.form.controls['question'].setErrors(null);
    this.form.controls['answer_one'].setErrors(null);
    this.form.controls['answer_two'].setErrors(null);
    this.form.controls['answer_three'].setErrors(null);
    this.form.controls['correct'].setErrors(null);
  }

  submit() {
    this.submited = true;
    if (!this.form.valid) {
      this.snackBarService.displayError('Συμπληρώστε όλα τα πεδία.');
    } else {
      if (this.update) {
        this.postUpdateForm();
      } else {
        this.postForm();
      }
    }
    this.submited = false;
  }

  formatData() {
    var question;

    if (this.data.question) {
      question = {
        id: this.data.question.id,
        question: this.form.get('question')?.value,
        answer_one: this.form.get('answer_one')?.value,
        answer_two: this.form.get('answer_two')?.value,
        answer_three: this.form.get('answer_three')?.value,
        correct: this.form.get('correct')?.value,
      };
    } else {
      question = {
        question: this.form.get('question')?.value,
        answer_one: this.form.get('answer_one')?.value,
        answer_two: this.form.get('answer_two')?.value,
        answer_three: this.form.get('answer_three')?.value,
        correct: this.form.get('correct')?.value,
      };
    }

    return question;
  }

  async postForm(): Promise<void> {
    try {
      const formData = this.formatData();

      const body = { question: formData };

      let response;

      if (this.data.question) {
        response = await this.commonService.putRequest(
          `questions/update/${this.data.question.id}`,
          body
        );
      } else {
        response = await this.commonService.postRequest(
          'questions/create',
          body
        );
      }

      this.snackBarService.displaySuccess('Η ερώτηση αποθηκεύτηκε επιτυχώς.');

      this.dialogRef.close({ question: body });
    } catch (error: any) {
      this.snackBarService.displayError(error?.error?.message);
    }
  }

  async postUpdateForm(): Promise<void> {
    try {
      const formData = this.formatData();

      const body = { question: formData };

      const response = await this.commonService.putRequest(
        `questions/update/${this.data.question.id}`,
        body
      );

      this.snackBarService.displaySuccess('Η ερώτηση αποθηκεύτηκε επιτυχώς.');

      this.clearForm();
      this.dialogRef.close({ result: true });
    } catch (error: any) {
      this.snackBarService.displayError(error?.error?.message);
    }
  }
}
