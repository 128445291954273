import { SnackBarService } from 'src/app/services/snack-bar.service';
import { CommonService } from './../../services/common.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { Guide } from 'src/app/interfaces/guide.interface';
import { GuideFormComponent } from '../guide-form/guide-form.component';

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss'],
})
export class GuideComponent implements OnInit {
  guide: Guide;

  constructor(
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private snackBarService: SnackBarService,
    private location: Location,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    let id = this.activatedRoute.snapshot.paramMap.get('id');

    this.initData(id);
  }

  async initData(id: any): Promise<void> {
    try {
      const response = await this.commonService.getRequest(`guides/${id}`);

      console.log(response.result)
      this.guide = response.result.guide;
    } catch (error) {
      console.log(error);
    }
  }

  async openDeleteDialog() {
    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.delete();
      }
    });
  }

  async delete() {
    try {
      const results = await this.commonService.deleteRequest(
        `guides/${this.guide.id}`
      );

      this.snackBarService.displaySuccess('Ο οδηγός διεγράφη επιτυχώς.');

      this.location.back();
    } catch (error: any) {
      this.snackBarService.displayError(error?.message);
    }
  }

  edit() {
    const dialogRef = this.dialog.open(GuideFormComponent, {
      data: {
        guide: this.guide,
        update: true,
      },
      width: '70vw',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.result) {
        this.initData(this.guide.id);
      }
    });
  }
}
