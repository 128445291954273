<div class="mat-elevation-z8 main">
  <mat-tab-group mat-align-tabs="start" dynamicHeight mat-stretch-tabs>
    <mat-tab label="Αναφορές Προβλημάτων">

      <ng-container *ngIf="!dataloaded">
        <div class="spinner-div">
          <mat-spinner [diameter]="100"></mat-spinner>
        </div>
      </ng-container>
      <div [ngClass]="{'hide': !dataloaded}">
        <div style="padding: 20px;">
          <mat-form-field style="width:20%;">
            <mat-label>Αναζήτηση</mat-label>
            <input matInput (keyup)="onSearch()"  [(ngModel)]="search">
          </mat-form-field>
        </div>
        <table
          mat-table
          matSort
          [dataSource]="dataSource"
          class="table"
        >
          <ng-container matColumnDef="order">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let element; let i = index">
              {{ i + 1 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell mat-sort-header *matHeaderCellDef
              >Όνομα</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{
              element.name
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="sound">
            <mat-header-cell mat-sort-header *matHeaderCellDef
              >Ένταση Ήχου</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{
              element.sound > 0 ? element.sound.toFixed(2) + " Db" : '-'
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="like">
            <mat-header-cell mat-sort-header *matHeaderCellDef
              >Like</mat-header-cell
            >
            <mat-cell *matCellDef="let element">
              <div style="padding-left: 5px">
                {{ element.like }}
              </div>
            </mat-cell>
          </ng-container>
          <ng-container matColumnDef="dislike">
            <mat-header-cell mat-sort-header *matHeaderCellDef
              >Dislike</mat-header-cell
            >
            <mat-cell *matCellDef="let element"> 
              <div style="padding-left: 8px">
                {{ element.dislike }}
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="approved">
            <mat-header-cell mat-sort-header *matHeaderCellDef
              >Κατάσταση</mat-header-cell
            >
            <mat-cell *matCellDef="let element" align="left">
              <div style="padding-left: 15px">
                <mat-icon style="cursor: pointer;" matTooltip="Εγκεκριμένη" *ngIf="element.approved">done</mat-icon>
                <mat-icon style="cursor: pointer;" matTooltip="Μη Εγκεκριμένη" *ngIf="!element.approved">clear</mat-icon>
              </div>
            </mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <mat-header-cell *matHeaderCellDef> Ενέργειες </mat-header-cell>
            <mat-cell *matCellDef="let element" align="right">
              <mat-icon
                class="pointer primary-color"
                matTooltip="Δείτε την αναφορά προλήματος"
                [routerLink]="getReportLink(element)"
                >visibility</mat-icon
              >
              <mat-icon matTooltip="Διαγραφή" class="pointer close-color" (click)="openDeleteDialog(element)"
                >delete</mat-icon
              >
            </mat-cell>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </table>

        <mat-paginator
          [length]="length"
          [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
      </div>

    </mat-tab>
    <mat-tab label="Κατηγορίες">
      <app-report-categories></app-report-categories>
    </mat-tab>
    <!-- <mat-tab label="Υποκατηγορίες">
      <app-report-sub-categories></app-report-sub-categories>
    </mat-tab> -->
  </mat-tab-group>
</div>
