import { CommonService } from './../../services/common.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { Suggestion } from 'src/app/interfaces/suggestion.interface';

import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { OrderPipe } from 'ngx-order-pipe';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';

@Component({
  selector: 'app-suggestions',
  templateUrl: './suggestions.component.html',
  styleUrls: ['./suggestions.component.scss'],
})
export class SuggestionsComponent implements OnInit {
  displayedColumns: string[] = [
    'order',
    'name',
    'like_number',
    'dislike_number',
    'approved',
    'actions',
  ];
  suggestions: Array<Suggestion> = [];
  available_suggestions: Array<Suggestion> = [];

  dataSource = new MatTableDataSource<Suggestion>();

  length: any;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 100];

  pageEvent: PageEvent;

  latest_sort: any;

  dataloaded = false;
  search = ""
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private commonService: CommonService,
    private orderPipe: OrderPipe,
    private snackBarService: SnackBarService,
    public dialog: MatDialog
  ) {}

  rowClick(row: any) {
    console.log('Row clicked: ', row);
  }

  ngOnInit(): void {
    this.initSuggestions();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(',')
        .map((str) => +str);
    }
  }

  async initSuggestions(): Promise<void> {
    try {
      const response = await this.commonService.getRequest('suggestions/view');

      this.suggestions = response.results;

      this.available_suggestions =  this.suggestions

      this.dataSource.data = this.available_suggestions

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      this.dataloaded = true

    } catch (error) {
      console.log(error);
    }
  }

  pagerChange(e: any): void {
    if (e.pageIndex === 0) {
      this.changePage(0, e.pageSize);
    } else {
      this.changePage(e.pageSize * e.pageIndex, e.pageSize);
    }
  }

  changePage(index: number, size: number) {
    this.available_suggestions = this.suggestions.slice(index, index + size);
  }

  sortData(e: any) {
    this.latest_sort = e;
    if (!e.direction) {
      this.available_suggestions = this.orderPipe.transform(
        this.available_suggestions,
        'id',
        true
      );
    } else {
      this.available_suggestions = this.orderPipe.transform(
        this.available_suggestions,
        e.active,
        e.direction === 'asc' ? false : true
      );
    }
  }

  async deleteSuggestion(e: any) {
    try {
      const results = await this.commonService.deleteRequest(
        `suggestions/${e.id}`
      );
      let index = this.available_suggestions
        .map((item) => item.id)
        .indexOf(e.id);

      let suggestion_index = this.suggestions
        .map((item) => item.id)
        .indexOf(e.id);

      this.suggestions.splice(suggestion_index, 1);

      this.available_suggestions = this.suggestions;

      this.dataSource.data = this.available_suggestions

      if (this.latest_sort !== undefined) {
        this.sortData(this.latest_sort);
      } else {
        this.sortData({
          direction: 'asc',
        });
      }

      this.snackBarService.displaySuccess('Η πρόταση διεγράφη επιτυχώς.');
    } catch (error: any) {
      this.snackBarService.displayError(error?.message);
    }
  }

  getSuggestionLink(suggestion: Suggestion) {
    return `/suggestion/${suggestion.id}`;
  }

  openDeleteDialog(suggestion: Suggestion): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteSuggestion(suggestion);
      }
    });
  }

  onSearch() {
    if (this.search) {
      this.available_suggestions = this.suggestions.filter((element) => {
        return element.name.toLowerCase().includes(this.search.toLowerCase());
      });
      this.dataSource.data = this.available_suggestions
    } else {
      this.dataSource.data = this.suggestions
    }
  }
}
