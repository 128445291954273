import { GoodDeed } from './../../interfaces/good-deed.interface';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/services/common.service';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { Quiz } from 'src/app/interfaces/quiz.interface';
import { Guide } from 'src/app/interfaces/guide.interface';
@Component({
  selector: 'app-good-deed-form',
  templateUrl: './good-deed-form.component.html',
  styleUrls: ['./good-deed-form.component.scss'],
})
export class GoodDeedFormComponent implements OnInit {
  constructor(
    public formBuilder: FormBuilder,
    private snackBarService: SnackBarService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<GoodDeedFormComponent>
  ) {}

  @Output('created') reload: EventEmitter<any> = new EventEmitter();

  form = this.formBuilder.group({
    title: ['', [Validators.required]],
    description: ['', [Validators.required]],
    guide: ['', [Validators.required]],
    // quiz: ['', [Validators.required]],
  });

  submited: boolean = false;
  good_deed: GoodDeed;
  available_guides: Array<Guide>;
  available_quiz: Array<Quiz>;
  update: boolean = false;

  @Input() isUpdate: boolean;

  ngOnInit(): void {
    this.good_deed = this.data.good_deed;
 
    this.available_guides = this.data.guides;
    this.available_quiz = this.data.available_quiz;

    if (this.data.update !== undefined) {
      this.update = this.data.update;
    } else {
      this.update = false;
    }

    if (this.good_deed) {
      this.form = this.formBuilder.group({
        title: [this.good_deed.title, [Validators.required]],
        description: [this.good_deed.description, [Validators.required]],
        guide: [this.good_deed.guide?.id, [Validators.required]],
        // quiz: [this.good_deed.quiz, [Validators.required]],
      });
    }
  }

  submit(): void {
    this.submited = true;
    if (!this.form.valid) {
      this.snackBarService.displayError('Συμπληρώστε όλα τα πεδία.');
    } else {
      if (this.update) {
        this.postUpdateForm();
      } else {
        this.postForm();
      }
    }
    this.submited = false;
  }

  formatData() {
    const good_deed = {
      title: this.form.get('title')?.value,
      description: this.form.get('description')?.value,
      guide: this.form.get('guide')?.value,
      // quiz: this.form.get('quiz')?.value,
    };

    return good_deed;
  }

  async postForm(): Promise<void> {
    try {
      const formData = this.formatData();

      const body = { good_deed: formData };

      const response = await this.commonService.postRequest(
        'good-deeds/create',
        body
      );

      this.snackBarService.displaySuccess(
        'Η καλή πράξη αποθηκεύτηκε επιτυχώς.'
      );
      // this.reload.emit();

      this.dialogRef.close({ result: true });
      this.clearForm();
    } catch (error: any) {
      console.log(error);
      this.snackBarService.displayError(error?.error?.message);
    }
  }

  async postUpdateForm(): Promise<void> {
    try {
      const formData = this.formatData();

      const body = { good_deed: formData };

      const response = await this.commonService.putRequest(
        `good-deeds/update/${this.good_deed.id}`,
        body
      );

      this.snackBarService.displaySuccess('Η καλή πράξη αποθηκεύτηκε επιτυχώς.');

      this.clearForm();
      this.dialogRef.close({ result: true });
    } catch (error: any) {
      this.snackBarService.displayError(error?.error?.message);
    }
  }

  clearForm() {
    this.form.reset();
    this.form.controls['title'].setErrors(null);
    this.form.controls['description'].setErrors(null);
    this.form.controls['guide'].setErrors(null);
    // this.form.controls['quiz'].setErrors(null);
  }

  close() {
    this.dialogRef.close();
  }
}
