import { SnackBarService } from './snack-bar.service';
import { CommonService } from './common.service';
import { User } from './../interfaces/user.interface';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  user: any;

  constructor(private router: Router, private commonService: CommonService, private snackBarService: SnackBarService) {}

  async logout(expired = false): Promise<void> {
    localStorage.removeItem('token');
    localStorage.removeItem('user');

    if (expired) {
      this.snackBarService.displayError("Έληξε η περίοδος της σύνδεσής σας, παρακαλούμε συνδεθείτε ξανά.")
    }
    this.router.navigateByUrl('/');
  }

  async login(data: any): Promise<void> {
    localStorage.setItem('token', data.token);

    localStorage.setItem('user', JSON.stringify(data.user));

    this.router.navigateByUrl('/home');
  }

  getUser(): User {
    if (this.user === undefined) {
      if (localStorage.getItem('user') !== null) {
        this.user = JSON.parse(localStorage.getItem('user') || '{}');
      }
    }
    return this.user;
  }

  async checkLoggedUser(login: boolean = false): Promise<void> {
    let user = this.getUser();

    if (user) {
      await this.syncUser(login);
    }
  }

  async syncUser(login: boolean = false): Promise<void> {
    try {
      const response = await this.commonService.getRequest(
        `users/${this.user.id}`
      );

      localStorage.setItem('user', JSON.stringify(response.user));
      this.user = response.user;

      if (login) {
        this.router.navigateByUrl('/home');
      }
    } catch (error) {
      console.log(error);
    }
  }

  async checkValidToken() {
    try {
      if (this.user) {
        const response = await this.commonService.getRequest(
          `users/${this.user.id}`
        );
      }
    } catch (error) {
      this.logout(true);
    }
  }
}
