import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss']
})
export class ImageViewerComponent implements OnInit {

  src = ""
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ImageViewerComponent>) {
    if (data.src) {
      this.src = data.src
    }
    console.log(this.src)
   }

  ngOnInit(): void {
  }

  close() {
    this.dialogRef.close()
  }
}
