<mat-dialog-content class="mat-typography dialog">
  <ng-container mat-dialog-title>
    <h1 class="modal-title"  *ngIf="title === undefined">
      <span>Επεξεργασία Κατηγορίας</span>
      <mat-icon style="cursor: pointer;" (click)="close()">close</mat-icon>
    </h1>
    <h1 class="modal-title"  *ngIf="title !== undefined">
      <span>{{ title }}</span>
      <mat-icon style="cursor: pointer;" (click)="close()">close</mat-icon>
    </h1>
  </ng-container>

  <mat-form-field class="field" *ngIf="type === undefined || type === 'input'">
    <mat-label *ngIf="title === undefined">Όνομα</mat-label>
    <mat-label *ngIf="placeholder !== undefined">{{ placeholder }}</mat-label>
    <input matInput [(ngModel)]="input" />
  </mat-form-field>
  <mat-form-field
    class="field"
    *ngIf="type !== undefined || type === 'textarea'"
  >
    <mat-label *ngIf="placeholder !== undefined">{{ placeholder }}</mat-label>

    <textarea
      [rows]="rows !== null ? rows : 10"
      matInput
      placeholder="Κείμενο"
      [(ngModel)]="input"
      required
    ></textarea>
  </mat-form-field>
</mat-dialog-content>

<div mat-dialog-actions align="end">
  <mat-dialog-actions>
    <button class="close-btn" mat-raised-button [mat-dialog-close]="false">Ακύρωση</button>
    <button mat-raised-button class="custom-btn" [mat-dialog-close]="input">
      Επιβεβαίωση
    </button>
  </mat-dialog-actions>
</div>
