<div style="width: 100%;" class="content">
    <mat-table
        [dataSource]="data"
        #table
       
    >
        <ng-container matColumnDef="name">
        <th style="border: none; width: 80%" mat-header-cell *matHeaderCellDef>Όνομα</th>
        <td mat-cell *matCellDef="let element" style="border: none; width: 80%"> <a class="info-link" style="text-decoration: none;" [routerLink]="'/suggestion/'+element.id">{{ element.name }}</a></td>
        </ng-container>

        <ng-container matColumnDef="approved">
        <th style="border: none; width: 5%" mat-header-cell *matHeaderCellDef
            >Ενέργεια</th
        >
        <td style="border: none; width: 5%; text-align: center;" mat-cell *matCellDef="let element; let i = index">
            <mat-icon style="cursor: pointer;" (click)="approveSuggestion(i)" matTooltip="Έγκριση Πρότασης">done</mat-icon>
        </td>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
    </mat-table>
 
</div>