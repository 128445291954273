import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  displayError(message: any): void {
    this.snackBar.open(message, 'Κλείσιμο', { panelClass: 'error-snackbar', duration: 3000 });
  }

  displaySuccess(message: any): void {
    this.snackBar.open(message, 'Κλείσιμο', { panelClass: 'suceess-snackbar', duration: 3000 });
  }
}
