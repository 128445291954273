<div class="mat-elevation-z8 main">
  <mat-tab-group
    mat-align-tabs="start"
    [(selectedIndex)]="tabIndex"
    dynamicHeight
    mat-stretch-tabs
    [disablePagination]="true"
    class="tab-header"
  >
    <mat-tab label="Καλές Πράξεις" disabled>
      
      <ng-container *ngIf="!dataloaded">
        <div class="spinner-div">
          <mat-spinner [diameter]="100"></mat-spinner>
        </div>
      </ng-container>
      <div [ngClass]="{'hide': !dataloaded}">
        <div>
          <button mat-raised-button class="add-btn custom-btn" (click)="create()">
            Προσθήκη Νέας Καλής Πράξης
          </button>
        </div>
        <mat-form-field style="width:20%; padding-left: 20px;">
          <mat-label>Αναζήτηση</mat-label>
          <input matInput (keyup)="onSearch()"  [(ngModel)]="search">
        </mat-form-field>
        <table
          mat-table
          matSort
          [dataSource]="dataSource"
          class="table"
        >
          <ng-container matColumnDef="order">
            <th mat-header-cell *matHeaderCellDef style="width: 2%; border: none;">#</th>
            <td mat-cell *matCellDef="let element; let i = index" style="width: 2%; border: none;">
              {{ i + 1 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="title">
            <th mat-header-cell mat-sort-header *matHeaderCellDef style="width: 40%; border: none;">
              Τίτλος
            </th>
            <td style="width: 40%; border: none;" mat-cell *matCellDef="let element">{{ element.title }}</td>
          </ng-container>

          <ng-container matColumnDef="guide">
            <th mat-header-cell *matHeaderCellDef style="width: 30%; border: none;"
              >Οδηγός</th
            >

            <th mat-cell *matCellDef="let element" style="width: 30%; border: none; text-align: start; font-weight:400">
              <mat-select
                class="select"
                (valueChange)="updateGuide($event, element)"
                [(ngModel)]="element.guide.id"
                *ngIf="element.guide !== undefined"
              >
                <mat-option
                  *ngIf="available_guides.length <= 0"
                  [value]="0"
                  [disabled]="true"
                >
                  Δεν βρέθηκε κάποιος οδηγός
                </mat-option>

                <mat-option
                  *ngFor="let guide of available_guides"
                  [value]="guide.id"
                >
                  {{ guide.title }}
                </mat-option>
              </mat-select>

              <mat-select
                class="select"
                (valueChange)="updateGuide($event, element)"
                *ngIf="element.guide === undefined"
              >
                <mat-option
                  *ngIf="available_guides.length <= 0"
                  [value]="0"
                  [disabled]="true"
                >
                  Δεν βρέθηκε κάποιος οδηγός
                </mat-option>

                <mat-option
                  *ngFor="let guide of available_guides"
                  [value]="guide.id"
                >
                  {{ guide.title }}
                </mat-option>
              </mat-select>
            </th>
          </ng-container>

          <ng-container matColumnDef="quiz">
            <th mat-header-cell *matHeaderCellDef style="width: 15%; border: none;"
              >Quiz</th
            >
            <td mat-cell *matCellDef="let element" style="width: 15%; border: none;  ">
              {{ element.guide?.quiz?.title }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell class="actions" *matHeaderCellDef style="width: 10%; border: none;">
              Ενέργειες
            </th>
            <td mat-cell class="actions" *matCellDef="let element" style="width: 10%; border: none;">
              <mat-icon   matTooltip="Δείτε τη καλή πράξη" class="pointer primary-color" [routerLink]="getGoodDeedLink(element)"
                >visibility</mat-icon
              >

              <mat-icon matTooltip="Επεξεργασία" class="pointer primary-color" (click)="edit(element)">edit</mat-icon>

              <mat-icon matTooltip="Διαγραφή" class="pointer close-color" (click)="openDeleteDialog(element)"
                >delete</mat-icon
              >
            </td>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </table>
        <mat-paginator
          [length]="length"
          [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
      </div>

    </mat-tab>
  </mat-tab-group>
</div>
