import { CommonService } from './../../services/common.service';
import { Component, OnInit, ViewChild } from '@angular/core';

import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { OrderPipe } from 'ngx-order-pipe';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { CreateDialogComponentComponent } from '../create-dialog-component/create-dialog-component.component';
import { SuggestionCategory } from 'src/app/interfaces/suggestion-category.interface';
import { UpdateDialogComponent } from '../update-dialog/update-dialog.component';

@Component({
  selector: 'app-suggestion-categories',
  templateUrl: './suggestion-categories.component.html',
  styleUrls: ['./suggestion-categories.component.scss'],
})
export class SuggestionCategoriesComponent implements OnInit {
  displayedColumns: string[] = ['order', 'name', 'actions'];
  categories: Array<SuggestionCategory> = [];
  available_categories: Array<SuggestionCategory> = [];

  dataSource = new MatTableDataSource<SuggestionCategory>();

  length: any;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 100];

  pageEvent: PageEvent;

  latest_sort: any;
  dataloaded = false;

  search = ""
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(
    private commonService: CommonService,
    private orderPipe: OrderPipe,
    private snackBarService: SnackBarService,
    public dialog: MatDialog
  ) {}

  rowClick(row: any) {
    console.log('Row clicked: ', row);
  }

  ngOnInit(): void {
    this.initcategories();
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(',')
        .map((str) => +str);
    }
  }

  async initcategories(): Promise<void> {
    try {
      const response = await this.commonService.getRequest(
        'suggestion-categories/view'
      );

      this.categories = response.results;

      this.dataSource.data = this.categories

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataloaded = true

    } catch (error) {
      console.log(error);
    }
  }

  pagerChange(e: any): void {
    if (e.pageIndex === 0) {
      this.changePage(0, e.pageSize);
    } else {
      this.changePage(e.pageSize * e.pageIndex, e.pageSize);
    }
  }

  changePage(index: number, size: number) {
    this.available_categories = this.categories.slice(index, index + size);
  }

  sortData(e: any) {
    this.latest_sort = e;
    if (!e.direction) {
      this.available_categories = this.orderPipe.transform(
        this.available_categories,
        'id',
        true
      );
    } else {
      this.available_categories = this.orderPipe.transform(
        this.available_categories,
        e.active,
        e.direction === 'asc' ? false : true
      );
    }
  }

  async delete(e: any) {
    try {
      const results = await this.commonService.deleteRequest(
        `suggestion-categories/${e.id}`
      );

      let categories_index = this.categories
        .map((item) => item.id)
        .indexOf(e.id);

      this.categories.splice(categories_index, 1);

      this.available_categories = this.categories;

      this.dataSource.data = this.categories

      if (this.latest_sort !== undefined) {
        this.sortData(this.latest_sort);
      } else {
        this.sortData({
          direction: 'asc',
        });
      }

      this.snackBarService.displaySuccess('Η κατηγορία διεγράφη επιτυχώς.');
    } catch (error: any) {
      this.snackBarService.displayError(error?.message);
    }
  }

  openDeleteDialog(category: SuggestionCategory): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.delete(category);
      }
    });
  }

  edit(category: SuggestionCategory) {
    const dialogRef = this.dialog.open(UpdateDialogComponent, {
      height: '220px',
      data: { current: category },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined && result) {
        this.postUpdate(category, result);
      }
    });
  }

  create() {
    const dialogRef = this.dialog.open(CreateDialogComponentComponent, {
      height: '220px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result !== undefined && result) {
        this.postCreate(result);
      }
    });
  }

  async postUpdate(category: SuggestionCategory, name: string): Promise<void> {
    try {
      const body = {
        field: 'name',
        new_value: name,
      };
      const repsonse = await this.commonService.putRequest(
        `suggestion-categories/update/${category.id}`,
        body
      );

      this.categories.map((element) => {
        if (element.id === category.id) {
          element.name = name;
        }
      });

      this.available_categories.map((element) => {
        if (element.id === category.id) {
          element.name = name;
        }
      });

      this.snackBarService.displaySuccess('Η κατηγορία αποθηκεύτηκε επιτυχώς.');
    } catch (error) {
      console.log(error);
    }
  }

  async postCreate(name: string): Promise<void> {
    try {
      const body = {
        category: {
          name: name,
        },
      };
      const repsonse = await this.commonService.postRequest(
        `suggestion-categories/create`,
        body
      );

      this.categories.push(repsonse);
      this.available_categories.push(repsonse);

      this.dataSource.data = this.categories

      if (this.latest_sort !== undefined) {
        this.sortData(this.latest_sort);
      } else {
        this.sortData({
          direction: 'asc',
        });
      }

      this.snackBarService.displaySuccess('Η κατηγορία δημιουργήθηκε επιτυχώς.');
    } catch (error) {
      console.log(error);
    }
  }
  
  onSearch() {
    if (this.search) {
      this.available_categories = this.categories.filter((element) => {
        return element.name.toLowerCase().includes(this.search.toLowerCase());
      });
      this.dataSource.data = this.available_categories
    } else {
      this.dataSource.data = this.categories
    }
  }
}
