
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { CommonService } from './../../services/common.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Report } from 'src/app/interfaces/report.interface';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { ImageViewerComponent } from '../image-viewer/image-viewer.component';

declare var google: any;

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss'],
})
export class ReportComponent implements OnInit {
  report: Report;
  map: any;
  images: any[] = [];

  constructor(
    private commonService: CommonService,
    private activatedRoute: ActivatedRoute,
    private snackBarService: SnackBarService,
    private location: Location,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    let id = this.activatedRoute.snapshot.paramMap.get('id');

    this.initReport(id);
  }

  async initReport(id: any): Promise<void> {
    try {
      const response = await this.commonService.getRequest(`reports/${id}`);
      this.report = response.report;
      if (this.report.images.length) {
        this.report.images.forEach((element: any) => {
          this.images.push({
            path: this.commonService.getImage(element.filename)
          })
        });      }
    } catch (error) {
      console.log(error);
    }
  }

  getDate(report: Report): string {
    let date = new Date(report.report.added_at);

    var month = date.getUTCMonth() + 1;
    var day = date.getUTCDate();
    var year = date.getUTCFullYear();

    return year + '-' + month + '-' + day;
  }

  async openDeleteDialog() {
    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteReport();
      }
    });
  }

  async deleteReport() {
    try {
      const results = await this.commonService.deleteRequest(
        `reports/${this.report.report.id}`
      );

      this.snackBarService.displaySuccess('Η αναφορά διεγράφη επιτυχώς.');

      this.location.back();
    } catch (error: any) {
      this.snackBarService.displayError(error?.message);
    }
  }

  async approve() {
    try {
      const respone = await this.commonService.getRequest(
        `reports/approve/${this.report.report.id}`
      );
      this.report = respone.report;
      this.snackBarService.displaySuccess('Η αναφορά εγκρίθηκε επιτυχώς.');
    } catch (error: any) {
      this.snackBarService.displaySuccess(error?.message);
    }
  }

  async disapprove() {
    try {
      const respone = await this.commonService.getRequest(
        `reports/disapprove/${this.report.report.id}`
      );
      this.report = respone.report;
      this.snackBarService.displaySuccess('Η αναφορά ορίστηκε ως μη εγκεκριμένη.');
    } catch (error: any) {
      this.snackBarService.displaySuccess(error?.message);
    }
  }

  getApprovedName() {
    return this.report.report.approved ? 'Εγκεκριμένη' : 'Μη Εγκεκριμένη';
  }

  onMapReady(map: any) {
    this.map = map;

    this.addMarker()
  }

  addMarker() {

    const location = new google.maps.LatLng(
      this.report.report.lat,
      this.report.report.lon,
    );

    let marker = new google.maps.Marker({
      position: location,
      title: this.report.report.name,
      latitude: this.report.report.lat,
      longitude: this.report.report.lon,
      map: this.map,
    });

    this.addInfoToMarket(marker);

  }

  addInfoToMarket(marker: any) {
    let infoContent = '';

    infoContent = `<div id="info-div">
      <strong>${this.report.report.name}</strong><br>
    </div>`;

    marker.setClickable(true);

    let infoWindow = new google.maps.InfoWindow({
      content: infoContent,
      minWidth: 180,
    });

    marker.addListener('click', (e: any) => {
      infoWindow.open(this.map, marker);
    });

    marker.setClickable(true);
  }

  /**
   * Description: Gets image and displays it in a dialog component
   * @param image object containing path string member;
   */
  displayImage(image: any) {
     this.dialog.open(ImageViewerComponent, {
        data: {
          src: image.path
        },
        height: "85vh"
      });
  }
}
