import { CommonService } from './../../services/common.service';
import { Component, OnInit, ViewChild } from '@angular/core';

import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import { OrderPipe } from 'ngx-order-pipe';
import { SnackBarService } from 'src/app/services/snack-bar.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDialogComponent } from '../delete-dialog/delete-dialog.component';
import { GuideFormComponent } from '../guide-form/guide-form.component';
import { Guide } from 'src/app/interfaces/guide.interface';
import { Quiz } from 'src/app/interfaces/quiz.interface';

@Component({
  selector: 'app-guides',
  templateUrl: './guides.component.html',
  styleUrls: ['./guides.component.scss'],
})
export class GuidesComponent implements OnInit {
  displayedColumns: string[] = ['order', 'title', 'quiz', 'actions'];
  guides: Array<Guide> = [];
  available_guides: Array<Guide> = [];
  available_quiz: Array<Quiz> = [];

  dataSource = new MatTableDataSource<Guide>();

  length: any;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 100];

  pageEvent: PageEvent;

  latest_sort: any;

  tabIndex: number = 0;
  dataloaded = false;
  quizLoaded = false;

  search = ""
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private commonService: CommonService,
    private orderPipe: OrderPipe,
    private snackBarService: SnackBarService,
    public dialog: MatDialog
  ) {}

  rowClick(row: any) {
    console.log('Row clicked: ', row);
  }

  async ngOnInit(): Promise<void> {
     this.initGuides();
     this.initQuiz();
  }

  async reload(): Promise<void> {
    this.quizLoaded = false

     this.initGuides();
     this.initQuiz();

    this.tabIndex = 0;
  }

  setPageSizeOptions(setPageSizeOptionsInput: string) {
    if (setPageSizeOptionsInput) {
      this.pageSizeOptions = setPageSizeOptionsInput
        .split(',')
        .map((str) => +str);
    }
  }

  async initGuides(): Promise<void> {
    try {
      const response = await this.commonService.getRequest('guides/view');

      this.guides = response.results;

      this.available_guides = this.guides;
      this.dataSource.data = this.available_guides;

      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
      this.dataloaded = true

    } catch (error) {
      console.log(error);
    }
  }

  async initQuiz(): Promise<void> {
    try {
      const response = await this.commonService.getRequest('quiz/');

      this.available_quiz = response.results;

      this.quizLoaded = true
    } catch (error) {
      console.log(error);
    }
  }

  pagerChange(e: any): void {
    if (e.pageIndex === 0) {
      this.changePage(0, e.pageSize);
    } else {
      this.changePage(e.pageSize * e.pageIndex, e.pageSize);
    }
  }

  changePage(index: number, size: number) {
    this.available_guides = this.guides.slice(index, index + size);
  }

  sortData(e: any) {
    this.latest_sort = e;
    if (!e.direction) {
      this.available_guides = this.orderPipe.transform(
        this.available_guides,
        'id',
        true
      );
    } else {
      this.available_guides = this.orderPipe.transform(
        this.available_guides,
        e.active,
        e.direction === 'asc' ? false : true
      );
    }
  }

  async deleteGuide(e: any) {
    try {
      const results = await this.commonService.deleteRequest(`guides/${e.id}`);

      let guides_index = this.guides.map((item) => item.id).indexOf(e.id);

      this.guides.splice(guides_index, 1);

      this.available_guides = this.guides;

      this.dataSource.data = this.available_guides;

      if (this.latest_sort !== undefined) {
        this.sortData(this.latest_sort);
      } else {
        this.sortData({
          direction: 'asc',
        });
      }

      this.snackBarService.displaySuccess('Ο οδηγός διεγράφη επιτυχώς.');
    } catch (error: any) {
      this.snackBarService.displayError(error?.message);
    }
  }

  getLink(guide: Guide) {
    return `/guide/${guide.id}`;
  }

  openDeleteDialog(guide: Guide): void {
    const dialogRef = this.dialog.open(DeleteDialogComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteGuide(guide);
      }
    });
  }

  edit(guide: Guide) {
    const dialogRef = this.dialog.open(GuideFormComponent, {
      data: {
        guide: guide,
        update: true,
      },
      width: '80vw',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.result) {
        this.reload();
      }
    });
  }

  addGuide() {
    const dialogRef = this.dialog.open(GuideFormComponent, {
      data: {
        update: false,
      },
      width: '80vw',
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result?.result) {
        this.reload();
      }
    });
  }

  async updateQuiz(e: any, guide: Guide) {
    const quiz_id = e;
    this.quizLoaded = false
    try {
      const body = {
        quiz_id: quiz_id,
      };

      const response = await this.commonService.putRequest(
        `guides/add-quiz/${guide.id}`,
        body
      );

      this.reload();
      this.snackBarService.displaySuccess('Το quiz αποθηκεύτηκε επιτυχώς');
    } catch (error) {
      this.quizLoaded = true
    }
  }

  onSearch() {
    if (this.search) {
      this.available_guides = this.guides.filter((element) => {
        return element.title.toLowerCase().includes(this.search.toLowerCase());
      });
      this.dataSource.data = this.available_guides
    } else {
      this.dataSource.data = this.guides
    }
  }

  async removeQuiz(guide: Guide) {
    try {
 
      const response = await this.commonService.getRequest(
        `guides/remove-quiz/${guide.id}`,
      );

      this.reload();
      this.snackBarService.displaySuccess('Το quiz αφαιρέθηκε επιτυχώς');
    } catch (error) {}
  }
}
