<mat-card>
    <mat-card-header>
        <mat-select [(ngModel)]="selected_map_option" (selectionChange)="changeMapOption()">
            <mat-option *ngFor="let option of available_map_options" [value]="option.value">{{option.name}}</mat-option>
        </mat-select>
    </mat-card-header>
    <mat-card-content>

        <div *ngIf="!dataloaded">
            <div class="spinner-div">
                <mat-spinner [diameter]="100"></mat-spinner>
            </div>
        </div>
        <agm-map
            [ngClass]="{'hide': !dataloaded}"
            #map
            [latitude]="average_pos.lat"
            [longitude]="average_pos.lng"
            [zoom]="13"
            (mapReady)="onMapReady($event)"
            class="map"
        >
        </agm-map>
    </mat-card-content>
</mat-card>