<div class="content" *ngIf="!update">
  <ng-container mat-dialog-title>
    <h1 class="modal-title">
      <span>Δημιουργία Καλής Πράξης</span>
      <mat-icon style="cursor: pointer;" (click)="close()">close</mat-icon>
    </h1>
  </ng-container>
 
  <div mat-dialog-content>
    <form [formGroup]="form">
      <mat-grid-list class="form" cols="12" rowHeight="70px">
        <mat-grid-tile colspan="12">
          <div class="align-left" style="width: 100%">
            <mat-form-field style="width: 100%" appearance="standard" class="title">
              <mat-label>Τιτλος</mat-label>
              <input
                matInput
                placeholder="Τίτλος"
                formControlName="title"
                required
              />
            </mat-form-field>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      <mat-grid-list cols="12" rowHeight="70px">
        <mat-grid-tile colspan="12">
          <div class="align-left"  style="width: 100%">
            <mat-form-field  style="width: 100%" appearance="standard" class="title">
              <mat-label>Οδηγός</mat-label>
              <mat-select formControlName="guide" required>
                <mat-option
                  *ngFor="let guide of available_guides"
                  [value]="guide.id"
                >
                  {{ guide.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      <strong>Λεπτομέρειες</strong>
      <div style="width: 100%; margin-top:50px" class="textarea-div">
        <quill-editor [styles]="{height: '250px'}" placeholder="Λεπτομέρειες..." formControlName="description"></quill-editor>
      </div>
      <!-- <mat-grid-list cols="12" rowHeight="70px">
        <mat-grid-tile colspan="6">
          <div class="align-left">
            <mat-form-field appearance="standard" class="title">
              <mat-label>Quiz</mat-label>
              <mat-select formControlName="quiz" required>
                <mat-option
                  *ngFor="let quiz of available_quiz"
                  [value]="quiz.id"
                  [disabled]="!quiz.available"
                >
                  {{ quiz.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-grid-tile>
      </mat-grid-list> -->

    
    </form>
  </div>
  <div mat-dialog-actions class="actions" align="end">
    <mat-dialog-actions >
      <button class="close-btn" mat-raised-button [mat-dialog-close]="false">Ακύρωση</button>
      <button class="custom-btn" [disabled]="!form.valid" (click)="submit()" mat-raised-button>
        Επιβεβαίωση
      </button>
    </mat-dialog-actions>
  </div>
</div>

<div *ngIf="update">
  <ng-container mat-dialog-title>
    <h1 class="modal-title">
      <span>Επεξεργασία Καλής Πράξης</span>
      <mat-icon style="cursor: pointer;" (click)="close()">close</mat-icon>
    </h1>
  </ng-container>

  <div mat-dialog-content>
    <form [formGroup]="form">
      <div class="title-div">
        <mat-form-field style="width: 100%;" appearance="standard" class="title">
          <mat-label>Τιτλος</mat-label>
          <input
            matInput
            placeholder="Τίτλος"
            formControlName="title"
            required
          />
          <mat-error *ngIf="form.get('title')?.invalid"
            >Παρακαλώ συμπληρώστε τίτλο.</mat-error
          >
        </mat-form-field>
      </div>
      <mat-grid-list cols="12" rowHeight="70px">
        <mat-grid-tile colspan="12">
          <div class="align-left"  style="width: 100%">
            <mat-form-field  style="width: 100%" appearance="standard" class="title">
              <mat-label>Οδηγός</mat-label>
              <mat-select formControlName="guide" required>
                <mat-option
                  *ngFor="let guide of available_guides"
                  [value]="guide.id"
                >
                  {{ guide.title }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-grid-tile>
      </mat-grid-list>
      <strong>Λεπτομέρειες</strong>
      <div style="width: 100%; margin-top:50px" class="textarea-div">
          <quill-editor [styles]="{height: '250px'}" placeholder="Λεπτομέρειες..." formControlName="description"></quill-editor>
      </div>
    </form>
  </div>

  <div mat-dialog-actions class="actions" align="end">
    <mat-dialog-actions>
      <button class="close-btn" mat-raised-button [mat-dialog-close]="false">Ακύρωση</button>
      <button
        class="custom-btn"
        [disabled]="form.invalid"
        (click)="submit()"
        mat-raised-button
      >
        Επιβεβαίωση
      </button>
    </mat-dialog-actions>
  </div>
</div>
