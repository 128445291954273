<div class="mat-elevation-z8 main">
  <mat-toolbar>
    <div fxLayout="row" class="nav-div" *ngIf="good_deed !== undefined" fxFlex="100" fxLayoutAlign="start center center">
      <div fxLayout="column" fxFlex="90">
        <div fxLayout="row wrap">
          <label style="white-space: break-spaces;">{{ good_deed.title }}</label>
        </div>
      </div>
      <div fxLayout="column" fxFlex="10">
        <div fxLayout="row">
          <button (click)="edit()" mat-icon-button>
            <mat-icon matTooltip="Επεξεργασία">edit</mat-icon>
          </button>
          <button (click)="openDeleteDialog()" mat-icon-button>
            <mat-icon matTooltip="Διαγραφή" class="close-color">delete</mat-icon>
          </button>
        </div>
      </div>
    </div>
<!--    <mat-grid-list cols="12" class="nav-div" *ngIf="good_deed !== undefined">-->
<!--      <mat-grid-tile colspan="10">-->
<!--        <div class="align-left">-->
<!--          {{ good_deed.title }}-->
<!--        </div>-->
<!--      </mat-grid-tile>-->

<!--      <mat-grid-tile colspan="2">-->
<!--        <div class="align-right">-->
<!--          <button (click)="edit()" mat-icon-button>-->
<!--            <mat-icon matTooltip="Επεξεργασία" >edit</mat-icon>-->
<!--          </button>-->
<!--          <button (click)="openDeleteDialog()" mat-icon-button>-->
<!--            <mat-icon matTooltip="Διαγραφή" class="close-color">delete</mat-icon>-->
<!--          </button>-->
<!--        </div>-->
<!--      </mat-grid-tile>-->
<!--    </mat-grid-list>-->
  </mat-toolbar>

  <div class="context" *ngIf="good_deed === undefined">
    <div class="spinner-div">
      <mat-spinner [diameter]="100"></mat-spinner>
    </div>
  </div>

  <div
    class="context"
    *ngIf="good_deed !== undefined && good_deed.guide !== undefined"
  >
    <mat-grid-list cols="12" *ngIf="good_deed !== undefined">
      <mat-grid-tile colspan="2">
        <div class="align-left">Οδηγός</div>
      </mat-grid-tile>
      <mat-grid-tile colspan="4">
        <mat-select
          class="select"
          (valueChange)="updateGuide($event)"
          [(ngModel)]="good_deed.guide.id"
          placeholder="Επιλέξτε κάποιο οδηγό"
        >
          <mat-option
            *ngIf="available_guides.length <= 0"
            [value]="0"
            [disabled]="true"
          >
            Δεν βρέθηκε κάποιος οδηγός
          </mat-option>

          <mat-option
            *ngFor="let guide of available_guides"
            [value]="guide.id"
          >
            {{ guide.title }}
          </mat-option>
        </mat-select>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list cols="12" *ngIf="good_deed !== undefined">
      <mat-grid-tile colspan="2">
        <div class="align-left">Quiz</div>
      </mat-grid-tile>
      <mat-grid-tile colspan="4">
        <mat-select
          class="select"
          (valueChange)="updateQuiz($event)"
          *ngIf="good_deed.guide.quiz !== undefined"
          [(ngModel)]="good_deed.guide.quiz.id"
          placeholder="Επιλέξτε κάποιο quiz"
        >
          <mat-option
            *ngIf="available_quiz.length <= 0"
            [value]="0"
            [disabled]="true"
          >
            Δεν βρέθηκε κάποιο διαθέσιμο quiz
          </mat-option>

          <mat-option
            *ngFor="let quiz of available_quiz"
            [value]="quiz.id"
            [disabled]="!quiz.available"
          >
            {{ quiz.title }}
          </mat-option>
        </mat-select>
        <mat-select
          class="select"
          (valueChange)="updateQuiz($event)"
          *ngIf="good_deed.guide.quiz === undefined"
          placeholder="Επιλέξτε κάποιο quiz"
        >
          <mat-option
            *ngIf="available_quiz.length <= 0"
            [value]="0"
            [disabled]="true"
          >
            Δεν βρέθηκε κάποιο διαθέσιμο quiz
          </mat-option>

          <mat-option
            *ngFor="let quiz of available_quiz"
            [value]="quiz.id"
            [disabled]="!quiz.available"
          >
            {{ quiz.title }}
          </mat-option>
        </mat-select>
      </mat-grid-tile>
    </mat-grid-list>

    <div class="guide-div">
      <h3>Οδηγός</h3>
      <a
        class="pointer guide-link"
        [routerLink]="getGuideLink(good_deed.guide)"
      >
        {{ good_deed.guide.title }}
      </a>

      <div class="guide-text">
        <p [innerHTML]="good_deed.guide.context"></p>
      </div>
    </div>

    <app-quiz
      [quiz]="good_deed.quiz"
      *ngIf="good_deed.quiz !== undefined"
    ></app-quiz>
  </div>
  <div
    class="context"
    *ngIf="good_deed !== undefined && good_deed.guide === undefined"
  >
    <p>Δεν υπάρχει οδηγός</p>
  </div>
</div>
