<div class="mat-elevation-z8 main">
  <mat-tab-group
    mat-align-tabs="start"
    [(selectedIndex)]="tabIndex"
    dynamicHeight
    mat-stretch-tabs
    [disablePagination]="true"
  >
    <mat-tab label="Χρήστες" disabled>

      <ng-container *ngIf="!dataloaded">
        <div class="spinner-div">
          <mat-spinner [diameter]="100"></mat-spinner>
        </div>
      </ng-container>

      <div [ngClass]="{'hide': !dataloaded}">

        <div>
          <button mat-raised-button class="add-btn custom-btn" (click)="addUser()">
            Προσθήκη Νέου Χρήστη
          </button>
        </div>
        <mat-form-field style="width:20%; margin-left: 20px;">
          <mat-label>Αναζήτηση</mat-label>
          <input matInput (keyup)="onSearch()"  [(ngModel)]="search">
        </mat-form-field>
        <table
          mat-table
          matSort
          [dataSource]="dataSource"
          class="table"
        >
          <ng-container matColumnDef="order">
            <th mat-header-cell *matHeaderCellDef>#</th>
            <td mat-cell *matCellDef="let element; let i = index">
              {{ i + 1 }}
            </td>
          </ng-container>

          <ng-container matColumnDef="name">
            <mat-header-cell mat-sort-header *matHeaderCellDef
              >Όνομα</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{ element.name }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="surname">
            <mat-header-cell mat-sort-header *matHeaderCellDef
              >Επώνυνο</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{ element.surname }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="email">
            <mat-header-cell mat-sort-header *matHeaderCellDef
              >Email</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{ element.email }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="role">
            <mat-header-cell mat-sort-header *matHeaderCellDef
              >Ρόλος</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{
              getRoleName(element)
            }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="points">
            <mat-header-cell mat-sort-header *matHeaderCellDef
              >Πόντοι</mat-header-cell
            >
            <mat-cell *matCellDef="let element">{{ element.points }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef  style="width: 10%; border: none;"> Ενέργειες </th>
            <td mat-cell *matCellDef="let element" style="width: 10%; border: none;">
              <mat-icon matTooltip="Δείτε το χρήστη" class="pointer primary-color" [routerLink]="getLink(element)"
                >visibility</mat-icon
              >

              <mat-icon matTooltip="Επεξεργασία" class="pointer primary-color" (click)="edit(element)">edit</mat-icon>

              <mat-icon
                matTooltip="Διαγραφή"
                class="pointer close-color"
                (click)="openDeleteDialog(element)"
                *ngIf="current_user.id !== element.id"
                >delete</mat-icon
              >
            </td>
          </ng-container>

          <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
          <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
        </table>

        <mat-paginator
          [length]="length"
          [pageSizeOptions]="pageSizeOptions"
        >
        </mat-paginator>
      </div>

    </mat-tab>
  </mat-tab-group>
</div>
