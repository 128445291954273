<div *ngIf="quiz !== undefined">
  <div class="quiz-div">
    <h2>Quiz</h2>
    <!-- <div>Ερώτηση: {{ quiz.question }}</div>
    <div class="text">1) {{ quiz.answer_one }}</div>
    <div class="text">2) {{ quiz.answer_two }}</div>
    <div class="text">3) {{ quiz.answer_three }}</div>
    <div class="text">Σωστή απάντηση: {{ quiz.correct }}</div> -->

    <mat-grid-list cols="12">
      <mat-grid-tile colspan="6">
        <button color="warn" mat-raised-button (click)="removeDialog()">
          Διαγραφή quiz
        </button>
      </mat-grid-tile>
      <mat-grid-tile colspan="6">
        <button class="btn" mat-raised-button (click)="editQuiz()">
          Επεξεργασία quiz
        </button>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>
