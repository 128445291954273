import { ReportCategory } from './../../interfaces/report-category';
import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-subcategory-dialog',
  templateUrl: './subcategory-dialog.component.html',
  styleUrls: ['./subcategory-dialog.component.scss'],
})
export class SubcategoryDialogComponent implements OnInit {
  input: string;
  category: number;
  categories: Array<ReportCategory>;

  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    this.initcategories();
  }

  async initcategories(): Promise<void> {
    try {
      const response = await this.commonService.getRequest(
        'report-categories/view'
      );

      this.categories = response.results;
      if (this.categories.length) {
        this.category = this.categories[0].id;
      }
    } catch (error) {
      console.log(error);
    }
  }
}
