import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './components/home/home.component';
import { DrawerComponent } from './components/drawer/drawer.component';
import { LoginComponent } from './components/login/login.component';
import { MaterialModule } from './material/material.module';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { ProfileComponent } from './components/profile/profile.component';
import { HomeLayoutComponent } from './components/home-layout/home-layout.component';
import { LoginLayoutComponent } from './components/login-layout/login-layout.component';
import { SuggestionsComponent } from './components/suggestions/suggestions.component';
import { ReportsComponent } from './components/reports/reports.component';
import { OrderModule } from 'ngx-order-pipe';
import { SuggestionComponent } from './components/suggestion/suggestion.component';
import { DeleteDialogComponent } from './components/delete-dialog/delete-dialog.component';
import { ReportComponent } from './components/report/report.component';
import { UsersComponent } from './components/users/users.component';
import { GuidesComponent } from './components/guides/guides.component';
import { GoodDeedsComponent } from './components/good-deeds/good-deeds.component';
import { GoodDeedComponent } from './components/good-deed/good-deed.component';
import { GuideComponent } from './components/guide/guide.component';
import { UserComponent } from './components/user/user.component';
import { SuggestionCategoriesComponent } from './components/suggestion-categories/suggestion-categories.component';
import { CreateDialogComponentComponent } from './components/create-dialog-component/create-dialog-component.component';
import { UpdateDialogComponent } from './components/update-dialog/update-dialog.component';
import { ReportCategoriesComponent } from './components/report-categories/report-categories.component';
import { ReportSubCategoriesComponent } from './components/report-sub-categories/report-sub-categories.component';
import { SubcategoryDialogComponent } from './components/subcategory-dialog/subcategory-dialog.component';
import { GoodDeedFormComponent } from './components/good-deed-form/good-deed-form.component';
import { UserFormComponent } from './components/user-form/user-form.component';
import { GuideFormComponent } from './components/guide-form/guide-form.component';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { QuizComponent } from './components/quiz/quiz.component';
import { QuizFormComponent } from './components/quiz-form/quiz-form.component';
import { AllQuizComponent } from './components/all-quiz/all-quiz.component';
import { QuestionFormComponent } from './components/question-form/question-form.component';
import { QuestionsComponent } from './components/questions/questions.component';
import { QuestionComponent } from './components/question/question.component';

import { QuillModule } from 'ngx-quill';
import { SettingsComponent } from './components/settings/settings.component';

import { AgmCoreModule } from '@agm/core';

import { GoogleMapsModule } from '@angular/google-maps';
import { QuizStepperComponent } from './components/quiz-stepper/quiz-stepper.component';
import { QuestionStepperFormComponent } from './components/question-stepper-form/question-stepper-form.component';
import { NonApproveSuggestionsComponent } from './components/non-approve-suggestions/non-approve-suggestions.component';
import { NonApproveReportsComponent } from './components/non-approve-reports/non-approve-reports.component';
import { BestReportsComponent } from './components/best-reports/best-reports.component';
import { BestSuggestionsComponent } from './components/best-suggestions/best-suggestions.component';
import { HomeMapComponent } from './components/home-map/home-map.component';

import { CustomPaginator } from './paginatorConfiguration/paginatorConfiguration';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { ConfirmLogoutComponent } from './components/confirm-logout/confirm-logout.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import {InterceptInterceptor} from "./interceptors/intercept.interceptor";

import {IvyCarouselModule} from 'angular-responsive-carousel';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { NewAdminFormComponent } from './components/new-admin-form/new-admin-form.component';
import { PrivacyComponent } from './components/privacy/privacy.component';
@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    HomeComponent,
    DrawerComponent,
    LoginComponent,
    ProfileComponent,
    HomeLayoutComponent,
    LoginLayoutComponent,
    SuggestionsComponent,
    ReportsComponent,
    SuggestionComponent,
    DeleteDialogComponent,
    ReportComponent,
    UsersComponent,
    GuidesComponent,
    GoodDeedsComponent,
    GoodDeedComponent,
    GuideComponent,
    UserComponent,
    SuggestionCategoriesComponent,
    CreateDialogComponentComponent,
    UpdateDialogComponent,
    ReportCategoriesComponent,
    ReportSubCategoriesComponent,
    SubcategoryDialogComponent,
    GoodDeedFormComponent,
    UserFormComponent,
    GuideFormComponent,
    QuizComponent,
    QuizFormComponent,
    AllQuizComponent,
    QuestionFormComponent,
    QuestionsComponent,
    QuestionComponent,
    SettingsComponent,
    QuizStepperComponent,
    QuestionStepperFormComponent,
    NonApproveSuggestionsComponent,
    NonApproveReportsComponent,
    BestReportsComponent,
    BestSuggestionsComponent,
    HomeMapComponent,
    ConfirmLogoutComponent,
    NewAdminFormComponent,
    ImageViewerComponent,
    PrivacyComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    OrderModule,
    QuillModule.forRoot({
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote', 'code-block'],
          [{ 'header': 1 }, { 'header': 2 }],               // custom button values
          [{ 'list': 'ordered'}, { 'list': 'bullet' }],
          [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
          [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
          [{ 'direction': 'rtl' }],                         // text direction
          [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
          [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
          [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
          [{ 'font': [] }],
          [{ 'align': [] }],
          ['clean'],                                         // remove formatting button
          ['link', 'image']  
        ]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyA03BE_TmjVXpRND6y-eGhL3ze2m_F7pSc',
      libraries: ['drawing'],
    }),
    GoogleMapsModule,
    FlexLayoutModule,
    IvyCarouselModule
  ],
  providers: [
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    {
      provide: MatPaginatorIntl, useValue: CustomPaginator()
    },
    {
      provide: LocationStrategy, useClass: HashLocationStrategy
    },
    {
      provide: HTTP_INTERCEPTORS, useClass: InterceptInterceptor, multi: true
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
