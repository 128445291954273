import { MatDialog } from '@angular/material/dialog';
import { ConfirmLogoutComponent } from './../confirm-logout/confirm-logout.component';
import { User } from './../../interfaces/user.interface';
import { UserService } from './../../services/user.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit {
  @Output('toggleDrawer') toggleDrawerFun: EventEmitter<any> =
    new EventEmitter();
  constructor(private userService: UserService, private dialog: MatDialog) {}

  user: User;
  ngOnInit(): void {
    this.user = this.userService.getUser();
  }

  toggleDrawer(): void {
    this.toggleDrawerFun.emit();
  }

  getUserName(): string {
    if (this.user === undefined) {
      this.user = this.userService.getUser();
    }
    return this.user.name;
  }

  async confirmLogout(): Promise<void> {
    const dialogRef = this.dialog.open(ConfirmLogoutComponent);

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.logout();
      }
    });
  }

  logout(): void {
    this.userService.logout();
  }
}
