import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-update-dialog',
  templateUrl: './update-dialog.component.html',
  styleUrls: ['./update-dialog.component.scss'],
})
export class UpdateDialogComponent implements OnInit {
  input: string;
  title: string;
  placeholder: string;
  type: string;
  rows: number;

  constructor(@Inject(MAT_DIALOG_DATA) public current: any, public dialogRef: MatDialogRef<UpdateDialogComponent>) {}

  ngOnInit(): void {
    this.input = this.current.current.name;
    this.title = this.current.current.title;
    this.placeholder = this.current.current.placeholder;
    this.type = this.current.current.type;
    this.rows = this.current.current.rows;
  }

  close() {
    this.dialogRef.close();
  }
}
