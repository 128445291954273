<ng-container mat-dialog-title>
    <h1 class="modal-title">
      <span>Αποσύνδεση</span>
      <mat-icon style="cursor: pointer;" (click)="close()">close</mat-icon>
    </h1>
  </ng-container>
 
  <mat-dialog-actions align="end">
    <button class="close-btn" mat-raised-button [mat-dialog-close]="false">Ακύρωση</button>
    <button class="custom-btn" mat-raised-button [mat-dialog-close]="true">
      Επιβεβαίωση
    </button>
  </mat-dialog-actions>
  