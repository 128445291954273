import { CommonService } from './../../services/common.service';
import { SnackBarService } from './../../services/snack-bar.service';
import { AgmMap, Polygon } from '@agm/core';
import { Component, OnInit, ViewChild } from '@angular/core';

declare var google: any;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  coordinates: any = [];
  map: any;
  drawingManager: any;
  polygone: any;

  average_pos = { lat: 40.653549, lng: 22.898692 };

  drawingManagerOptions = {
    drawingControl: false,
    drawingControlOptions: {
      drawingModes: ['polygon'],
    },
    polygonOptions: {
      draggable: false,
      editable: true,
    },
    drawingMode: google.maps.drawing.OverlayType.POLYGON,
  };

  tabIndex: number = 0;
  terms = "";
  message = "";
  warning_message = "";

  termsLoaded = false;
  messageLoaded = false;
  warningmessageLoaded = false;


  constructor(
    private snackBarService: SnackBarService,
    private commonService: CommonService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.initCoords();
  }

  async initCoords() {
    try {
      const response = await this.commonService.getRequest('settings/coords');
      if (response.coords) {
        let coords = JSON.parse(response.coords);
        coords.forEach((point: any) => {
          this.coordinates.push(new google.maps.LatLng(point.lat, point.lng));
        });
      }

      this.initDrawingManager(this.map);
    } catch (error: any) {
      this.snackBarService.displayError(error);
    }
  }

  drawPolygon() {
    console.log(this.coordinates);
    const polygone = new google.maps.Polygon({
      paths: this.coordinates,
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillOpacity: 0.1,
      editable: true,
    });

    polygone.setMap(this.map);

    this.polygone = polygone;
  }

  onMapReady(map: any) {
    this.map = map;
    // this.initDrawingManager(map);
  }

  initDrawingManager(map: any) {
    this.drawingManager = new google.maps.drawing.DrawingManager(
      this.drawingManagerOptions
    );

    if (this.coordinates.length) {
      const polygone: Polygon = new google.maps.Polygon({
        paths: this.coordinates,
        strokeOpacity: 0.8,
        strokeWeight: 2,
        fillOpacity: 0.1,
        editable: true,
      });
      polygone.setEditable(true);
      polygone.setMap(this.map);
      this.polygone = polygone;
    } else {
      this.drawingManager.setMap(map);
    }

    google.maps.event.addListener(
      this.drawingManager,
      'overlaycomplete',
      (event: any) => {
        this.drawingManager.setDrawingMode(null);

        if (event.type === google.maps.drawing.OverlayType.POLYGON) {
          this.polygone = event.overlay;
        }
      }
    );
  }

  submit() {
    this.coordinates = [];
    if (this.polygone !== undefined || this.polygone !== null) {
      var len = this.polygone.getPath().getLength();
      for (var i = 0; i < len; i++) {
        let elements = this.polygone
          .getPath()
          .getAt(i)
          .toUrlValue(5)
          .split(',');
        this.coordinates.push({
          lat: parseFloat(elements[0]),
          lng: parseFloat(elements[1]),
        });
      }

      if (this.coordinates.length) {
        this.postCoords();
      } else {
        this.snackBarService.displayError('Παρακαλώ επιλέξτε σημεία.');
      }
    } else {
      this.snackBarService.displayError('Παρακαλώ επιλέξτε σημεία.');
    }
  }

  clear(remove_poly = true) {
    if (this.polygone !== undefined) {

      if (remove_poly) {
        this.polygone.setMap(null);
        this.polygone = null;
      }

      this.coordinates = [];
      this.initDrawingManager(this.map);
    }
  }

  postCoords() {
    try {
      const body = {
        coords: JSON.stringify(this.coordinates),
      };

      const response = this.commonService.postRequest(`settings/coords`, body);

      this.snackBarService.displaySuccess(
        'Οι συντεταγμένες αποθηκεύτηκαν επιτυχώς.'
      );
    } catch (error: any) {
      this.snackBarService.displayError(error?.message);
    }
  }

  async resetCoords () {

    try {
      let response = await this.commonService.getRequest('settings/reset-coords')

      this.snackBarService.displaySuccess("Τα όρια αποθηκεύτηκαν επιτυχώς")

      if (this.polygone) {
        this.clear(true)
      } else {
        this.clear(false)
      }

      await this.initCoords();
    } catch (error: any) {
      console.log(error)
      this.snackBarService.displayError(error.error.message)

    }
  }

  async changeTab(event: any) {
    if (event.index === 1) {
      await this.initTerms()
    } else if (event.index === 2) {
      await this.initMessage()
    } else if (event.index === 3) {
      await this.initWarningMessage()
    }
  }

  async initTerms() {
    try {
      const response = await this.commonService.getRequest('settings/terms')
      this.terms = response.terms.value
      this.termsLoaded = true
    } catch (error: any) {
      console.log(error)
      this.snackBarService.displayError(error.error.message)
    }
  }

  async postTerms() {
    try {
      const body = {terms: this.terms}
      const response = await this.commonService.postRequest('settings/terms', body)
      this.terms = response.terms.value
      this.snackBarService.displaySuccess('Οι όροι χρήσης αποθηκεύτηκαν επιτυχώς.')
    } catch (error: any) {
      this.snackBarService.displayError(error.error.mesasge)
    }
  }

  async initMessage() {
    try {
      const response = await this.commonService.getRequest('settings/message')
      this.message = response.message.value
      this.messageLoaded = true
    } catch (error: any) {
      console.log(error)
      this.snackBarService.displayError(error.error.message)
    }
  }

  async postMessage() {
    try {
      const body = {message: this.message}
      const response = await this.commonService.postRequest('settings/message', body)
      this.message = response.message.value
      this.snackBarService.displaySuccess('Το μήνυμα δημάρχου αποθηκεύτηκε επιτυχώς.')
    } catch (error: any) {
      console.log(error)
      this.snackBarService.displayError(error.error.mesasge)
    }
  }

  async initWarningMessage() {
    try {
      const response = await this.commonService.getRequest('settings/warning-message')
      this.warning_message = response.message.value
      this.warningmessageLoaded = true
    } catch (error: any) {
      console.log(error)
      this.snackBarService.displayError(error.error.message)
    }
  }

  async postWarningMessage() {
    try {
      const body = {warning_message: this.warning_message}
      const response = await this.commonService.postRequest('settings/warning-message', body)
      this.warning_message = response.message.value
      this.snackBarService.displaySuccess('Το προειδοποιητικό μήνυμα αποθηκεύτηκε επιτυχώς.')
    } catch (error: any) {
      console.log(error)
      this.snackBarService.displayError(error.error.mesasge)
    }
  }
}
