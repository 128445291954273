<div class="mat-elevation-z8 main">
  <mat-tab-group
    mat-align-tabs="start"
    [(selectedIndex)]="tabIndex"
    dynamicHeight
    mat-stretch-tabs
    [disablePagination]="true"
    (selectedTabChange)="changeTab($event)"
  >
    <mat-tab label="Όρια Περιοχής">
      <div class="content">
        <agm-map
          #map
          [latitude]="average_pos.lat"
          [longitude]="average_pos.lng"
          [zoom]="12"
          (mapReady)="onMapReady($event)"
        >
        </agm-map>
      </div>

      <div class="actions-menu">
        <div style="padding: 10px;">
          <button class="close-btn" mat-raised-button (click)="resetCoords()">Επαναφορά Αρχικών Ορίων</button>
        </div>
        <div class="actions">
          <div>
            <button class="close-btn" mat-raised-button (click)="clear()">Καθαρισμός</button>
          </div>
          <div style="padding-left: 10px; padding-right: 10px;">
            <button [disabled]="polygone === null" mat-raised-button (click)="submit()" class="custom-btn">
              Επιβεβαίωση
            </button>
          </div>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Όροι χρήσης">
      <ng-container *ngIf="!termsLoaded">
        <div class="spinner-div">
          <mat-spinner [diameter]="100"></mat-spinner>
        </div>
      </ng-container>
      <div [ngClass]="{'hide': !termsLoaded}" class="content" style="overflow-y: auto;">
        <quill-editor [(ngModel)]="terms" [styles]="{height: '400px'}" placeholder="Όροι χρήσης..."></quill-editor>
      </div>
      <div class="actions-menu">
        <div class="term-actions">
          <button (click)="postTerms()" class="custom-btn" [disabled]="!terms" mat-raised-button>Επιβεβαίωση</button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Μήνυμα Δημάρχου">
      <ng-container *ngIf="!messageLoaded">
        <div class="spinner-div">
          <mat-spinner [diameter]="100"></mat-spinner>
        </div>
      </ng-container>
      <div [ngClass]="{'hide': !messageLoaded}" class="content" style="overflow-y: auto;">
        <quill-editor [(ngModel)]="message" [styles]="{height: '400px'}" placeholder="Μήνυμα Δημάρχου..."></quill-editor>
      </div>
      <div class="actions-menu">
        <div class="term-actions">
          <button (click)="postMessage()" class="custom-btn" [disabled]="!message" mat-raised-button>Επιβεβαίωση</button>
        </div>
      </div>
    </mat-tab>
    <mat-tab label="Προειδοποιητικό Μήνυμα">
      <ng-container *ngIf="!warningmessageLoaded">
        <div class="spinner-div">
          <mat-spinner [diameter]="100"></mat-spinner>
        </div>
      </ng-container>
      <div [ngClass]="{'hide': !warningmessageLoaded}" class="warning-content" style="overflow-y: auto;">
        <textarea style="width: 99%; height: 80%;" [(ngModel)]="warning_message" placeholder="Προειδοποιητικό Μήνυμα..."></textarea>
      </div>
      <div class="actions-menu">
        <div class="term-actions">
          <button (click)="postWarningMessage()" class="custom-btn" [disabled]="!warning_message" mat-raised-button>Επιβεβαίωση</button>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</div>
